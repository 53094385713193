import React, { useEffect, useState, useRef } from 'react';
import './SignUpForm.css';
import RegularInput from '../../Components/RegularInput/RegularInput';
import { useTranslation } from 'react-i18next';
import registration from '../../Images/registration.jpg';
import Footer from '../Footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';

const SignUpForm = ({ userLanguage, setLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { props } = location?.state || {};

  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [registrationError, setRegistrationError] = useState('');

  const alertPleaseEnterYourName = t('alert_please_enter_your_name');
  const alert_please_enter_a_valid_email_address = t('alert_please_enter_a_valid_email_address');
  const alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number = t('alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number');
  const passwordInputRef = useRef(null);

  const handleSubmit = () => {

    setLoading(true);
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    const passwordRegex = /^(?=.*\d)[\s\S]{6,}$/;
    passwordInputRef.current?.blur();
    
    if (!fullName) {
      setRegistrationError(alertPleaseEnterYourName);
      setLoading(false);
      return;
    }

    else if (!emailRegex.test(email)) {
      setRegistrationError(alert_please_enter_a_valid_email_address);
      setLoading(false);
      return;
    }

    else if (!passwordRegex.test(password)) {
      setRegistrationError(alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number);
      setLoading(false);
      return;
    }
    else {
      navigate("/PackagesOffer",
        {
          state: {
            chooseOption: "please_select_an_option",
            btns: true,
            email: email,
            fullName: fullName,
            password: password,
            userLanguage: userLanguage,
            personePackage: props
          }
        }
      )

    }

    setRegistrationError('');
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      const image = new Image();
      image.src = registration;
      image.onload = () => {
        // Обработка после успешной загрузки изображения
        setLoading(false);
      };
    }, 200);
    setLoading(false);

  }, [setLoading]);


  return (
    <>
      <div className="signup-container" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
        <div className='signup-titel' >
          <img src={registration} alt="img signUp" loading="eager" />

          <div className="signup-input-container">
            <h2> {t('signup')} </h2>
            <RegularInput
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
              placeholder="first_and_Last_Name"
            />

            <RegularInput
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              placeholder="email"

            />

          <label style={{ position: 'relative' }}>
            <RegularInput
              securePassword={true}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
              eyeStyle={userLanguage?.includes("he") ? true : false}

              />
            </label>
            {registrationError && (
              <p style={{ color: 'red', textAlign: 'center' }}>{registrationError}</p>
            )}

            <div className='btns-sign-up'>
              <button
                className='regular-button'
                onClick={handleSubmit}>
                <p>{t('start_registration')}</p>
              </button>
            </div>

          </div>
        </div >


      </div>
      <Footer userLanguage={userLanguage} />

    </>
  );
};

export default SignUpForm;
