import React, { useEffect } from 'react';
import './App.css';
import Navigation from './Navigation/Navigation';
import Modal from 'react-modal';
import { BrowserRouter as Router, } from 'react-router-dom';
// import FamilyTree from './Screens/TreeOfLife/FamilyTree/FamilyTree';
// import GenerateQRCode from './Components/GenerateQRCode/GenerateQRCode';
// import GenerateQrCodeTestWithLogo from './Components/GenerateQRCode/CreateQrCodeWithLogo';
// import GenerateLogoQRCode from './Components/GenerateLogoQRCode/GenerateLogoQRCode';

function App() {
  useEffect(() => {
    // Устанавливаем корневой элемент для модальных окон
    Modal.setAppElement('#root');
  }, []);

  return (
    <div className="App">

      <Router>
       {/* <FamilyTree /> */}
        <Navigation />
        {/* <GenerateLogoQRCode /> */}
        {/* <GenerateQRCode /> */}
        {/* <GenerateQrCodeTest /> */}
        {/* <GenerateQrCodeTestWithLogo /> */}
      </Router>
    </div>
  );
}

export default App;
