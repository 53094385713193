import React from 'react';
// import './InstructionSteps.css';
import RegularButton from '../../Components/RegularButton/RegularButton';
import { useTranslation } from 'react-i18next';
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const ReasonsToCreate = ({ userLanguage }) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 150,
            behavior: 'smooth',
        });
    };

    const { t } = useTranslation();

    const styles = {
        customFont: {
          fontSize: 60,
          fontWeight: 400,
          fontStyle: 'italic',
          fontFamily: 'Rubik', // имя вашего шрифта
          src: 'url(/fonts/Rubik-Italic.ttf) format("truetype")' // путь к вашему файлу шрифта
        }
      };

    return (
        <div className="instruction-content" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
            {/* <h2>{t('reasons_to_create_a_memorial_page_with_qrmoment')}</h2> */}
            <h2> <span style={styles.customFont}>3</span> {t('causes')}</h2>
            <h2> {t('reasons_to_create_a_memorial_page_with_qrmoment')} </h2>
            <div className="instruction-container">
                <div className="instruction-step">
                    <h4>1. {t('first_reason')}</h4>
                    <p>{t('first_reason_text')}</p>
                </div>
                {userLanguage?.includes("he") ?
                    <FaArrowLeftLong color='white' size="20" className="instruction-step-arrow" />
                    :
                    <FaArrowRightLong color='white' size="20" className="instruction-step-arrow" />
                }
                <div className="instruction-step" >
                    <h4>2. {t('second_reason')}</h4>
                    <p>{t('second_reason_text')}</p>
                </div>
                {userLanguage?.includes("he") ?
                    <FaArrowLeftLong color='white' size="20" className="instruction-step-arrow" />
                    :
                    <FaArrowRightLong color='white' size="20" className="instruction-step-arrow" />
                }
                <div className="instruction-step">
                    <h4>3. {t('three_reason')}</h4>
                    <p>{t('three_reason_text')}</p>
                </div>
            </div>

            <div className="instruction-button-container">
                <RegularButton
                    buttonText="BuyBtn"
                    to="/SignUpForm"
                    styleText={{ color: "#003B46", fontWeight: 550, fontFamily: 'Lora' }}
                    style={{ backgroundColor: "#dcdce2", height: 60, padding: 0, marginTop: 20 }}
                    onClick={scrollToTop}
                />
            </div>
        </div>
    );
};

export default ReasonsToCreate;