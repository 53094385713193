import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import './LanguageSelector.css';

// Импортируем изображения флагов
import flagRU from '../Images/flags/flag-ru.png';
import flagEN from '../Images/flags/flag-en.png';
// import flagHE from '../Images/flags/flag-he.png';

const languageOptions = [
  { value: 'ru', label: 'Русский', flag: flagRU, lan: "RU" },
  { value: 'en', label: 'English', flag: flagEN, lan: "EN" },
  // { value: 'he', label: 'עברית', flag: flagHE },
];

const LanguageOption = ({ option, userLanguage, handleLanguageChange, setBurgerMenuOpen }) => {
  const isSelected = option.value === userLanguage;

  const handleClick = () => {
    handleLanguageChange(option.value);
    setBurgerMenuOpen(false);
  };

  return (
    <div className="language-option" onClick={handleClick}>
      <p style={{ fontWeight: isSelected ? 'bold' : 'normal' }}>{option.lan}</p>
    </div>
  );
};

const LanguageSelector = ({ userLanguage, setUserLanguage, setBurgerMenuOpen }) => {
  const navigate = useNavigate();

  const defaultLanguage = userLanguage ? userLanguage : 'ru';

  const { i18n } = useTranslation();

  const location = useLocation();

  const handleLanguageChange = (newLanguage) => {
    setUserLanguage(newLanguage);

    //Set user language from local storage
    localStorage.setItem('userLanguage', newLanguage);

    i18n.changeLanguage(newLanguage);
    // Формируем новый путь с новым языком
    const newPath = location.pathname.replace(`/${userLanguage}`, `/${newLanguage}`);
    // Переходим на новый путь
    navigate(newPath);
  };

  useEffect(() => {
    i18n.changeLanguage(defaultLanguage);
  }, [defaultLanguage, i18n]);

  return (
    <div className={userLanguage === 'en' ? 'language-selector' : 'language-selector'} style={{ borderRadius: 9 }}>
      {languageOptions?.map((option) => (
        <LanguageOption key={option.value} userLanguage={userLanguage} option={option} handleLanguageChange={handleLanguageChange} setBurgerMenuOpen={setBurgerMenuOpen} />
      ))}
    </div>
  );
};

export default LanguageSelector;
