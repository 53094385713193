import React, { useState } from 'react';
import './ContactForm.css';
import RegularInput from '../../Components/RegularInput/RegularInput';
import RegularButton from '../../Components/RegularButton/RegularButton';
import { addMessage } from '../../Redux/actions/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';

const ContactForm = ({ userLanguage, setLoading, isMobile }) => {
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [treatmentCategory, setTreatmentCategory] = useState("");

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const the_name_is_required = t('the_name_is_required');
    const last_name_is_required = t('last_name_is_required');
    const phone_is_required = t('phone_is_required');
    const alert_please_enter_a_valid_email_address = t('alert_please_enter_a_valid_email_address');
    const message_is_required = t('message_is_required');
    const your_message_has_been_sent_we_will_contact_you_shortly = t('your_message_has_been_sent_we_will_contact_you_shortly');
    const treatmentCategory_is_required = t('treatmentCategory_is_required');

    const handleSubmit = async () => {
        if (name === "") {
            alert(the_name_is_required);
            return; // Выход из функции, чтобы не продолжать проверку других полей
        }

        if (lastName === "") {
            alert(last_name_is_required);
            return;
        }

        if (phone === "") {
            alert(phone_is_required);
            return;
        }

        if (email === "") {
            alert(alert_please_enter_a_valid_email_address);
            return;
        }

        if (message === "") {
            alert(message_is_required);
            return;
        }
        if (treatmentCategory === "") {
            alert(treatmentCategory_is_required);
            return;
        }


        setLoading(true);

        try {
            const result = await dispatch(addMessage(name, lastName, phone, email, treatmentCategory, message));
            if (result && result.success === true) {
                alert(your_message_has_been_sent_we_will_contact_you_shortly)
                setName("");
                setLastName("");
                setPhone("");
                setEmail("");
                setMessage("");
            } else {
                // Handle error actions here
                console.error(result || "Unknown error");
            }
        } catch (error) {
            console.error(error);
            navigate('/Error');
        } finally {
            setLoading(false);
        }

    }

    // добовляем места по дефолту
    const defaultOptionListTreatmentCategory = [
        {
            value: t('selectCooperation'),
            label: t('selectCooperation'),
        },
        {
            value: t('selectRegistration'),
            label: t('selectRegistration'),
        },
        {
            value: t('selectSignIn'),
            label: t('selectSignIn'),
        },
        {
            value: t('selectOther'),
            label: t('selectOther'),
        },
    ];

    const allOptions = [...defaultOptionListTreatmentCategory];

    return (
        <div>
            <div className="contact-form" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
                <div>
                    <h2 style={{ fontSize: 30 }}>{t('do_you_need_help')}</h2>
                    <h2>{t('contactInfo')}</h2>
                </div>

                <div className={userLanguage?.includes("he") ? 'contact-us_hebrew' : "contact-us"} >
                    <h2>{t('contactUs')}</h2>
                    <div className="contact-input-group">
                        <RegularInput
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            style={{ minWidth: isMobile ? 260 : 560 }}
                            placeholder="your_name"

                        />
                    </div>
                    <div className="contact-input-group">
                        <RegularInput
                            required
                            type="text"
                            name="name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            style={{ minWidth: isMobile ? 260 : 560 }}
                            placeholder="your_last_name"

                        />
                    </div>
                    <div className="contact-input-group">
                        <RegularInput
                            required
                            type="number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            style={{ minWidth: isMobile ? 260 : 560 }}
                            placeholder="your_phone"

                        />
                    </div>
                    <div className="contact-input-group">
                        <RegularInput
                            required
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ minWidth: isMobile ? 260 : 560 }}
                            placeholder="your_email"

                        />
                    </div>

                    <div className="contact-btn-send" >
                        <RegularInput
                            type="select"
                            value={treatmentCategory}
                            onChange={(selectedTreatmentCategory) => setTreatmentCategory(selectedTreatmentCategory)}
                            // label={t('how_did_you_hear_about_us')}
                            placeholder={t('treatmentCategory')}
                            options={allOptions}
                            matchMedia={window.matchMedia('(max-width: 768px)').matches ? "300px" : "600px"}
                            userLanguage={userLanguage}
                        />
                    </div>
                    <div className="contact-input-group">
                        <RegularInput
                            required
                            type="textarea"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="your_message"
                        // styleTextArea={{ width: 602 }}

                        />
                    </div>

                    <div className="contact-btn-send">
                        <RegularButton
                            buttonText="send"
                            onClick={handleSubmit}
                            styleText={{ fontSize: 20, color: "white", }}
                            style={{ marginLeft: 10, marginTop: 10, borderStyle:'solid', borderWidth: 1, borderColor: 'white'}}
                            
                        />
                    </div>


                </div>

            </div>
            <Footer userLanguage={userLanguage} />
        </div>
    );
};

export default ContactForm;
