import React, { useEffect } from 'react';
import './PageNoFound.css'
import Footer from '../Footer/Footer';
import RegularButton from '../../Components/RegularButton/RegularButton';
import { useTranslation } from 'react-i18next';

const PageNoFound = ({ loadingUserData, setLoading }) => {
  const { t } = useTranslation();

  // const getEnterUser = window.location.pathname.includes("EnterWithQrCode");

  useEffect(() => {
    setLoading(true)
    if (loadingUserData) {
      setLoading(false)
    }
    setLoading(false)

  }, [loadingUserData, setLoading])

  return (
        <>
            <div className='page-not-found-details'>
              <h1>404</h1>
              <h2>{t('sorry_page_not_found')}</h2>
              <p>{t('the_page_you_are_looking_for_might_have_been_removed_had_its_name_changed')}</p>
              <div style={{ textAlign: 'center' }}>
                <RegularButton
                  to="/"
                  buttonText="home_page"
                  style={{ width: 200 }}
                  styleText={{ fontSize: 20, color: "white", }}
                />
              </div>
            </div>
          <Footer />

           </>
  );
};

export default PageNoFound;
