import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveNewUserData } from "../../Redux/actions/actions";
import { registerWithEmailAndPassword } from "../../Components/Firebase/Firebase";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import RegularButton from "../../Components/RegularButton/RegularButton";
import registration from '../../Images/registration.jpg';
import Footer from "../Footer/Footer";
import './AssistPayment.css'

const AssistPayment = () => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const firstNamePersone = null;
    const lastNamePersone = null;
    const fathersNamePersons = null;
    const birthDatePersone = null;
    const deathDatePersone = null;
    const storyPersone = null;
    const placeOfBirth = null;
    const memorialPlace = null;
    const spouse = null;
    const children = null;
    const education = null;
    const hobbies = null;
    const userLocation = null;

    const isUserRegistered = true;
    const createUserWithQrCode = false;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const userDataFromLocalStorage = JSON.parse(localStorage.getItem('userData'));
    const userData = useSelector(state => state?.saveDataNewUser.data);

    useEffect(() => {
        if (userDataFromLocalStorage) {
            dispatch(saveNewUserData(userDataFromLocalStorage));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeUserDataFromLocalStorage = () => {
        localStorage.removeItem('userData');
    };

    const addDataToFirebase = async () => {
        setIsButtonDisabled(true);
        if (userData !== undefined) {
            try {
                // Удаление данных пользователя из локального хранилища
                removeUserDataFromLocalStorage();

                await registerWithEmailAndPassword(
                    userData.email,
                    userData.password,
                    userData.fullName,
                    userData.userPrice,

                    userData.city,
                    userData.country,
                    userData.discription,
                    userData.flatNumber,
                    userData.houseNumber,
                    userData.orderNumber,
                    userData.phone,
                    userData.source,
                    userData.street,
                    userData.zipCode,

                    navigate,
                    dispatch,
                    t,

                    firstNamePersone,
                    lastNamePersone,
                    fathersNamePersons,
                    birthDatePersone,
                    deathDatePersone,
                    storyPersone,
                    placeOfBirth,
                    memorialPlace,
                    spouse,
                    children,
                    education,
                    hobbies,
                    userLocation,

                    isUserRegistered,
                    createUserWithQrCode
                );

            } catch (error) {
                console.error("Ошибка при регистрации:", error);
                alert(t('registration_error_message'))
            }
        }
    };

    return (
        <>
            <div className="assist-payment-container">

                <img src={registration} alt="img signUp" loading="eager" />

                <div className="assist-payment-text">

                    <h2>{t('thank_you_for_registering')}</h2>

                    <p>
                        {t('first_desc_registration_done')}
                    </p>


                    <div style={{ margin: 'auto' }}>
                        <button
                            style={{ height: 80, width: 300 }}
                            className='regular-button'
                            onClick={() => addDataToFirebase()}
                            disabled={isButtonDisabled}
                        > {t('next')}</button>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default AssistPayment;