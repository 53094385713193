import React, { useEffect, useState } from 'react';
import './Examples.css';

import pavelosipovich from '../../Images/pavelosipovich.jpg';
import michailvisockiy from '../../Images/michailvisockiy.jpg';
import bodrov from '../../Images/bodrov.jpg';
import faina from '../../Images/faina.jpg';

import suhoi_qr from '../../Images/qr-code-examples/suhoi.png';
import visockiy_qr from '../../Images/qr-code-examples//visockiy.png';
import bodrov_qr from '../../Images/qr-code-examples/bodrov.png';
import faina_qr from '../../Images/qr-code-examples/faina.png';

import Spinner from '../../Components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

const Examples = () => {
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();


    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            const image = new Image();
            image.src = pavelosipovich && michailvisockiy && bodrov && faina;
            image.onload = () => {
                // Обработка после успешной загрузки изображения
                setLoading(false);
            };
        }, 200);
    }, []);

    const peopleData = [
        {
            name: t('exampleFirstName'),
            lastName: t('exampleLastName'),
            years: t('exampleYears'),
            image: pavelosipovich,
            image_qr: suhoi_qr,
            link: "https://qrmoment.by/7s4Bd1FdnW/EnterWithQrCode"
        },
        {
            name: t('exampleFirstName2'),
            lastName: t('exampleLastName2'),
            years: t('exampleYears2'),
            image: michailvisockiy,
            image_qr: visockiy_qr,
            link: "https://qrmoment.by/ck3tQtfGt5/EnterWithQrCode"
        },
        {
            name: t('exampleFirstName3'),
            lastName: t('exampleLastName3'),
            years: t('exampleYears3'),
            image: bodrov,
            image_qr: bodrov_qr,
            link: "https://qrmoment.by/RYtm1AdaQl/EnterWithQrCode"
        },
        {
            name: t('exampleFirstName4'),
            lastName: t('exampleLastName4'),
            years: t('exampleYears4'),
            image: faina,
            image_qr: faina_qr,
            link: "https://qrmoment.by/wSpyFjKofT/EnterWithQrCode"
        },
    ];

    const openLink = (url) => {
        window.open(url);
    };

    //Откроеться в том же окне
    // const openLink = (url) => {
    //     window.location.href = url;
    // };

    return (
        <>
            {loading ? (
                <Spinner loading={loading} />
            ) : (
                <div className='example-container'>
                    <div className='example-text-container'>
                        <div className='example-content'>
                            {peopleData.map((person, index) => (
                                <div key={index}>
                                    <button onClick={() => openLink(person.link)}>
                                        <img src={person.image} alt="img" loading="eager" />
                                        <h4>{person.lastName}</h4>
                                        <h4>{person.name}</h4>
                            
                                        <p>{person.years}</p>

                                    </button>
                                    <div className='example-qr-code' style={{ margin: 'auto' }}>
                                        <img src={person.image_qr} alt="img" />
                                        <p>{t('scanMe')}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            )}
        </>

    );
};

export default Examples;
