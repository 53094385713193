import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import { getStorage, ref, uploadString } from 'firebase/storage';
import RegularButton from '../RegularButton/RegularButton';
import Footer from '../../Screens/Footer/Footer';

const GenerateLogoQRCode = () => {
    const [filename, setFilename] = useState("userId");

    const color = "#000"
    const companyName = 'QR MOMENT';

    const email = "qrmoment.by";
    const userId = 'qrmoment.by';

    useEffect(() => {
        setFilename(`${email}.png`);
    }, [email, userId]);

    const generateQRCode = async () => {
     
        if (filename) {
            try {
                const updatedUrl = `https://qrmoment.by/`;
    
                // Создаем QR-код
                const qrCanvas = document.createElement('canvas');
                const qrCtx = qrCanvas.getContext('2d');
                await QRCode.toCanvas(qrCanvas, updatedUrl, {
                    width: 2500,
                    margin: 1,
                    color: {
                        dark: color,
                        // light: "#0000"
                    },
                });
    
                // Создаем canvas для текста
                const textCanvas = document.createElement('canvas');
                const textCtx = textCanvas.getContext('2d');
                textCanvas.width = qrCanvas.width;
                textCanvas.height = qrCanvas.height;
    
                // Рисуем белый прямоугольник в центре
                const centerX = qrCanvas.width / 2;
                const centerY = qrCanvas.height / 2;
                const textWidth = textCtx.measureText(companyName).width;
                const textHeight = 180; // Высота текста
    
                const rectangleWidth = textWidth + 1200; // Увеличиваем ширину прямоугольника на 20 пикселей

                textCtx.fillStyle = 'white';
                textCtx.fillRect(centerX - rectangleWidth / 2, centerY - textHeight / 2, rectangleWidth, textHeight);
    
                // Задаем параметры для названия компании
                textCtx.fillStyle = "#000";
                textCtx.font = 'bold 180px Arial';
                textCtx.textAlign = 'center';
                textCtx.textBaseline = 'middle'; // Выравниваем текст по центру
    
                textCtx.fillText(companyName, centerX, centerY);
    
                // Объединяем QR-код и текст
                qrCtx.drawImage(textCanvas, 0, 0);
    
                // Загрузка изображения в Firebase Storage
                const storage = getStorage();
                const storageRef = ref(storage, `${email}/QrCode/${userId}`);
                await uploadString(storageRef, qrCanvas.toDataURL('image/png'), 'data_url');
    
                alert("Qr code создан успешно!");
    
                console.log(`QR Code for ${updatedUrl} created and saved as ${userId}`);
            } catch (err) {
                console.error('Error creating QR Code:', err);
            } finally {
            }
        }
    };


    return (
        <div>
                <div className="generate-qr-container" style={{ height: 700, color: 'white' }}>
                    <p>Создаем qr code для лого сохраняем его в firebase storage под именем http://qrmoment.com/</p>
                    <RegularButton
                        onClick={generateQRCode}
                        buttonText="Create QrCode"
                    />
                </div>
            <Footer />
        </div>
    );
};

export default GenerateLogoQRCode;