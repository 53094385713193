import React, { useEffect } from "react";
import './RegistrationDone.css';
import { useLocation } from "react-router-dom";
import GenerateQRCode from "../../Components/GenerateQRCode/GenerateQRCode";
import { useTranslation } from "react-i18next";
import registration from '../../Images/registration.jpg';
import Footer from "../Footer/Footer";

const RegistrationDone = ({ setLoading, userLanguage }) => {
    const location = useLocation();
    const { t } = useTranslation();

    const { userId, email, firstNamePersone, lastNamePersone, EnterWithQrCode } = location.state || {};

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            const image = new Image();
            image.src = registration;
            image.onload = () => {
                // Обработка после успешной загрузки изображения
                setLoading(false);
            };
        }, 200);
        setLoading(false);
    }, [setLoading]);

    return (
        <>
            <div className="registration-done-container">

                <img src={registration} alt="img signUp" loading="eager" />

                <div className="registration-done-text">

                    {/* <h2>{(t('good_news'))}</h2> */}
                    {/* <h2>{t('thank_you_for_registering')}</h2>

                    <p>
                        {t('first_desc_registration_done')}
                    </p> */}
                    <h2>
                        {t('third_desc_registration_done')}
                    </h2>

                    <p>
                        {t('second_desc_registration_done')}
                    </p>

                    <div className="registration-done-btn">
                        <GenerateQRCode
                            userId={userId}
                            email={email}
                            firstNamePersone={firstNamePersone}
                            lastNamePersone={lastNamePersone}
                            setLoading={setLoading}
                            EnterWithQrCode={EnterWithQrCode}
                        />
                    </div>
                </div>
            </div>
            <Footer userLanguage={userLanguage} />

        </>
    );
}

export default RegistrationDone;
