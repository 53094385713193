import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, } from "react-router-dom";
import './AboutPersonComponent.css';
import { useDispatch, useSelector } from "react-redux";
import RegularInput from "../../Components/RegularInput/RegularInput";
import RegularButton from "../../Components/RegularButton/RegularButton";
import { useTranslation } from "react-i18next";
import registration from '../../Images/registration.jpg';
import services from '../../Images//services.jpg';
import Footer from "../Footer/Footer";
import Alert from "../../Components/Alert/Alert";

import { getUserData, updateUserData } from "../../Redux/actions/actions";

const AboutPersonComponent = ({ userLanguage, setLoading, }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const currentUser = useSelector((state) => state.userData);

    const { storedUserId } = location.state || {};
    
    useEffect(() => {
        setLoading(true);
        dispatch(getUserData());
        setLoading(false);
    }, [dispatch, setLoading]);

    const {
        birthDatePersone,
        deathDatePersone,
        firstNamePersone,
        lastNamePersone,
        fathersNamePersons,
        storyPersone,
        memorialPlace,
        spouse,
        children,
        education,
        hobbies,
        placeOfBirth,
        uid
    } = currentUser;

    const [birthDatePersoneUpadte, setBirthDatePersoneUpadte] = useState(birthDatePersone);
    const [deathDatePersoneUpadte, setDeathDatePersoneUpadte] = useState(deathDatePersone || "1900-01-01");
    const [firstNamePersoneUpadte, setFirstNamePersoneUpadte] = useState(firstNamePersone);
    const [lastNamePersoneUpadte, setLastNamePersoneUpadte] = useState(lastNamePersone);
    const [fathersNamePersonsUpdate, setFathersNamePersonsUpdate] = useState(fathersNamePersons)
    const [storyPersoneUpadte, setStoryPersoneUpadte] = useState(storyPersone);
    const [memorialPlaceUpadte, setMemorialPlaceUpadte] = useState(memorialPlace);
    const [spouseUpadte, setSpouseUpadte] = useState(spouse);
    const [childrenUpadte, setChildrenUpadte] = useState(children);
    const [educationUpadte, setEducationUpadte] = useState(education);
    const [hobbiesUpadte, setHobbiesUpadte] = useState(hobbies);
    const [placeOfBirthUpadte, setPlaceOfBirthUpadte] = useState(placeOfBirth);

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            const image = new Image();
            image.src = registration && services;
            image.onload = () => {
                // Обработка после успешной загрузки изображения
                setLoading(false);
            };
            setLoading(true);
        }, 200);
        setLoading(false)
    }, [setLoading]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setAlertVisible(false);
        setLoading(true);

        if (firstNamePersoneUpadte === "" || firstNamePersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('the_name_is_required'));
        } else if (lastNamePersoneUpadte === "" || lastNamePersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('last_name_is_required'));
            
        } else if (birthDatePersoneUpadte === "" || birthDatePersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('date_of_birth_is_required'));
            
        } else if (deathDatePersoneUpadte === "" || deathDatePersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('date_of_departure_is_required'));
            
        } else if (storyPersoneUpadte === "" || storyPersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('please_tell_us_about_the_person_this_is_a_required_field'));
            
        }
        else {
            const updatedData = {
                firstNamePersone: firstNamePersoneUpadte,
                lastNamePersone: lastNamePersoneUpadte,
                fathersNamePersons: fathersNamePersonsUpdate,
                birthDatePersone: birthDatePersoneUpadte,
                deathDatePersone: deathDatePersoneUpadte,
                storyPersone: storyPersoneUpadte,
                placeOfBirth: placeOfBirthUpadte,
                memorialPlace: memorialPlaceUpadte,
                spouse: spouseUpadte,
                children: childrenUpadte,
                education: educationUpadte,
                hobbies: hobbiesUpadte,
            };

            await dispatch(updateUserData(uid, updatedData));
            navigate(`/${storedUserId}/PersoneHistory`);
        }

        setLoading(false);
    }
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];
    return (
        <>
            {alertVisible && (
                <Alert
                    title={alertTitle}
                    text={null}
                    icon={"info"}
                    confirmButtonText={"Ok"}
                    onConfirm={() => setAlertVisible(false)}
                    showCancelButton={false}
                    setAlertVisible={setAlertVisible}
                    alertVisible={alertVisible}
                />
            )}

            <div className="person-form-container" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
                <img src={registration} alt="img signUp" loading="eager" />

                <div className="persone-input-container">
                    <h2>{t('add_information_about_your_loved_one')}</h2>

                    <RegularInput
                        type="text"
                        value={firstNamePersoneUpadte !== null ? firstNamePersoneUpadte : ''}
                        onChange={(e) => setFirstNamePersoneUpadte(e.target.value)}
                        required
                        label="name"
                        placeholder="name"
                    />

                    <RegularInput
                        type="text"
                        value={lastNamePersoneUpadte !== null ? lastNamePersoneUpadte : ''}
                        onChange={(e) => setLastNamePersoneUpadte(e.target.value)}
                        required
                        label="surname"
                        placeholder="surname"
                    />

                    {userLanguage?.includes("ru") ?
                        <RegularInput
                            type="text"
                            value={fathersNamePersonsUpdate !== null ? fathersNamePersonsUpdate : ''}
                            onChange={(e) => setFathersNamePersonsUpdate(e.target.value)}
                            required
                            label="fathersName"
                            placeholder="fathersName"
                        />
                        :
                        null}

                    <RegularInput
                        type="date"
                        value={birthDatePersoneUpadte !== null ? birthDatePersoneUpadte : formattedCurrentDate}
                        onChange={(e) => setBirthDatePersoneUpadte(e.target.value)}
                        required
                        label="date_of_Birth"
                        placeholder="date_of_Birth"
                    />

                    <RegularInput
                        type="date"
                        value={deathDatePersoneUpadte !== null ? deathDatePersoneUpadte : formattedCurrentDate}
                        onChange={(e) => setDeathDatePersoneUpadte(e.target.value)}
                        required
                        label="date_of_departure"
                        placeholder="date_of_departure"

                    />

                    <RegularInput
                        type="textarea"
                        value={storyPersoneUpadte !== null ? storyPersoneUpadte : ''}
                        onChange={(e) => setStoryPersoneUpadte(e.target.value)}
                        required
                        label="tell_us_about_the_person"
                        placeholder="tell_us_about_the_person"
                    // styleTextArea={{ width: 350 }}
                    />

                </div >
            </div >

            <div className="person-more-input-form-container" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
                <img src={services} alt="img signUp" loading="eager" />

                <div className="persone-more-input-container">
                    <h2>{t('more_details')} </h2>
                    <RegularInput
                        type="text"
                        value={placeOfBirthUpadte !== null ? placeOfBirthUpadte : ''}
                        onChange={(e) => setPlaceOfBirthUpadte(e.target.value)}
                        label="place_of_Birth"
                        placeholder="place_of_Birth"

                    />

                    <RegularInput
                        type="text"
                        value={memorialPlaceUpadte !== null ? memorialPlaceUpadte : ''}
                        onChange={(e) => setMemorialPlaceUpadte(e.target.value)}
                        label="memorial_site"
                        placeholder="memorial_site"

                    />

                    <RegularInput
                        type="text"
                        value={spouseUpadte !== null ? spouseUpadte : ''}
                        onChange={(e) => setSpouseUpadte(e.target.value)}
                        label="spouse"
                        placeholder="spouse"

                    />
                    <RegularInput
                        type="text"
                        value={childrenUpadte !== null ? childrenUpadte : ''}
                        onChange={(e) => setChildrenUpadte(e.target.value)}
                        label="children"
                        placeholder="children"

                    />
                    <RegularInput
                        type="text"
                        value={educationUpadte !== null ? educationUpadte : ''}
                        onChange={(e) => setEducationUpadte(e.target.value)}
                        label="education"
                        placeholder="education"

                    />
                    <RegularInput
                        type="text"
                        value={hobbiesUpadte !== null ? hobbiesUpadte : ''}
                        onChange={(e) => setHobbiesUpadte(e.target.value)}
                        label="hobby"
                        placeholder="hobby"

                    />

                    <div className="persone-btn-container">
                        <RegularButton
                            buttonText="continue"
                            onClick={handleSubmit}
                        />
                    </div>
                </div >
            </div >
            <Footer />
        </>
    )
}

export default AboutPersonComponent;