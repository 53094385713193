import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import PersonHistory from '../PersoneHistory/PersoneHistory';
import './EnterWithQrCode.css';
import logo_primary from '../../Images/logos/logo_primary_main.png';
import CookieConsent from '../../Components/CookieConsent/CookieConsent';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EnterWithQrCode = ({ userLanguage, setLoading, scrollPosition }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showCookieConsent, setShowCookieConsent] = useState(true);
    const { t } = useTranslation();

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            setModalIsOpen(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleCookieConsent = () => {
        setShowCookieConsent(false); // После получения согласия скрываем компонент
    };


    return (
        <div>
                <PersonHistory
                    userLanguage={userLanguage}
                    setLoading={setLoading}
                    scrollPosition={scrollPosition}
                    EnterWithQrCode={true}
                />
    
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="banner-modal">
                <span className="close-btn" onClick={closeModal}>×</span>
                <div className="banner-modal-img">
                    <img
                        src={logo_primary}
                        alt="logo"
                        loading="eager"
                    />
                </div>
                <h2>{t('would_you_like_to_purchase_a_qr_code')}</h2>

                <button
                    onClick={() => navigate('/SignUpForm')}>
                    {t('signup')}
                </button>
            </Modal>
            {showCookieConsent && <CookieConsent handleCookieConsent={handleCookieConsent} />}

        </div>
    );
};

export default EnterWithQrCode;