// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      he: {
        translation: require('./Components/Languages/he/translation_he.json')
      },
      ru: {
        translation: require('./Components/Languages/ru/translation_ru.json')

      },
      en: {
        translation: require('./Components/Languages/en/translation_en.json')

      }

    },
    lng: "ru", // if you're using a language detector, do not define the lng option
    fallbackLng: "ru",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;
