import { addDoc, arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { app } from '../../Components/Firebase/Firebase';
import fs, { db } from '../../Components/Firebase/Firebase';

const storage = getStorage(app);

export const getUserData = (userId) => {
  // console.log(userId,'userId Action');
  return async (dispatch) => {
    try {
      const colRef = collection(fs, 'users');
      const docRef = doc(colRef, userId); // Используйте userId для получения данных текущего пользователя
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        dispatch(updateData(userData, true));
      } else {
        // console.log("User not found");
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateUserData = (userId, updatedData) => {
  return async (dispatch) => {
    try {
      const userDocRef = doc(collection(fs, 'users'), userId);
      await updateDoc(userDocRef, updatedData);

      // После успешного обновления данных в Firebase, обновляем данные в Redux
      dispatch({
        type: 'UPDATE_USER_DATA',
        payload: updatedData,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const ADD_FREE_TEXT = 'ADD_FREE_TEXT';

export const addFreeText = (storedUserId, freeText, authorOfText,) => async (dispatch) => {
  try {
    const userDocRef = doc(db, 'users', storedUserId);
    // Update the document with arrayUnion 
    // if (moreBiography === true) {
    //   await updateDoc(userDocRef, {
    //     moreBiography: arrayUnion({
    //       freeTextData: freeText,
    //       authorOfText: authorOfText,
    //     }),
    //   });
    // } else {
    await updateDoc(userDocRef, {
      freeTexts: arrayUnion({
        freeTextData: freeText,
        authorOfText: authorOfText,
      }),
    });
    // }


    // Dispatch an action to update the Redux state if needed
    dispatch({
      type: ADD_FREE_TEXT,
      payload: {
        freeTextData: freeText,
        authorOfText: authorOfText,
      },
    });

    // Optionally, you can return any additional data you need
    return { success: true };
  } catch (error) {
    console.error(error.message);
    // You might want to dispatch an error action here if needed
    return { success: false, error: error.message };
  }
};

export const updateData = (data) => ({
  type: 'UPDATE_USER_DATA',
  payload: data,
});


const ADD_MESSAGE = () => {
  return {
    type: 'ADD_MESSAGE',
  };
};

export const addMessage = (name, lastName, phone, email, treatmentCategory, message) => async (dispatch) => {
  try {
    const messagesCollectionRef = collection(db, 'messages');

    // Add a new document to the 'messages' collection
    const newMessageRef = await addDoc(messagesCollectionRef, {
      name,
      lastName,
      phone,
      email,
      treatmentCategory,
      message,
      timestamp: new Date().toISOString(),
    });

    // console.log('Message added to the collection with ID: ', newMessageRef.id);

    // Dispatch an action to update the Redux state if needed
    dispatch({
      type: ADD_MESSAGE,
      payload: {
        name,
        lastName,
        phone,
        email,
        message,
        timestamp: new Date().toISOString(),
        messageId: newMessageRef.id,
      },
    });

    // Optionally, you can return any additional data you need
    return { success: true, messageId: newMessageRef.id };
  } catch (error) {
    console.error(error.message);
    // You might want to dispatch an error action here if needed
    return { success: false, error: error.message };
  }
};


// actions.js
export const uploadPhotos = (uid, newPhotos) => async (dispatch) => {
  try {
    const storageRef = ref(storage, `${uid}/images/`);

    // Проверяем, что newPhotos является массивом
    const photosArray = Array.isArray(newPhotos) ? newPhotos : [newPhotos];

    // Загружаем каждую фотографию
    const uploadPromises = photosArray.map(async (photo) => {
      const photoRef = ref(storageRef, photo.name);
      await uploadBytes(photoRef, photo);

      // Получаем URL загруженной фотографии
      const url = await getDownloadURL(photoRef);
      return url;
    });

    const uploadedPhotoUrls = await Promise.all(uploadPromises);

    // Обновляем Redux state с новыми URL-ами фотографий
    dispatch({
      type: 'UPLOAD_PHOTOS_SUCCESS',
      payload: { uploadedPhotoUrls },
    });

    // console.log('Фотографии успешно загружены в Firebase.');
    return { success: true };
  } catch (error) {
    console.error('Ошибка при загрузке фотографий:', error.message);
    dispatch({
      type: 'UPLOAD_PHOTOS_FAILURE',
      payload: { error: error.message },
    });
    return { success: false, error: error.message };
  }
};

export const setPhotoUrls = (photoUrls) => ({
  type: 'SET_PHOTO_URLS',
  payload: { photoUrls },
});

export const getPhotoUrls = (uid) => async (dispatch) => {
  try {
    const storageRef = ref(storage, `${uid}/images/`);
    const result = await listAll(storageRef);
    const urls = await Promise.all(result.items.map((item) => getDownloadURL(item)));
    dispatch(setPhotoUrls(urls));
  } catch (error) {
    console.error('Ошибка при получении фотографий:', error.message);
    // Дополнительно обработайте ошибку, если это необходимо
  }
};

export const DELETE_PHOTO_REQUEST = 'DELETE_PHOTO_REQUEST';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_FAILURE = 'DELETE_PHOTO_FAILURE';

const deletePhotoRequest = () => ({
  type: DELETE_PHOTO_REQUEST,
});

const deletePhotoSuccess = (url) => ({
  type: DELETE_PHOTO_SUCCESS,
  payload: url,
});

const deletePhotoFailure = (error) => ({
  type: DELETE_PHOTO_FAILURE,
  payload: error,
});

// Async Action to Delete Photo
export const deletePhoto = (uid, photoUrl) => async (dispatch) => {
  dispatch(deletePhotoRequest());

  try {
    const storage = getStorage();
    const photoRef = ref(storage, photoUrl);

    await deleteObject(photoRef);

    dispatch(deletePhotoSuccess(photoUrl));
  } catch (error) {
    dispatch(deletePhotoFailure(error.message));

  }
};

export const ADD_VIDEO_REQUEST = 'ADD_VIDEO_REQUEST';
export const ADD_VIDEO_SUCCESS = 'ADD_VIDEO_SUCCESS';
export const ADD_VIDEO_FAILURE = 'ADD_VIDEO_FAILURE';

export const addVideo = (uid, videos) => async (dispatch) => {
  try {
    dispatch({ type: ADD_VIDEO_REQUEST });

    const storage = getStorage();
    const newVideoData = await Promise.all(
      videos.map(async (file) => {
        try {
          const storageRef = ref(storage, `${uid}/videos/${file.name}`);
          await uploadBytes(storageRef, file);
          const videoDownloadURL = await getDownloadURL(storageRef);

          return { file, url: videoDownloadURL };
        } catch (error) {
          throw new Error('Error saving video: ' + error.message);
        }
      })
    );

    const filteredVideos = newVideoData.filter((video) => video !== null);

    dispatch({
      type: ADD_VIDEO_SUCCESS,
      payload: filteredVideos,
    });

    // Возвращаем данные, которые могут пригодиться в компоненте
    return { success: true, videos: filteredVideos };
  } catch (error) {
    console.error('Error adding video:', error.message);
    dispatch({
      type: ADD_VIDEO_FAILURE,
      payload: error.message,
    });

    // Возвращаем данные об ошибке
    return { success: false, error: error.message };
  }
};

export const DELETE_VIDEO_REQUEST = 'DELETE_VIDEO_REQUEST';
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEO_FAILURE = 'DELETE_VIDEO_FAILURE';

export const deleteVideo = (filePuth) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_VIDEO_REQUEST });

    const storage = getStorage();
    const storageRef = ref(storage, `${filePuth}`);
    await deleteObject(storageRef);

    dispatch({
      type: DELETE_VIDEO_SUCCESS,
      payload: filePuth,
    });

    return { success: true, filePuth };
  } catch (error) {
    console.error('Error deleting video:', error.message);
    dispatch({
      type: DELETE_VIDEO_FAILURE,
      payload: error.message,
    });

    return { success: false, error: error.message };
  }
};

export const FETCH_VIDEOS_REQUEST = 'FETCH_VIDEOS_REQUEST';
export const FETCH_VIDEOS_SUCCESS = 'FETCH_VIDEOS_SUCCESS';
export const FETCH_VIDEOS_FAILURE = 'FETCH_VIDEOS_FAILURE';

export const fetchVideos = (uid) => async (dispatch) => {
  dispatch({ type: FETCH_VIDEOS_REQUEST });

  const storage = getStorage();
  const storageRef = ref(storage, `${uid}/videos/`);

  try {
    const result = await listAll(storageRef);
    const promises = result.items.map((item) => getDownloadURL(item));
    const urls = await Promise.all(promises);

    dispatch({
      type: FETCH_VIDEOS_SUCCESS,
      payload: urls,
    });
  } catch (error) {
    dispatch({
      type: FETCH_VIDEOS_FAILURE,
      payload: error.message,
    });
  }
};


//discount for masons
export const addNewDiscount = (fullName, teudatZeut, phone, city, street, submittedUniqueId) => async (dispatch) => {
  try {

    // const messagesCollectionRef = collection(db, 'discoundCards');

    // Add a new document to the 'messages' collection
    // const newMessageRef = await addDoc(messagesCollectionRef, {
    //   fullName,
    //   teudatZeut,
    //   phone,
    //   city,
    //   street,
    //   submittedUniqueId,
    //   timestamp: new Date().toISOString(),
    // });

    // console.log('Message added to the collection with ID: ', newMessageRef.id);

    // Dispatch an action to update the Redux state if needed
    dispatch({
      type: ADD_MESSAGE,
      payload: {
        fullName,
        teudatZeut,
        phone,
        city,
        street,
        submittedUniqueId,
        timestamp: new Date().toISOString(),
      },
    });

    // Optionally, you can return any additional data you need
    return { success: true, timestamp: new Date().toISOString() };
  } catch (error) {
    console.error(error.message);
    // You might want to dispatch an error action here if needed
    return { success: false, error: error.message };
  }
};

export const getAllDiscountsSuccess = (allDiscounts) => ({
  type: 'GET_ALL_DISCOUNTS_SUCCESS',
  payload: allDiscounts,
});

export const getAllDiscountsFailure = (error) => ({
  type: 'GET_ALL_DISCOUNTS_FAILURE',
  payload: error,
});

export const getAllDiscounts = () => {
  return async (dispatch) => {
    try {
      const colRef = collection(fs, 'discoundCards');
      const querySnapshot = await getDocs(colRef);

      const allDiscounts = [];
      querySnapshot.forEach((doc) => {
        const discountData = doc.data();
        allDiscounts.push(discountData);
      });

      dispatch(getAllDiscountsSuccess(allDiscounts));
    } catch (error) {
      console.error(error);
      dispatch(getAllDiscountsFailure(error));
    }
  };
};

export const logoutAction = () => {
  return { type: 'LOGOUT' };
};

export const uploadPdf = (pdfBlob, email, fileName, fullName, chosenPrice,) => async (dispatch) => {
  try {
    dispatch({ type: 'ADD_PDF_REQUEST' });

    const storage = getStorage();
    const storageRef = ref(storage, `${email}/receipt/${fileName}`);
    await uploadBytes(storageRef, pdfBlob);
    const pdfDownloadURL = await getDownloadURL(storageRef);

    dispatch({
      type: 'ADD_PDF_SUCCESS',
      payload: { file: fileName, url: pdfDownloadURL },
    });

    // Возвращаем данные, которые могут пригодиться в компоненте
    return { success: true, pdfFile: { file: fileName, url: pdfDownloadURL } };
  } catch (error) {
    console.error('Error adding PDF:', error.message);
    dispatch({
      type: 'ADD_PDF_FAILURE',
      payload: error.message,
    });

    // Возвращаем данные об ошибке
    return { success: false, error: error.message };
  }
};

// export const addPdfFileToNewUserCollection = (fullName, chosenPrice, email) => async (dispatch) => {
//   try {
//     const messagesCollectionRef = collection(db, 'new-users');

//     // Add a new document to the 'messages' collection
//     const newMessageRef = await addDoc(messagesCollectionRef, {
//       fullName,
//       chosenPrice,
//       email,
//       timestamp: new Date().toISOString(),
//     });

//     console.log('Email added to the collection with ID: ', newMessageRef.id);

//     // Dispatch an action to update the Redux state if needed
//     dispatch({
//       type: ADD_MESSAGE,
//       payload: {
//         fullName,
//         chosenPrice,
//         timestamp: new Date().toISOString(),
//       },
//     });

//     // Optionally, you can return any additional data you need
//     return { success: true, timestamp: new Date().toISOString() };
//   } catch (error) {
//     console.error(error.message);
//     // You might want to dispatch an error action here if needed
//     return { success: false, error: error.message };
//   }
// };


export const saveNewUserData = (data) => ({
  type: 'SAVE_NEW_USER_DATA',
  payload: data,
});

export const REMOVE_FREE_TEXT = 'REMOVE_FREE_TEXT';

export const removeFreeText = (uid, memory) => async (dispatch) => {
  try {
    const userDocRef = doc(db, 'users', uid);
    await updateDoc(userDocRef, {
      freeTexts: arrayRemove(memory),
    });

    dispatch({
      type: REMOVE_FREE_TEXT,
      payload: {
        freeTextId: memory,
      },
    });
  } catch (error) {
    // Handle error
    console.error('Ошибка при удалении текста:', error);
  }
};


// Ваш actions.js
export const editFreeText = (index, author, uid, editedAuthorOfText) => {
  console.log(uid, 'uid');
  console.log(index, 'index');
  console.log(author, 'author');
  console.log(editedAuthorOfText, 'editedAuthorOfText');
  return async (dispatch) => {
    try {
      const docRef = doc(db, "users", uid);

      // Получаем текущее значение массива из базы данных
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const currentArray = docSnap.data().freeTexts;

        console.log(currentArray, 'currentArray');

        // Удаляем элемент по индексу из массива
        const removedElement = currentArray[index];

        // Обновляем документ в Firestore, передавая удаленный элемент
        await updateDoc(docRef, {
          freeTexts: arrayRemove(removedElement)
        });

        await updateDoc(docRef, {
          freeTexts: arrayUnion({
            authorOfText: author,
            freeTextData: editedAuthorOfText
          })
        });

        console.log('Элемент успешно удален из массива в базе данных.');
        // Вызываем другое действие, которое обновит состояние Redux
        dispatch(getUserData());

      } else {
        console.error('Документ не найден.');
      }
    } catch (error) {
      console.error('Ошибка при удалении элемента из массива:', error);
    }
  };
};





