import React, { useEffect, useState } from 'react';
import remember from '../../Images/home_page.jpg';
import InstructionSteps from '../InstructionSteps/InstructionSteps';
import { useTranslation } from 'react-i18next';
// import RegularButton from '../../Components/RegularButton/RegularButton';
import Examples from '../Examples/Examples';
import Footer from '../Footer/Footer';
import './HomeScreen.css';
import CookieConsent from '../../Components/CookieConsent/CookieConsent';
import ReasonsToCreate from '../ReasonsToCreate/ReasonsToCreate.js';

const HomeScreen = ({ userLanguage, setLoading }) => {
  const { t } = useTranslation();
  const [showCookieConsent, setShowCookieConsent] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      const image = new Image();
      image.src = remember;
      image.onload = () => {
        // Обработка после успешной загрузки изображения
        setLoading(false);
      };
    }, 200);
    setLoading(false);

  }, [setLoading]);


  const handleCookieConsent = () => {
    setShowCookieConsent(false); // После получения согласия скрываем компонент
  };

  useEffect(() => {
    const justifyTextWithoutExtraSpace = () => {
      const elements = document.querySelectorAll('.justify-without-space');

      elements.forEach(element => {
        const words = element.textContent.split(' ');
        const spaceWidth = (element.offsetWidth - element.scrollWidth) / (words.length - 1);
        let newText = '';

        for (let i = 0; i < words.length - 1; i++) {
          newText += words[i] + ' '.repeat(spaceWidth);
        }

        newText += words[words.length - 1];
        element.innerHTML = newText;
      });
    };

    justifyTextWithoutExtraSpace();

    window.addEventListener('resize', justifyTextWithoutExtraSpace);

    return () => {
      window.removeEventListener('resize', justifyTextWithoutExtraSpace);
    };
  }, []);

  return (
    <>

      <div className="home-container" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>

        <div className="home-remember-images">
          <img
            src={remember}
            alt="img home"
            loading="eager"
          />

          {remember === null ? null : <h2>QrMoment</h2>}
          <h4>{t('cherish_the_moment')}</h4>

        </div>

        <div className={userLanguage?.includes('he') ? "home-text-container-hebrew" : "home-text-container"}>


          <div className='home-about-us-section home-text-computer-component'>
            <div className='home-about-us home-about-us-text'>
              <h3 >{t('aboutUs')}</h3>
              <p className='home-text-inded'>{t('aboutQrCodeHomePage-first')}</p>
            </div>
            <div className='home-about-us home-about-us-text'>
              <p className='home-text-inded'>{t('aboutQrCodeHomePage-second')}</p>
            </div>
          </div>



          <div className='home-about-phone-contaioner'>
            <div className="home-text-title-about-us">
              <h3 >{t('aboutUs')}</h3>
            </div>
            <div className='home-about-phone-title'>
              <p>{t('aboutQrCodeHomePage-first')} {t('aboutQrCodeHomePage-second')}</p>
            </div>
          </div>
        </div>


      </div>
      <ReasonsToCreate userLanguage={userLanguage} />
      <div className="home-container" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>


        <div className={userLanguage?.includes('he') ? "home-text-container-hebrew" : "home-text-container"}>
          <div className='home-text-computer-component'>

            <div className="home-text-title-about-us">
              <p >{t('what_can_you_put_on_a_memory_page')}</p>
            </div>

            <div
              className={userLanguage?.includes('he') ? "home-about-us-hebrew" : "home-about-us"}
              style={{ margin: 'auto' }}
            >
              <p >{t('can_you_put')}</p>

            </div>

          </div>
        </div>
      </div>

      <InstructionSteps userLanguage={userLanguage} />
      <Examples userLanguage={userLanguage} />
      <div style={{ paddingBottom: 40, backgroundColor: '#003B46' }} />
      <Footer userLanguage={userLanguage} />

      {showCookieConsent && <CookieConsent handleCookieConsent={handleCookieConsent} />} {/* Показываем CookieConsent, если согласие еще не было получено */}

    </>
  );
};

export default HomeScreen;
