import React, { useState, useRef, useEffect } from 'react';
import './VideoUploader.css';
import { useDispatch, useSelector } from 'react-redux';
import { addVideo, fetchVideos, getUserData } from '../../Redux/actions/actions';
import RegularButton from '../RegularButton/RegularButton';
import { useTranslation } from 'react-i18next';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { CiSquarePlus } from 'react-icons/ci';

const MAX_VIDEO_SIZE_MB = 10000; // Максимальный размер видео в мегабайтах
const MAX_VIDEO_COUNT = 10; // Максимальное количество загружаемых видео

const VideoUploader = ({ userLanguage, setLoading }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData);
    const videoData = useSelector((state) => state.videos.data);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        dispatch(getUserData());
    }, [dispatch])

    const { uid } = userData;

    console.log(videoData, 'videoData');

    const [selectedVideos, setSelectedVideos] = useState([]);
    const inputRef = useRef(null);

    const exceeded_the_maximum_allowed_number_of_videos = t("exceeded_the_maximum_allowed_number_of_videos");
    const video = t('video');
    const exceeds_the_maximum_allowed_size = t('exceeds_the_maximum_allowed_size');
    const error_handling_video = t('error_handling_video');
    const error_saving_videos = t('error_saving_videos');

    const handleVideoChange = async (e) => {
        const files = e.target.files;

        if (!files || files?.length === 0) {
            return;
        }

        try {
            const newVideos = await Promise.all(
                Array.from(files).map(async (file) => {
                    try {
                        if (file.size / (1024 * 1024) > MAX_VIDEO_SIZE_MB) {
                            alert(video + `${file.name}` + exceeds_the_maximum_allowed_size);
                            return null;
                        }
                        return file;
                    } catch (error) {
                        alert(error_handling_video, error);
                        return null;
                    }
                })
            );

            const filteredVideos = newVideos.filter((video) => video !== null);
            if (selectedVideos?.length + filteredVideos?.length > MAX_VIDEO_COUNT || videoData.length >= MAX_VIDEO_COUNT) {
                alert(exceeded_the_maximum_allowed_number_of_videos);
                return;
            }
            setSelectedVideos((prevVideos) => {
                const updatedVideos = [...prevVideos, ...filteredVideos];
                return updatedVideos.slice(0, MAX_VIDEO_COUNT);
            });

        } catch (error) {
            alert(error_handling_video, error);
        }
    };

    const handleDeleteVideo = (index) => {
        const updatedVideos = [...selectedVideos];
        updatedVideos.splice(index, 1);
        setSelectedVideos(updatedVideos);
    };

    const handleSave = async () => {
        setLoading(true);

        try {
            const result = await dispatch(addVideo(uid, selectedVideos, setUploadProgress));
            if (result && result.success) {
                setSelectedVideos([]);
                dispatch(fetchVideos(uid));
            } else {
                // Обработка ошибки
                console.error(error_saving_videos + " " + result.error);
            }
        } catch (error) {
            console.error(error_saving_videos, error);
        } finally {
            setLoading(false);
        }
        // setLoading(false);
    };

    return (
        <>
            {uploadProgress > 0 && uploadProgress < 100 && (
                <div className="progress-container">
                    <div className="progress-bar" style={{ width: `${uploadProgress}%` }}>{uploadProgress}%</div>
                </div>
            )}
            <div className='video-uploader-container' dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
                <label>
                    <div className="custom-file-input-video">
                        <CiSquarePlus
                            className='add-photo-btn'
                        />
                        <input
                            type="file"
                            accept="video/*"
                            onChange={handleVideoChange}
                            style={{ display: 'none' }}
                            ref={inputRef}
                            multiple
                        />
                    </div>
                </label>

                <div className="video-grid">
                    {selectedVideos?.map((video, index) => (
                        <div key={index} className="video-container">
                            <video controls width="100" height="100" style={{ margin: '10px', border: '0.3px solid white' }}>
                                <source
                                    src={URL.createObjectURL(video)} type="video/mp4"
                                />
                            </video>

                            <div>
                                <div className='btn-remove'>
                                    <RiDeleteBin6Line
                                        onClick={() => {
                                            const confirmDelete = window.confirm(t('alert_are_you_sure_you_want_to_delete_this_video'));
                                            if (confirmDelete) {
                                                handleDeleteVideo(index);
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    ))}

                </div>

                {selectedVideos?.length > 0 && (
                    <RegularButton
                        buttonText="save"
                        onClick={handleSave}
                        style={{ width: 200, margin: 40 }}
                    />
                )}
            </div>
        </>
    );
};

export default VideoUploader;
