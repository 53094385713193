import { initializeApp } from 'firebase/app';

// import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
// import { useHistory } from 'react-router-dom';

import {
  // GoogleAuthProvider,
  getAuth,
  // signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updateEmail as updateEmailAuth,
  updatePassword as updatePasswordAuth
} from "firebase/auth";

import {
  getFirestore,
  // query,
  // getDocs,
  // collection,
  // where,
  // addDoc,
  // onSnapshot,
  doc,
  // setDoc,
  writeBatch,
} from "firebase/firestore";
import { getUserData, logoutAction } from '../../Redux/actions/actions';


//Prodaction база данных
export const firebaseConfig = {
  apiKey: "AIzaSyBE27CIUugvYbkReDDLf1KuybLBFwc2754",
  authDomain: "yourqrmemories.firebaseapp.com",
  projectId: "yourqrmemories",
  storageBucket: "yourqrmemories.appspot.com",
  messagingSenderId: "440719795945",
  appId: "1:440719795945:web:5633407ba3303c28923278"
};

//Тестовая база данных 
export const firebaseConfigTest = {
  apiKey: "AIzaSyCoBnK3fxKArQg2Y1d8biVbigWpPLEzysI",
  authDomain: "qrmoment-cbf7b.firebaseapp.com",
  projectId: "qrmoment-cbf7b",
  storageBucket: "qrmoment-cbf7b.appspot.com",
  messagingSenderId: "340256189611",
  appId: "1:340256189611:web:61391534367c334e695bee",
  measurementId: "G-SFQDZE77CN"
};

// Функция для определения окружения на основе URL
const getEnvironment = () => {
  return window.location.hostname === "localhost" ? "test" : "prod";
};

// Выбор конфигурации на основе окружения
const selectedFirebaseConfig = getEnvironment() === "test" ? firebaseConfigTest : firebaseConfig;

// Инициализация приложения Firebase с выбранной конфигурацией
const app = initializeApp(selectedFirebaseConfig);

const updateEmail = updateEmailAuth;
const updatePassword = updatePasswordAuth;

// const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const fs = getFirestore(app)
export default fs;


// const googleProvider = new GoogleAuthProvider();

// const signInWithGoogle = async () => {
//   try {
//     const res = await signInWithPopup(auth, googleProvider);
//     const user = res.user;
//     const q = query(collection(db, "users"), where("uid", "==", user.uid));
//     const docs = await getDocs(q);
//     if (docs.docs.length === 0) {
//       await addDoc(collection(db, "users"), {
//         uid: user.uid,
//         name: user.displayName,
//         authProvider: "google",
//         email: user.email,
//       });
//     }
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };


const logInWithEmailAndPassword = async (email, password, navigate, dispatch, t) => {

  try {
    await signInWithEmailAndPassword(auth, email, password, t);
    const userId = auth.currentUser.uid
    const shortenedUserId = userId?.substring(0, 10);

    dispatch(getUserData(userId));
    localStorage.setItem('userId', shortenedUserId);

    navigate(`/${shortenedUserId}/PersoneHistory`);
  } catch (err) {
    console.error(err.message);
    alert(t('invalid_username_password'));
  }
};

const loginAfterRegistration = async (email, password, navigate, dispatch, t) => {
  try {
    await signInWithEmailAndPassword(auth, email, password, t);
    const userId = auth.currentUser.uid
    const shortenedUserId = userId?.substring(0, 10);

    dispatch(getUserData(userId));
    localStorage.setItem('userId', shortenedUserId);

  } catch (err) {
    console.error(err.message);
    alert(t('error_after_registration_login'));
  }
};

const registerWithEmailAndPassword = async (
  email,
  password,
  fullName,
  userPrice,

  city,
  country,
  discription,
  flatNumber,
  houseNumber,
  orderNumber,
  phone,
  source,
  street,
  zipCode,

  navigate,
  dispatch,
  t,

  firstNamePersone,
  lastNamePersone,
  fathersNamePersons,
  birthDatePersone,
  deathDatePersone,
  storyPersone,
  placeOfBirth,
  memorialPlace,
  spouse,
  children,
  education,
  hobbies,
  userLocation,

  isUserRegistered,
  createUserWithQrCode
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      if (res.user) {
        const batch = writeBatch(db);

        const userDocRef = doc(db, "users", res.user.uid);
        const sentEmailsDocRef = doc(db, "new-users", res.user.uid);

        batch.set(userDocRef, {
          uid: res.user.uid,
          email,
          password,
          fullName,
          userPrice,

          city,
          country,
          discription,
          flatNumber,
          houseNumber,
          orderNumber,
          phone,
          source,
          street,
          zipCode,

          firstNamePersone,
          lastNamePersone,
          fathersNamePersons,
          birthDatePersone,
          deathDatePersone,
          storyPersone,
          placeOfBirth,
          memorialPlace,
          spouse,
          children,
          education,
          hobbies,
          userLocation,

          isUserRegistered,
          createUserWithQrCode,

          dateOfCreation: new Date().toISOString(),
        });

        batch.set(sentEmailsDocRef, {
          uid: res.user.uid,
          price: userPrice,
          country,
          email,
          fullName,
          phone,
          dateOfCreation: new Date().toISOString(),
        });

        await batch.commit();

        navigate(`/RegistrationDone`, {
          state: {
            userId: res.user.uid,
            email: email,
          }
        });
        await loginAfterRegistration(email, password, navigate, dispatch, t);
        resolve(res.user.uid);

      } else {
        alert(t('user_registration_error'));
        reject(t('user_registration_error'));
        navigate(`/Error`)
      }
    } catch (err) {
      console.error(err.message);
      reject(err.message);
      alert(err.message, 'err.message')
      navigate(`/Error`)
    }
  });
};


const sendPasswordReset = async (email, t) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert(t('password_reset_link_sent') + ` ${email}`);
  } catch (err) {
    console.error(err, 'error');
    alert("Ошибка: " + err.message);
  }
};

const logout = (dispatch) => {
  signOut(auth);
  localStorage.removeItem('userId');
  localStorage.removeItem('qrCodeId');
  dispatch(logoutAction());
};


export {
  auth,
  db,
  app,
  // signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,

  updateEmail,
  updatePassword
};