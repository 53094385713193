import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './DiscountForm.css'
import RegularInput from '../../Components/RegularInput/RegularInput';
import RegularButton from '../../Components/RegularButton/RegularButton';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../Components/Spinner/Spinner';
import { addNewDiscount } from '../../Redux/actions/actions';
import Footer from '../Footer/Footer';

const DiscountForm = ({ userLanguage, }) => {
    const [loading, setLoading] = useState(false);
    const [fullName, setFullName] = useState("");
    const [teudatZeut, setTeudatZeut] = useState("");
    const [phone, setPhone] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [submittedUniqueId, setSubmittedUniqueId] = useState(""); // Добавим state для хранения полученного uniqueId
    const [submitted, setSubmitted] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        setLoading(true);
        if (fullName === "" && teudatZeut === "" && city === "" && street === "") {
            alert("Заполните форму!")
        } else {
            e.preventDefault();
            // Генерация уникального ID с большими буквами (максимум 6 символов)
            const uniqueId = uuidv4().substring(0, 6).toUpperCase();

            // Обновляем state с полученным uniqueId
            setSubmittedUniqueId(uniqueId);
            setSubmitted(true);

            try {
                const result = await dispatch(addNewDiscount(fullName, teudatZeut, phone, city, street, uniqueId));
                if (result && result.success === true) {
                    setFullName('');
                    setTeudatZeut('');
                    setPhone('');
                    setCity('');
                    setStreet('');
                } else {
                    // Handle error actions here
                    console.error(result || "Unknown error");
                }
            } catch (error) {
                console.error(error);
                navigate('/Error');
            } finally {
                setLoading(false);
            }
        }
    };


    const resetAll = () => {
        setSubmitted("");
        setSubmitted(false);
    }

    return (
        <>
            {loading ?
                <Spinner /> :

                <>

                    <div className="form-discount">
                        <h2>Cooperating Companies Form</h2>

                        {submitted ? (
                            <>
                                <h3>Discount number: {submittedUniqueId}</h3>
                                
                                <div className='discount-form-btn'>

                                    <RegularButton
                                        buttonText="Reset"
                                        onClick={resetAll}
                                        style={{ width: 220, marginTop: 10 }}
                                    />
                                </div>
                            </>
                        ) : (
                            <div>
                                <RegularInput
                                    type="text"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                    required
                                    placeholder="Full Name/Company name"
                                    style={{ width: 200, marginTop: 20 }}
                                />

                                <RegularInput
                                    type="text"
                                    value={teudatZeut}
                                    onChange={(e) => setTeudatZeut(e.target.value)}
                                    required
                                    placeholder="Teudat Zeut/Mispar hevra"
                                    style={{ width: 200, marginTop: 20 }}
                                />

                                <RegularInput
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    required
                                    placeholder="Phone"
                                    style={{ width: 200, marginTop: 20 }}
                                />

                                <RegularInput
                                    type="text"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                    placeholder="City"
                                    style={{ width: 200, marginTop: 20 }}
                                />

                                <RegularInput
                                    type="text"
                                    value={street}
                                    onChange={(e) => setStreet(e.target.value)}
                                    required
                                    placeholder="Street"
                                    style={{ width: 200, marginTop: 20 }}
                                />
                                <div className='discount-form-btn'>
                                    <RegularButton
                                        buttonText="Save"
                                        onClick={handleSubmit}
                                        style={{ width: 240, marginTop: 20 }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <Footer userLanguage={userLanguage} />
                </>
            }
        </>
    );
};

export default DiscountForm;
