import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

import './SignInForm.css';

import { logInWithEmailAndPassword } from "../../Components/Firebase/Firebase";
import { getUserData } from "../../Redux/actions/actions";
import RegularButton from "../../Components/RegularButton/RegularButton";
import RegularInput from "../../Components/RegularInput/RegularInput";
import { useTranslation } from "react-i18next";
import login from '../../Images/login.jpg';
import Footer from "../Footer/Footer";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app, db, updateEmail, updatePassword } from "../../Components/Firebase/Firebase";
import { doc, setDoc } from "firebase/firestore";
import { updateUserData } from "../../Redux/actions/actions";

const SignInForm = ({ userLanguage, setLoading }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isTemporaryLogin, setIsTemporaryLogin] = useState(false); // Состояние для отслеживания выбранной вкладки

    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('+375');
    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [registrationError, setRegistrationError] = useState('');


    const { t } = useTranslation();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    // const usersData = useSelector((state) => state.usersData);

    useEffect(() => {
        setLoading(true);
        dispatch(getUserData());
        setLoading(false);
    }, [dispatch, setLoading]);

    const handleLogin = async () => {
        setLoading(true);

        try {
            await logInWithEmailAndPassword(email, password, navigate, dispatch, t);
        } catch (err) {
            // console.error(err.message);
            alert(t('invalid_username_password'));
            setLoading(false);

        }
        setLoading(false);

    };
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            const image = new Image();
            image.src = login;
            image.onload = () => {
                // Обработка после успешной загрузки изображения
                setLoading(false);
            };
        }, 200);
        setLoading(false);

    }, [setLoading]);

    const alertPleaseEnterYourName = t('alert_please_enter_your_name');
    const alert_please_enter_a_valid_email_address = t('alert_please_enter_a_valid_email_address');
    const alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number = t('alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number');

    const handleUpdateEmailAndPassword = async () => {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        const passwordRegex = /^(?=.*\d)[\s\S]{6,}$/;
        setLoading(true);
        if (!fullName) {
            setRegistrationError(alertPleaseEnterYourName);
            return;
        }

        else if (!emailRegex.test(email || newEmail)) {
            setRegistrationError(alert_please_enter_a_valid_email_address);
            return;
        }
        else if (!passwordRegex.test(password || newPassword || confirmNewPassword)) {
            setRegistrationError(alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number);
            return;
        }
        else if (!phone) {
            setRegistrationError(t('alert_please_enter_your_phone'));
            return;
        }

        else {
            const auth = getAuth(app);
            try {
                // Авторизуем пользователя с текущими учетными данными
                await signInWithEmailAndPassword(auth, email, password);

                // Если авторизация прошла успешно, обновляем электронный адрес и пароль
                const user = auth.currentUser;

                // Добавляем данные в коллекцию new-users для отправки email покупателю
                const otherCollectionDocRef = doc(db, "new-users", user.uid);
                await setDoc(otherCollectionDocRef, {
                    uid: user.uid,
                    price: "500",
                    country: "Israel",
                    email: newEmail,
                    fullName,
                    phone,
                    dateOfCreation: new Date().toISOString(),
                });
                await updateEmail(user, newEmail);
                await updatePassword(user, newPassword);

                //обновляем данные в коллекции
                const updatedData = {
                    phone: phone,
                    fullName: fullName,
                    email: newEmail,
                    isUserRegistered: true
                };

                // Обновление данных в Firebase и Redux
                await dispatch(updateUserData(user.uid, updatedData));
                const userid = user.uid.substring(0, 10);
                localStorage.setItem('userId', userid);

                navigate(`/${userid}/PersoneHistory`);

                // alert('Email and password updated successfully');
            } catch (error) {
                alert('Error updating email and password:', error.message);
            }
        }

        setLoading(false);
    };


    return (
        <>
            <div className="sign-in-form" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
                <div className='sign-In-titel' >

                    <img src={login} alt="img signUp" loading="eager" className={!isTemporaryLogin ? "sign-In-titel-img" : "sign-In-titel-img-temp"} />

                    <div className="sign-in-input-container">
                        <h4>{t('login_to_your_account_or_register')}</h4>

                        <div className="sign-in-tabs">
                            <button
                                className={!isTemporaryLogin ? "active" : ""} onClick={() => setIsTemporaryLogin(false)}>{t('authorization')}</button>
                            <button className={isTemporaryLogin ? "active" : ""} onClick={() => setIsTemporaryLogin(true)}>{t('replacement_of_temporary_mail')}</button>
                        </div>

                        {isTemporaryLogin ? (
                            <>
                                <div className="temp-sign-title" >
                                    <h4 >{t('please_enter_a_temporary_email_and_password')}</h4>
                                </div>

                                <RegularInput
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    placeholder="temporary_email"
                                />

                                <label style={{ position: 'relative' }}>
                                    <RegularInput
                                        securePassword={true}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="temporary_password"
                                        eyeStyle={userLanguage?.includes("he") ? true : false}

                                    />
                                </label>
                                <div className="temp-sign-title" >
                                    <h4>{t('please_fill_in_the_information')}</h4>
                                </div>
                                <RegularInput
                                    type="text"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                    required
                                    label="recipients_first_and_last_name"
                                    placeholder="recipients_first_and_last_name"
                                />

                                <RegularInput
                                    type="email"
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    required
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    placeholder="new_email"
                                />
                                <label style={{ position: 'relative' }}>
                                    <RegularInput
                                        securePassword={true}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder="new_password"
                                        eyeStyle={userLanguage?.includes("he") ? true : false}

                                    />
                                </label>
                                <label style={{ position: 'relative' }}>
                                    <RegularInput
                                        securePassword={true}
                                        value={confirmNewPassword}
                                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                                        placeholder="confirm_your_new_password"
                                        eyeStyle={userLanguage?.includes("he") ? true : false}

                                    />
                                </label>

                                <RegularInput
                                    type="phone"
                                    value={phone}
                                    onChange={(value) => setPhone(value)}
                                    label="phone"
                                    placeholder="+375"
                                />

                                {registrationError && (
                                    <p style={{ color: 'red', textAlign: 'center' }}>{registrationError}</p>
                                )}

                                <div className='btns-update-email'>
                                    <RegularButton
                                        onClick={handleUpdateEmailAndPassword}
                                        buttonText="next"
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <RegularInput
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    placeholder="email"
                                />



                                <label style={{ position: 'relative' }}>
                                    <RegularInput
                                        securePassword={true}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        eyeStyle={userLanguage?.includes("he") ? true : false}
                                        placeholder="password"

                                    />
                                </label>

                                <div className="sign-in-forgot-password-container">
                                    {/* <div className="sign-in-forgot-password-options">
                                <input type="checkbox" />
                                <p>{t('remember_me')}</p>
                            </div> */}


                                    <div className="sign-in-forgot-password-link">
                                        <Link
                                            to="/Forgot-password"
                                            style={{ color: "white" }}
                                        >
                                            {t('forgot_your_password')}
                                        </Link>
                                    </div>

                                </div>

                                <div className='btns-sign-in'>
                                    <RegularButton
                                        onClick={handleLogin}
                                        buttonText="signIn"
                                    />
                                </div>
                                <div>

                                    <Link to="/SignUpForm" style={{ color: "white", fontSize: 20 }}>
                                        <p>{t('not_registered_yet')}</p>
                                    </Link>

                                </div>
                            </>
                        )
                        }
                    </div>

                </div>
            </div >
            <Footer userLanguage={userLanguage} />

        </>
    );
};

export default SignInForm;
