import React, { useState, useEffect } from 'react';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import { FaPhone, FaEnvelope, } from 'react-icons/fa';
import { LiaViber } from "react-icons/lia";
import { RiTelegramLine } from "react-icons/ri";
import { PiWhatsappLogoFill } from "react-icons/pi";
import Spinner from '../../Components/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { IoMdTime } from "react-icons/io";

import logo_primary from '../../Images/logos/logo_primary_main.png';

import belcome from '../../Images/payment_icons/belcome.png';
import belcome2 from '../../Images/payment_icons/belcome2.png';

import mip from '../../Images/payment_icons/mip.png';
import MIRAccept from '../../Images/payment_icons/MIRAccept.png';

import visa from '../../Images/payment_icons/visa.png';
import VisaSecure from '../../Images/payment_icons/VisaSecure.png';

import Mastercard from '../../Images/payment_icons/Mastercard.png';
import MastercardIDCheck from '../../Images/payment_icons/MastercardIDCheck.png';


import ASSIST from '../../Images/payment_icons/ASSIST.png';


const Footer = ({ userLanguage }) => {
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            const image = new Image();
            image.src = logo_primary;
            image.onload = () => {
                // Обработка после успешной загрузки изображения
                setLoading(false);
            };
        }, 200);
        setLoading(false);

    }, []);

    return (
        <>
            {loading ? (
                <Spinner loading={loading} />
            ) : (
                <div className="footer-container " dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>

                    <div className="footer-section">
                        <img
                            src={logo_primary}
                            alt="logo"
                            loading="eager"
                        />
                    </div>

                    <div className="footer-section-aboutUs">
                        <h4>{t('aboutUs')}</h4>
                        <p>{t('our_product')}</p>
                    </div>

                    <div className="footer-section-aboutUs-contact">
                        <h3 style={{textAlign: 'center'}}>{t('organization')}</h3>
                        <p>{t('sole_proprietor')}</p>
                        <p>{t('resource')} www.qrmoment.by</p>
                        <p>{t('upn')}</p>
                        <p>{t('organization_address')}</p>
                    </div>

                    <div className="footer-section">
                        <h4>{t('contactUs').toUpperCase()}</h4>
                        <div style={{ textAlign: 'center' }}>
                            <p>
                                <a href="tel:+375292346983">
                                    <FaPhone size={20} className={`footer-section-icons ${userLanguage?.includes("he") ? "footer-section-icons-hebrew" : "footer-section-icons"}`} /> +375292346983
                                </a>
                            </p>

                            <p style={{ color: 'white' }}>
                                <IoMdTime size={27} className={`footer-section-icons ${userLanguage?.includes("he") ? "footer-section-icons-hebrew" : "footer-section-icons"}`} />{t('we_are_open')}
                            </p>

                            <p>
                                <a href="mailto:info@qrmoment.com">
                                    <FaEnvelope size={20} className={`footer-section-icons ${userLanguage?.includes("he") ? "footer-section-icons-hebrew" : "footer-section-icons"}`} /> info@qrmoment.com
                                </a>
                            </p>
                        </div>



                        <div className="social-icons">

                            <a href="https://t.me/infoqrmoment" target="_blank" rel="noopener noreferrer">
                                <div>
                                    <RiTelegramLine size={37} />
                                </div>
                            </a>
                            <a href="https://wa.me/375292346983" target="_blank" rel="noopener noreferrer">

                                <div>
                                    <PiWhatsappLogoFill size={37} />
                                </div>
                            </a>
                            <a href="viber://chat?number=%2B375292346983" target="_blank" rel="noopener noreferrer">

                                <div>
                                    <LiaViber size={37} />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            )}

            <div className='footer-privete-policy'>
                <p onClick={() => navigate('/PrivatePolicy', { replace: true })}>Copyright Ⓒ 2024 Ⓡ QrMoment </p>
                <p onClick={() => navigate('/PrivatePolicy', { replace: true })} style={{ marginTop: 2 }}>Privacy Policy</p>
            </div>

            <div className='footer-privete-policy' style={{ backgroundColor: "#C4DFE6" }}>

                <img
                    src={visa}
                    alt="logo"
                    loading="eager"
                    className='footer-privete-payment-img'
                />
                <img
                    src={VisaSecure}
                    alt="logo"
                    loading="eager"
                    className='footer-privete-payment-img'
                />
                <img
                    src={Mastercard}
                    alt="logo"
                    loading="eager"
                    className='footer-privete-payment-img'
                    style={{ width: 50 }}
                />
                <img
                    src={MastercardIDCheck}
                    alt="logo"
                    loading="eager"
                    className='footer-privete-payment-img'
                    style={{ width: 50, height: 40 }}
                />

                <img
                    src={belcome}
                    alt="logo"
                    loading="eager"
                    className='footer-privete-payment-img'
                    style={{ width: 40, height: 40 }}
                />
                <img
                    src={belcome2}
                    alt="logo"
                    loading="eager"
                    className='footer-privete-payment-img'
                />

                <img
                    src={mip}
                    alt="logo"
                    loading="eager"
                    className='footer-privete-payment-img'
                />
                <img
                    src={MIRAccept}
                    alt="logo"
                    loading="eager"
                    className='footer-privete-payment-img'
                    style={{ width: 50, }}
                />
                <img
                    src={ASSIST}
                    alt="logo"
                    loading="eager"
                    style={{ width: 70 }}
                />

            </div>

        </>
    );
}

export default Footer;
