import React, { useEffect, useState } from 'react';
import services from '../../Images/services.jpg';
import './PackagesOffer.css';
import RegularButton from '../../Components/RegularButton/RegularButton';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import Footer from '../Footer/Footer';
import { useDispatch } from 'react-redux';
import { saveNewUserData } from '../../Redux/actions/actions';

const PackagesOffer = ({ userLanguage, setLoading }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { btns, email, fullName, password, personePackage } = location?.state || {};

    const { userPackage, userPrice } = personePackage || {}

    const [selectedPackage, setSelectedPackage] = useState(userPackage ? userPackage : 'premium');
    const [selectPrice, setSelectPrice] = useState(userPrice ? userPrice : "699");

    const handlePackageSelect = (packageType, price) => {
        setSelectedPackage(packageType);
        setSelectPrice(price);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 200,
            behavior: 'smooth',
        });
    };

    const dataUser = {
        userPrice: selectPrice,
        email: email,
        fullName: fullName,
        password: password,
    }

    const handleSubmit = () => {
        setLoading(true);
        dispatch(saveNewUserData(dataUser));

        navigate("/PaymentShiping", {
            state: {
                pathPrev: "/PackagesOffer"
            },
        });
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            const image = new Image();
            image.src = services;
            image.onload = () => {
                // Обработка после успешной загрузки изображения
                setLoading(false);
            };
        }, 200);
        setLoading(false)
    }, [setLoading]);

    const packages = [
        {
            type: 'premium',
            name: t('premium'),
            features: [
                t('personal_site_about_your_loved_one_with_photographs_and_video'),
                t('online_support_when_filling_out_the_page'),
                t('eternal_access_to_the_memory_site'),
                t('code_made_of_durable_metal_with_an_elegant_design_premium'),
                t('delivery_of_a_ready_made_QR_code_across_Belarus_within_10_working_days'),
                t('making_an_additional_qr_code_measuring'),
                t('the_ability_to_create_a_unique_page_design_according_to_your_wishes'),

            ],
            price: 998,
            discountPrice: 499
        },
        {
            type: 'standard',
            name: t('standart'),
            features: [
                t('personal_site_about_your_loved_one_with_photographs_and_video'),
                t('online_support_when_filling_out_the_page'),
                t('eternal_access_to_the_memory_site'),
                t('qr_code_from_durable_metal_with_elegant_design_and_careful_processing'),
                t('delivery_of_a_ready_made_QR_code_across_Belarus_within_10_working_days'),
            ],
            price: 498,
            discountPrice: 249

        },
    ];

    return (
        <>
            <div>
                <div
                    className="packages-container"
                    dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
                    <img src={services} alt="img service" loading="eager" />

                    < div className="package-options-container">
                        <h2>{t('services_price')}</h2>

                        <div className="package-direction">
                            {packages.map((pkg, index) => (
                                <div
                                    key={index}
                                    className={`package-option ${selectedPackage === pkg.type ? 'selected' : ''}`}
                                    onClick={() => handlePackageSelect(pkg.type, pkg.discountPrice)}
                                >
                                    <p>{pkg.name}</p>
                                    <ul style={{ textAlign: userLanguage?.includes('he') ? 'right' : 'left' }}>
                                        {pkg.features.map((feature, index) => (
                                            <div key={index} style={{ display: "flex", minHeight: 50, minWidth: 200 }}>
                                                <IoCheckmarkCircleSharp className="icon-size" />
                                                <li key={index}>
                                                    {feature}
                                                </li>
                                            </div>
                                        ))}
                                        <div>
                                            <h3
                                                style={{ color: 'red', textDecoration: 'line-through', textAlign: 'center' }}>
                                                {pkg.price} BYN
                                            </h3>
                                        </div>
                                        <div >
                                            <h1 style={{ color: 'white' }}>{pkg.discountPrice} BYN</h1>
                                        </div>

                                    </ul>

                                </div>
                            ))}
                        </div >
                        {btns ? null :
                            <RegularButton
                                buttonText="BuyBtn"
                                to={"/SignUpForm"}
                                styleText={{ fontSize: 20, color: "white" }}
                                onClick={scrollToTop}
                                props={{
                                    userPackage: selectedPackage,
                                    userPrice: selectPrice
                                }}
                            />
                        }

                        {!btns ? null :
                            <button
                                className='regular-button'
                                onClick={handleSubmit}>
                                <p>{t('next')}</p>
                            </button>
                        }
                    </div>


                </div >

            </div>
            <Footer userLanguage={userLanguage} />

        </>
    );
};

export default PackagesOffer;