import React, { useEffect, useState, useRef } from 'react';
import { app, auth } from '../../Components/Firebase/Firebase';
import { getDownloadURL, getStorage, listAll, ref, uploadBytes } from 'firebase/storage';

import { useDispatch, useSelector } from 'react-redux';
import { deletePhoto, editFreeText, getPhotoUrls, getUserData, removeFreeText, updateUserData } from '../../Redux/actions/actions';
import RegularInput from '../../Components/RegularInput/RegularInput';
import RegularButton from '../../Components/RegularButton/RegularButton';
import './SettingsPersoneHistory.css';

import { useLocation, useNavigate } from 'react-router-dom';
import noImage from '../../Images/no_data/no-image.png';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';

import { MdAddAPhoto, MdOutlineEdit } from "react-icons/md";
import { IoPerson } from "react-icons/io5";
import { RiInformationFill } from "react-icons/ri";
import { MdAccountCircle } from "react-icons/md";
import { TiLocation } from "react-icons/ti";
import { ImFileText } from "react-icons/im";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaMapMarkerAlt } from "react-icons/fa";


import FreeTextSection from '../FreeTextSection/FreeTextSection';
import Modal from 'react-modal';
import { getAuth, signInWithEmailAndPassword, updateEmail, updatePassword } from 'firebase/auth';
import Alert from '../../Components/Alert/Alert';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';


const SettingsPersoneHistory = ({ userLanguage, storedUserId, setLoading, isMobile }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const state = location?.state;

    const numberTab = state && state.numberTab;

    const currentUser = useSelector((state) => state.userData);

    //get userId from firebase
    const getCurrentUser = auth?.currentUser?.uid;

    useEffect(() => {
        setLoading(true);
        dispatch(getUserData());
        setLoading(false);
    }, [dispatch, setLoading]);

    const {
        birthDatePersone,
        deathDatePersone,
        firstNamePersone,
        lastNamePersone,
        fathersNamePersons,
        storyPersone,
        memorialPlace,
        spouse,
        children,
        education,
        hobbies,
        placeOfBirth,
        userLocation,
        permissionLocation,
        freeTexts,
        uid
    } = currentUser;

    // Управляемые состояния для каждого поля
    const [birthDatePersoneUpadte, setBirthDatePersoneUpadte] = useState(birthDatePersone);
    const [deathDatePersoneUpadte, setDeathDatePersoneUpadte] = useState(deathDatePersone);
    const [firstNamePersoneUpadte, setFirstNamePersoneUpadte] = useState(firstNamePersone);
    const [lastNamePersoneUpadte, setLastNamePersoneUpadte] = useState(lastNamePersone);
    const [fathersNamePersonsUpdate, setFathersNamePersonsUpdate] = useState(fathersNamePersons)
    const [storyPersoneUpadte, setStoryPersoneUpadte] = useState(storyPersone);
    const [memorialPlaceUpadte, setMemorialPlaceUpadte] = useState(memorialPlace);
    const [spouseUpadte, setSpouseUpadte] = useState(spouse);
    const [childrenUpadte, setChildrenUpadte] = useState(children);
    const [educationUpadte, setEducationUpadte] = useState(education);
    const [hobbiesUpadte, setHobbiesUpadte] = useState(hobbies);
    const [placeOfBirthUpadte, setPlaceOfBirthUpadte] = useState(placeOfBirth);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [agreeLocation, setAgreeLocation] = useState(permissionLocation);
    const [currentPosition, setCurrentPosition] = useState(userLocation);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [registrationError, setRegistrationError] = useState('');

    const [editedAuthorOfText, setEditedAuthorOfText] = useState('');
    const [editIndex, setEditIndex] = useState(null);

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");

    const storage = getStorage(app);

    const [photoUrls, setPhotoUrls] = useState([]);
    const fileInputRef = useRef(null);

    const handleAddPhotoClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            uploadPersoneFoto(file);
        }
    };

    const uploadPersoneFoto = async (file) => {
        setLoading(true);

        const storageRef = ref(storage, `${uid}/profile/profile_photo.jpg`);
        await uploadBytes(storageRef, file);

        const downloadURL = await getDownloadURL(storageRef);
        setPhotoUrls([downloadURL]);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        const storageRef = ref(storage, `${uid}/profile`);

        listAll(storageRef)
            .then((result) => Promise.all(result.items.map((item) => getDownloadURL(item))))
            .then((urls) => {
                setPhotoUrls(urls);
            })
            .finally(() => {
                setLoading(false);
            });
        setLoading(false);
    }, [email, storage, setLoading, uid]);

    const handleSaveChanges = async () => {

        if (firstNamePersoneUpadte === "" || firstNamePersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('the_name_is_required'));

        } else if (lastNamePersoneUpadte === "" || lastNamePersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('last_name_is_required'));

        } else if (birthDatePersoneUpadte === "" || birthDatePersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('date_of_birth_is_required'));

        } else if (deathDatePersoneUpadte === "" || deathDatePersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('date_of_departure_is_required'));

        } else if (storyPersoneUpadte === "" || storyPersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('please_tell_us_about_the_person_this_is_a_required_field'));

        }
        else {
            const updatedData = {
                firstNamePersone: firstNamePersoneUpadte,
                lastNamePersone: lastNamePersoneUpadte,
                fathersNamePersons: fathersNamePersonsUpdate,
                birthDatePersone: birthDatePersoneUpadte,
                deathDatePersone: deathDatePersoneUpadte,
                storyPersone: storyPersoneUpadte,
                placeOfBirth: placeOfBirthUpadte,
                memorialPlace: memorialPlaceUpadte,
                spouse: spouseUpadte,
                children: childrenUpadte,
                education: educationUpadte,
                hobbies: hobbiesUpadte,
                userLocation: currentPosition,
                permissionLocation: agreeLocation
            };

            // Обновление данных в Firebase и Redux
            await dispatch(updateUserData(getCurrentUser, updatedData));

            navigate(`/${storedUserId}/PersoneHistory`);
            // setFormIsValid(true)
        }
    };

    const error_checking_or_deleting_image = t('error_checking_or_deleting_image')

    const handleDeletePhoto = async (url) => {
        setLoading(true);
        try {
            // Удаляем фотографию
            await dispatch(deletePhoto(uid, url));

            // Получаем обновленный список фотографий
            await dispatch(getPhotoUrls(uid));
        } catch (error) {
            console.error(error_checking_or_deleting_image + ' ', error);
        } finally {
            setLoading(false);
        }
        setLoading(false);

    };


    useEffect(() => {
        if (!agreeLocation) {
            const permissionGranted = window.confirm('Для определения вашего местоположения, пожалуйста, разрешите доступ к геопозиции.');
            if (permissionGranted) {
                setAgreeLocation(true);
                setCurrentPosition(null); // Сбросим текущую позицию, чтобы вызвать useEffect и обновить местоположение
            }
        }
    }, [agreeLocation]);



    useEffect(() => {
        if (agreeLocation && (!userLocation || userLocation?.latitude === undefined || userLocation?.longitude === undefined)) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    setCurrentPosition({
                        latitude: position?.coords?.latitude,
                        longitude: position?.coords?.longitude,
                    });
                },
                error => {
                    console.error('Error getting user location:', error);
                    setCurrentPosition({ latitude: 32.109333, longitude: 34.855499 });
                }
            );
        }
    }, [agreeLocation, userLocation]);

    const markerIcon = L.divIcon({
        className: 'custom-icon',
        html: ReactDOMServer.renderToString(<FaMapMarkerAlt color='red' size={30} />)
    });

    const [activeTab, setActiveTab] = useState(numberTab ? numberTab : 1);

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    }
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];

    const alert_please_enter_a_valid_email_address = t('alert_please_enter_a_valid_email_address');
    const alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number = t('alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number');

    const updateEmailAndPassword = async () => {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        const passwordRegex = /^(?=.*\d)[\s\S]{6,}$/;

        // Проверяем, что все поля заполнены
        if (newEmail !== "" && newPassword !== "" && confirmNewPassword !== "") {
            setLoading(true);

            // Проверяем формат электронной почты
            if (!emailRegex.test(newEmail)) {
                setRegistrationError(alert_please_enter_a_valid_email_address);
                setLoading(false);
                return;
            }

            // Проверяем формат пароля
            if (!passwordRegex.test(newPassword)) {
                setRegistrationError(alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number);
                setLoading(false);
                return;
            }

            const auth = getAuth(app);
            try {
                // Авторизуем пользователя с текущими учетными данными
                await signInWithEmailAndPassword(auth, email, password);

                // Если авторизация прошла успешно, обновляем электронный адрес и пароль
                const user = auth.currentUser;

                // Обновляем электронный адрес
                await updateEmail(user, newEmail);

                // Обновляем пароль
                await updatePassword(user, newPassword);

                // Обновляем данные в коллекции
                const updatedData = {
                    email: newEmail,
                    password: newPassword
                };

                // Обновление данных в Firebase и Redux
                await dispatch(updateUserData(user.uid, updatedData));

                // Очищаем поля ввода
                setNewEmail('');
                setNewPassword('');
                setConfirmNewPassword('');

                setLoading(false);
                // alert('Email and password updated successfully');
            } catch (error) {
                setLoading(false);
                alert('Error updating email and password:', error.message);
            }
        } else {
            // Если не все поля заполнены, выводим сообщение об ошибке
            alert(t('please_fill_in_all_fields'));
        }
    }

    const handleRemoveMemory = (memory) => {
        const confirmDelete = window.confirm(t('are_you_sure_you_want_to_delete_this_memory'));

        if (confirmDelete) {
            // Вызываем функцию для удаления элемента
            dispatch(removeFreeText(uid, memory));
            getUserData();
        }
    };

    const handleEditMemory = (index) => {
        setEditIndex(index);
        const memory = freeTexts[index];
        setEditedAuthorOfText(memory.freeTextData);
    }

    const handleSaveEdit = (index, author) => {
        setLoading(true);
        dispatch(editFreeText(index, author, uid, editedAuthorOfText));
        setEditedAuthorOfText('');
        setEditIndex(null);
        getUserData();
        setLoading(false);

    };

    const removePosition = () => {
        setAgreeLocation(false)
        setCurrentPosition(null)
    }

    return (
        <div id="mapId">
            <div className="settings-persone-container" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
                <div className="tabs-container">
                    <div>
                        <div className="tabs">
                            <button
                                className={`tab ${activeTab === 1 ? 'active' : ''}`}
                                onClick={() => handleTabClick(1)}
                            >
                                <div className='tab-names'>

                                    <MdAddAPhoto style={{ color: 'black' }} />
                                    <p> style={{ color: 'black' }}{t('photo')}</p>
                                </div>
                            </button>
                            <button
                                className={`tab ${activeTab === 2 ? 'active' : ''}`}
                                onClick={() => handleTabClick(2)}
                            >
                                <div className='tab-names'>

                                    <IoPerson style={{ color: 'black' }} />

                                    <p style={{ color: 'black' }}>{t('biography')}</p>
                                </div>
                            </button>

                            <button
                                className={`tab ${activeTab === 3 ? 'active' : ''}`}
                                onClick={() => handleTabClick(3)}
                            >
                                <div className='tab-names'>
                                    <RiInformationFill style={{ color: 'black' }} />
                                    <p style={{ color: 'black' }} >{t('information')}</p>
                                </div>

                            </button>

                            <button
                                className={`tab ${activeTab === 4 ? 'active' : ''}`}
                                onClick={() => handleTabClick(4)}
                            >
                                <div className='tab-names'>

                                    <TiLocation style={{ color: 'black' }} />

                                    <p style={{ color: 'black' }}>{t('geolocation')}</p>
                                </div>

                            </button>
                        </div>
                        <div className="tabs">
                            <button
                                className={`tab ${activeTab === 5 ? 'active' : ''}`}
                                onClick={() => handleTabClick(5)}
                            >
                                <div className='tab-names'>

                                    <MdAccountCircle style={{ color: 'black' }} />
                                    <p style={{ color: 'black' }}>{t('account_settings')}</p>
                                </div>

                            </button>
                            <button
                                className={`tab ${activeTab === 6 ? 'active' : ''}`}
                                onClick={() => handleTabClick(6)}
                            >
                                <div className='tab-names'>

                                    <ImFileText style={{ color: 'black' }} />

                                    <p style={{ color: 'black' }}>{t('memories')}</p>
                                </div>

                            </button>
                        </div>
                    </div>

                    <div className="tab-content">
                        {activeTab === 1 && (
                            <div className='settings-persone-img settings-persone'>
                                {photoUrls?.length > 0 ? (
                                    photoUrls?.map((url, index) => (
                                        <>
                                            <h2>{t('change_photo')}</h2>
                                            <div key={index} style={{ marginTop: 40 }}>
                                                <img src={url} alt="Img" loading="eager" />
                                            </div>
                                            <div className="settings-persone-btnn-remove-and-change">

                                                <RegularButton
                                                    buttonText="change_photo"
                                                    onClick={handleAddPhotoClick}
                                                    style={{ width: 220 }}

                                                />

                                                <RegularButton
                                                    onClick={() => handleDeletePhoto(url)}
                                                    buttonText="remove_photo"
                                                    style={{ backgroundColor: "#ff0000", width: 220 }}
                                                />
                                            </div>
                                        </>
                                    ))
                                ) : (
                                    <div className="settings-persone-img settings-persone" onClick={handleAddPhotoClick}>
                                        <h2>{t('add_photo')}</h2>

                                        <img src={noImage} alt="img" />
                                        {photoUrls?.length >= 0 && (
                                            <div className="regular-button-container">
                                                <RegularButton
                                                    buttonText="add_photo"
                                                    onClick={handleAddPhotoClick}
                                                    style={isMobile ? { width: 150 } : null}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                                <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
                            </div>
                        )}
                        {activeTab === 2 && (
                            <div className='settings-persone'>
                                <h2>{t('change_a_persons_biography')}</h2>
                                <RegularInput
                                    type="textarea"
                                    value={storyPersoneUpadte}
                                    onChange={(e) => setStoryPersoneUpadte(e.target.value)}
                                    required
                                    label="tell_us_about_the_person"
                                    styleTextArea={{ minHeight: 500, padding: 20, }}
                                />

                                <div>
                                    {modalIsOpen && (
                                        <Modal
                                            isOpen={modalIsOpen}
                                            onRequestClose={() => {
                                                setModalIsOpen(false);
                                                // Сбрасываем индекс при закрытии модального окна
                                            }}
                                            contentLabel="Free Text Modal"
                                            className="custom-modal"
                                        >
                                            <h2>{t('biography')}</h2>
                                            <FreeTextSection
                                                storedUserId={storedUserId}
                                                setShowFreeTextForm={setModalIsOpen}
                                                userLanguage={userLanguage}
                                                moreBiography={true}
                                            />
                                        </Modal>
                                    )}

                                </div>
                            </div>
                        )}
                        {activeTab === 3 && (
                            <div className='settings-persone'>
                                <h2>{t('change_information_about_a_person')}</h2>
                                <RegularInput
                                    type="text"
                                    value={firstNamePersoneUpadte}
                                    onChange={(e) => setFirstNamePersoneUpadte(e.target.value)}
                                    required
                                    label="name"
                                />

                                <RegularInput
                                    type="text"
                                    value={lastNamePersoneUpadte}
                                    onChange={(e) => setLastNamePersoneUpadte(e.target.value)}
                                    required
                                    label="surname"
                                />

                                {userLanguage?.includes("ru") ?
                                    <RegularInput
                                        type="text"
                                        value={fathersNamePersons !== null ? fathersNamePersonsUpdate : ''}
                                        onChange={(e) => setFathersNamePersonsUpdate(e.target.value)}
                                        required
                                        label="fathersName"
                                        placeholder="fathersName"
                                    />
                                    :
                                    null}

                                <RegularInput
                                    type="date"
                                    value={birthDatePersoneUpadte ? birthDatePersoneUpadte : formattedCurrentDate}
                                    onChange={(e) => setBirthDatePersoneUpadte(e.target.value)}
                                    required
                                    label="date_of_Birth"

                                />

                                <RegularInput
                                    type="date"
                                    value={deathDatePersoneUpadte ? deathDatePersoneUpadte : formattedCurrentDate}
                                    onChange={(e) => setDeathDatePersoneUpadte(e.target.value)}
                                    required
                                    label="date_of_departure"

                                />
                                <div className='settings-persone'>
                                    <h2>{t('change_additional_data')}</h2>
                                    <RegularInput
                                        type="text"
                                        value={placeOfBirthUpadte}
                                        onChange={(e) => setPlaceOfBirthUpadte(e.target.value)}
                                        label="place_of_Birth"
                                    />

                                    <RegularInput
                                        type="text"
                                        value={memorialPlaceUpadte}
                                        onChange={(e) => setMemorialPlaceUpadte(e.target.value)}
                                        label="memorial_site"
                                    />

                                    <RegularInput
                                        type="text"
                                        value={spouseUpadte}
                                        onChange={(e) => setSpouseUpadte(e.target.value)}
                                        label="spouse"
                                    />
                                    <RegularInput
                                        type="text"
                                        value={childrenUpadte}
                                        onChange={(e) => setChildrenUpadte(e.target.value)}
                                        label="children"
                                    />
                                    <RegularInput
                                        type="text"
                                        value={educationUpadte}
                                        onChange={(e) => setEducationUpadte(e.target.value)}
                                        label="education"
                                    />
                                    <RegularInput
                                        type="text"
                                        value={hobbiesUpadte}
                                        onChange={(e) => setHobbiesUpadte(e.target.value)}
                                        label="hobby"
                                    />

                                </div>

                            </div>
                        )}
                        {activeTab === 4 && (
                            <>
                                {agreeLocation ? (
                                    <MapContainer
                                        center={currentPosition && [currentPosition.latitude, currentPosition.longitude]}

                                        zoom={5}
                                        className='settings-persone-map'
                                    >
                                        <TileLayer
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        <Marker position={currentPosition && currentPosition.latitude && currentPosition.longitude ? [currentPosition.latitude, currentPosition.longitude] : [53.893009, 27.567444]} icon={markerIcon}>
                                            <Popup>
                                                A pretty CSS3 popup. <br /> Easily customizable.
                                            </Popup>
                                        </Marker>
                                    </MapContainer>
                                ) : (
                                    <MapContainer
                                        center={[53.893009, 27.567444]} // Начальные координаты (например, Израиль)
                                        zoom={5}
                                        className='settings-persone-map'
                                    >
                                        <TileLayer
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        {currentPosition && (
                                            <Marker position={[currentPosition?.latitude, currentPosition?.longitude]}>
                                                <Popup>
                                                    A pretty CSS3 popup. <br /> Easily customizable.
                                                </Popup>
                                            </Marker>
                                        )}
                                    </MapContainer>
                                )
                                }
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                    <RiDeleteBin6Line size={30} onClick={() => removePosition()} />
                                </div>
                            </>
                        )}
                        {activeTab === 5 && (
                            <div className='settings-persone'>
                                <div className='settings-persone-title'>
                                    <h2>{t('change_email_and_password')}</h2>
                                </div>

                                <RegularInput
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    placeholder="current_email"
                                />

                                <label style={{ position: 'relative' }}>
                                    <RegularInput
                                        securePassword={true}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="current_password"
                                        eyeStyle={userLanguage?.includes("he") ? true : false}

                                    />
                                </label>


                                <RegularInput
                                    type="email"
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    required
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    placeholder="new_email"
                                />
                                <label style={{ position: 'relative' }}>
                                    <RegularInput
                                        securePassword={true}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder="new_password"
                                        eyeStyle={userLanguage?.includes("he") ? true : false}

                                    />
                                </label>
                                <label style={{ position: 'relative' }}>
                                    <RegularInput
                                        securePassword={true}
                                        value={confirmNewPassword}
                                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                                        placeholder="confirm_your_new_password"
                                        eyeStyle={userLanguage?.includes("he") ? true : false}

                                    />
                                </label>
                            </div>
                        )}
                        {activeTab === 6 && (
                            <div className='settings-persone'>
                                <div >
                                    <h2>{t('change_memory')}</h2>
                                </div>

                                {freeTexts?.map((memory, index) => (
                                    <div key={index} className='settings-persone-memory-container'>
                                        <h3>{memory.authorOfText}</h3>

                                        {editIndex === index ? (
                                            <div>
                                                <RegularInput
                                                    type="textarea"
                                                    styleTextArea={{ maxWidth: 500, minHeight: 300, padding: 20, }}
                                                    value={editedAuthorOfText}
                                                    onChange={(e) => setEditedAuthorOfText(e.target.value)}
                                                />
                                                <RegularButton
                                                    buttonText="save"
                                                    onClick={() => handleSaveEdit(index, memory.authorOfText)} />
                                                <RegularButton
                                                    style={{ marginLeft: 10 }}
                                                    buttonText="cancel"
                                                    onClick={() => setEditIndex(null)} />
                                            </div>
                                        ) : (
                                            <>
                                                <p>{memory.freeTextData}</p>
                                                <RiDeleteBin6Line size={30} onClick={() => handleRemoveMemory(memory)} />
                                                <MdOutlineEdit size={30} onClick={() => handleEditMemory(index)} />
                                            </>
                                        )}

                                    </div>
                                ))}

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <RegularButton
                                        buttonText="add_your_memory"
                                        onClick={() => setModalIsOpen(true)} />
                                </div>


                                <div>
                                    {modalIsOpen && (
                                        <Modal
                                            isOpen={modalIsOpen}
                                            onRequestClose={() => {
                                                setModalIsOpen(false);
                                                // Сбрасываем индекс при закрытии модального окна
                                            }}
                                            contentLabel="Free Text Modal"
                                            className="custom-modal"
                                        >
                                            <FreeTextSection
                                                storedUserId={storedUserId}
                                                setShowFreeTextForm={setModalIsOpen}
                                                userLanguage={userLanguage}
                                                moreBiography={false}
                                            />
                                        </Modal>
                                    )}

                                </div>
                            </div>
                        )}

                        <div className='settings-persone-btn'>
                            {activeTab === 1 ? (
                                <>
                                   
                                </>
                            ) : (
                                activeTab === 5 ? (
                                    <>
                                        {registrationError && (
                                            <p style={{ color: 'red', textAlign: 'center' }}>{registrationError}</p>
                                        )}
                                        <RegularButton
                                            buttonText="save"
                                            onClick={updateEmailAndPassword}
                                        />
                                    </>
                                ) : (
                                    activeTab === 6 ? (
                                        null // Здесь можно добавить другую логику, если нужно
                                    ) : (
                                        <RegularButton
                                            buttonText="save"
                                            onClick={handleSaveChanges}
                                        />
                                    )
                                )
                            )}
                        </div>

                    </div>

                </div>
                {alertVisible && (
                    <Alert
                        title={alertTitle}
                        text={null}
                        icon={"info"}
                        confirmButtonText={"Ok"}
                        onConfirm={() => setAlertVisible(false)}
                        showCancelButton={false}
                        setAlertVisible={setAlertVisible}
                        alertVisible={alertVisible}
                    />
                )}
            </div >

            <Footer />
        </div >
    );
};

export default SettingsPersoneHistory;
