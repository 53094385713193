import React, { useState } from "react";
import { auth, db } from "../../../Components/Firebase/Firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import RegularInput from "../../../Components/RegularInput/RegularInput";
import RegularButton from "../../../Components/RegularButton/RegularButton";
import Footer from "../../Footer/Footer";

const CreateDefaultUser = ({ userLanguage, setLoading }) => {

    const navigate = useNavigate();

    const firstNamePersone = null;
    const lastNamePersone = null;
    const fathersNamePersons = null;
    const birthDatePersone = null;
    const deathDatePersone = null;
    const storyPersone = null;
    const placeOfBirth = null;
    const memorialPlace = null;
    const spouse = null;
    const children = null;
    const education = null;
    const hobbies = null;
    const userLocation = null;
    const fullName = null

    const [email, setEmail] = useState("");
    // const [fullName, setFullName] = useState("");
    const [password, setPassword] = useState("");
    const [userPrice, setUserPrice] = useState("");

    const [city, setCity] = useState('Minsk');
    const [country, setCountry] = useState('Belarus');
    const [street, setStreet] = useState('Bone maceva');
    const [houseNumber, setHouseNumber] = useState('Bone maceva');
    const [flatNumber, setFlatNumber] = useState('Bone maceva');
    const [zipCode, setZipCode] = useState("00000000");
    const [discription, setDiscription] = useState('Bone maceva');
    const [phone, setPhone] = useState('+375 00 000 0000');
    const [source, setSource] = useState('Bone maceva');

    const isUserRegistered = false;
    const createUserWithQrCode = true;

    const heshbonNumber = Math.floor(Math.random() * 100000);

    const orderNumber = heshbonNumber;

    const registerWithEmailAndPassword = async (
    ) => {
        return new Promise(async (resolve, reject) => {
            setLoading(true);
            try {
                const res = await createUserWithEmailAndPassword(auth, email, password);
                if (res.user) {
                    const userDocRef = doc(db, "users", res.user.uid);

                    const unsubscribe = onSnapshot(userDocRef, () => {

                        alert("Пользователь добавлен успешно")
                        unsubscribe();
                    });

                    await setDoc(userDocRef, {

                        uid: res.user.uid,
                        email,
                        password,
                        fullName,
                        userPrice,

                        city,
                        country,
                        discription,
                        flatNumber,
                        houseNumber,
                        orderNumber,
                        phone,
                        source,
                        street,
                        zipCode,

                        firstNamePersone,
                        lastNamePersone,
                        fathersNamePersons,
                        birthDatePersone,
                        deathDatePersone,
                        storyPersone,
                        placeOfBirth,
                        memorialPlace,
                        spouse,
                        children,
                        education,
                        hobbies,
                        userLocation,

                        isUserRegistered,
                        createUserWithQrCode,

                        dateOfCreation: new Date().toISOString(),

                    });

                    navigate('/RegistrationDone', {
                        state: {
                            userId: res.user.uid,
                            email,
                            EnterWithQrCode: true
                        }
                    })

                    resolve(res.user.uid);
                } else {
                    alert("Ошибка при регистрации пользователя");
                    reject("Ошибка при регистрации пользователя");

                }
            } catch (err) {
                console.error(err.message);
                reject("Пользователь с таким email уже существует");
            }
            setLoading(false);

        });
    };

    return (
        <>
            <div className="payment-container" dir={userLanguage?.includes("he") ? "rtl" : "ltr"} style={{ color: "white" }}>
                <h2 style={{ color: "white", marginBottom: 10 }}>Добавить нового пользователя</h2>

                <RegularInput
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    placeholder="email"
                    label="email"
                />

                <label style={{ position: 'relative' }}>
                    <RegularInput
                        securePassword={true}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="password"
                        eyeStyle={userLanguage?.includes("he") ? true : false}
                        label="password"
                    />
                </label>

                <RegularInput
                    type="text"
                    value={userPrice}
                    onChange={(e) => setUserPrice(e.target.value)}
                    placeholder="price"
                    label="price"
                />

                <RegularInput
                    type="text"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder="country"
                    label="country"
                />

                <RegularInput
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                    placeholder="city"
                    label="city"
                />

                <RegularInput
                    type="text"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    required
                    placeholder="street"
                    label="street"
                />

                <RegularInput
                    type="text"
                    value={houseNumber}
                    onChange={(e) => setHouseNumber(e.target.value)}
                    required
                    placeholder="house_number"
                    label="house_number"
                />

                <RegularInput
                    type="text"
                    value={flatNumber}
                    onChange={(e) => setFlatNumber(e.target.value)}
                    required
                    placeholder="apartment_number"
                    label="apartment_number"
                />

                <RegularInput
                    type="text"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    placeholder="index"
                    label="index"
                />

                <RegularInput
                    type="text"
                    value={discription}
                    onChange={(e) => setDiscription(e.target.value)}
                    placeholder="comment_for_delivery"
                    label="comment_for_delivery"
                />

                <RegularInput
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="+375"
                    label="phone"
                />

                <RegularInput
                    type="text"
                    value={source}
                    onChange={(e) => setSource(e.target.value)}
                    placeholder="Salesperson Name"
                    userLanguage={userLanguage}
                    label="Salesperson Name"
                />

                <RegularButton
                    onClick={registerWithEmailAndPassword}
                    buttonText="Добавить"
                    style={{ borderColor: 'white', borderWidth: 1, borderStyle: "solid", marginTop: 20, borderRadius: 10, }}
                />

            </div>

            <Footer />

        </>
    )
}


export default CreateDefaultUser;