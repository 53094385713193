import React, { forwardRef } from 'react';
import './RegularButton.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RegularButton = forwardRef(({ onClick, style, buttonText, to, type, submitForm, styleText, props, disabled }, ref) => {
    const { t } = useTranslation();

    return (
        <Link
            to={to}
            state={{ props: props }}
        >
            <button
                className='regular-button'
                style={style}
                onClick={submitForm ? submitForm : onClick}
                type={type}
                disabled={disabled}
                ref={ref}
            >
                <p style={styleText}>{t(buttonText)}</p>
            </button>
        </Link >
    );
});

export default RegularButton;
