import React, { useState } from 'react';
import './ForgotPassword.css';
import RegularButton from '../../Components/RegularButton/RegularButton';
import RegularInput from '../../Components/RegularInput/RegularInput';
import { sendPasswordReset } from '../../Components/Firebase/Firebase';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';

const ForgotPassword = ({setLoading, userLanguage}) => {
  const [email, setEmail] = useState('');

  const { t } = useTranslation();

  const handleSubmit = () => {
    setLoading(true);
    sendPasswordReset(email);
    setEmail("");
    setLoading(false);
  };

  return (
    <>
      <div className="forgot-password-container">
        <div className="forgot-password-details">

          <h2>{t('forgot_your_password')}</h2>
          <p>{t('enter_your_email_address_to_reset_your_password')}</p>

          <RegularInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            label="email"
          />

          <RegularButton
            buttonText="send_password_reset_link"
            onClick={handleSubmit}
            styleText={{ fontSize: 20, color: "white", }}
          />
        </div>
      </div>
      <Footer userLanguage={userLanguage}/>
    </>
  );
};

export default ForgotPassword;
