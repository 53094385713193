import React from 'react';
import './Spinner.css';
import Lottie from 'lottie-react';
import animationData from './Spinner-transparent.json';

const Spinner = ({ loading }) => {
    return (
        <div>
            <div className="spinner-container">
                {loading && (
                    <div className="spinner-content">
                        <Lottie animationData={animationData} width={200} height={200} />
                        {/* <h2>Please wait...</h2> */}
                    </div>

                )}
            </div>
        </div>
    );
};

export default Spinner;
