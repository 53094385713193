import React, { useEffect, useState } from 'react';
import { app } from '../../Components/Firebase/Firebase';
import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage';
import './PersonHistory.css';
import PhotoGallery from '../PhotoGallery/PhotoGallery';
import VideoGallery from '../VideoGallery/VideoGallery';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../Redux/actions/actions';
import noImage from '../../Images/no_data/no-image.png';
import FreeTextSection from '../FreeTextSection/FreeTextSection';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import locationImage from '../../Images/locationImage.png';

import mingcute from '../../Images/mingcute.png';
import locationIcon from '../../Images/icons/location-icon.png';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RegularButton from '../../Components/RegularButton/RegularButton';

const PersonHistory = ({ storedUserId, userLanguage, setLoading, EnterWithQrCode }) => {

    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.userData);
    const [showFreeTextForm, setShowFreeTextForm] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
    const storage = getStorage(app);
    const [slidesToShow, setSlidesToShow] = useState(3); // Значение по умолчанию для веб-версии

    const [photoUrls, setPhotoUrls] = useState([]);

    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        dispatch(getUserData());
        setLoading(false);
    }, [dispatch, setLoading]);

    const {
        // email,
        birthDatePersone,
        deathDatePersone,
        firstNamePersone,
        lastNamePersone,
        fathersNamePersons,
        storyPersone,

        placeOfBirth,
        memorialPlace,
        spouse,
        children,
        education,
        hobbies,
        freeTexts,
        userLocation,
        uid

    } = currentUser;


    useEffect(() => {
        const handleResize = () => {
            if (freeTexts?.length === 1) {
                setSlidesToShow(1);
            }
            else if (freeTexts?.length === 2) {
                setSlidesToShow(2);
            }
            else if (window.innerWidth < 600) {
                setSlidesToShow(1); // Для мобильной версии
            }
            else if (window.innerWidth < 1000) {
                setSlidesToShow(2);
            }
            else {
                setSlidesToShow(3); // Для веб-версии
            }
        };

        handleResize(); // Вызываем функцию сразу, чтобы установить начальное значение

        window.addEventListener('resize', handleResize); // Слушаем изменения размера экрана

        return () => window.removeEventListener('resize', handleResize); // Убираем слушатель при размонтировании компонента
    }, [freeTexts?.length]);

    useEffect(() => {
        if (EnterWithQrCode
            & firstNamePersone === null
            || deathDatePersone === null
            || firstNamePersone === null
            || lastNamePersone === null
            || storyPersone === null
        ) {
            navigate('/UpdateEmail')
        }
    }, [EnterWithQrCode, deathDatePersone, firstNamePersone, lastNamePersone, navigate, storyPersone])

    useEffect(() => {
        if (!EnterWithQrCode) {
            if (
                birthDatePersone === null ||
                deathDatePersone === null ||
                firstNamePersone === null ||
                lastNamePersone === null ||
                storyPersone === null
            ) {

                navigate(`/${storedUserId}/AboutPersonComponent`, { state: { storedUserId: storedUserId }, replace: true });

            }
        }
    }, [EnterWithQrCode, birthDatePersone, deathDatePersone, firstNamePersone, lastNamePersone, navigate, storedUserId, storyPersone]);


    //проверяем если данные undefined то мы включаем загрузку
    useEffect(() => {
        if (!EnterWithQrCode) {
            if (
                birthDatePersone === undefined ||
                deathDatePersone === undefined ||
                firstNamePersone === undefined ||
                lastNamePersone === undefined ||
                storyPersone === undefined
            ) {
                setLoading(true);
            }
            else {
                setLoading(false)
            }
        }
    }, [EnterWithQrCode, birthDatePersone, deathDatePersone, firstNamePersone, lastNamePersone, setLoading, storyPersone])

    // Проверяем storedUserId при каждом обновлении компонента
    useEffect(() => {
        if (storedUserId === null) {
            navigate(`/${userLanguage}`);
        }
    }, [storedUserId, userLanguage, navigate]);

    useEffect(() => {
        setLoading(true);
        dispatch(getUserData());
        const storageRef = ref(storage, `${uid}/profile`);
        listAll(storageRef)
            .then((result) => Promise.all(result.items.map((item) => getDownloadURL(item))))
            .then((urls) => {
                setPhotoUrls(urls);
            })
            .catch((error) => {
                console.error('Error loading images:', error);
                // Дополнительная обработка ошибки, если необходимо
            })
            .finally(() => setLoading(false));
        setLoading(false);

    }, [dispatch, uid, storage, setLoading]);

    function reverseDateFormat(originalDate) {
        if (!originalDate) {
            // Возвращаем пустую строку или другое значение, в зависимости от вашего случая
            return '';
        }

        // Разбиваем строку с датой на массив [год, месяц, день]
        const parts = originalDate.split('-');

        // Создаем новый объект даты, задавая его аргументами день, месяц и год
        const reversedDate = new Date(parts[0], parts[1] - 1, parts[2]);

        // Используем методы объекта даты для получения дня, месяца и года
        const day = reversedDate.getDate();
        const month = reversedDate.getMonth() + 1; // Месяцы в JavaScript считаются с 0, поэтому добавляем 1
        const year = reversedDate.getFullYear();

        // Форматируем компоненты даты, чтобы были двухзначными, если нужно
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        // Возвращаем перевернутую дату в формате "дд.мм.гггг"
        return `${formattedDay}.${formattedMonth}.${year}`;
    }

    const createDetailItem = (label, value) => {
        if (value !== "" | value !== null) {
            return (
                <div className='container-detail'>
                    <span className="detail-label"> {label}:</span>
                    <span style={{ marginLeft: 5 }} />
                    <span className="detail-value"> {value}</span>
                </div>
            );
        }
        return null;
    }

    const handlePhotoClick = (index) => {
        setSelectedPhotoIndex(index);

    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Проверяем, что фотография открыта и клик был вне ее области
            if (selectedPhotoIndex !== null && !event.target.closest('.selected-photo-container')) {
                setSelectedPhotoIndex(null);
            }
        };

        // Добавляем обработчик события для клика вне элемента фотографии
        document.addEventListener('mousedown', handleOutsideClick);

        // Удаляем обработчик при размонтировании компонента
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [selectedPhotoIndex]);


    const openGoogleMaps = () => {
        if (!userLocation) {
            console.error('User location is not available.');
            return;
        }

        const { latitude, longitude } = userLocation;
        const destination = `${latitude},${longitude}`;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude: destLat, longitude: destLng } = position.coords;
                    const origin = `${destLat},${destLng}`;
                    window.open(`https://www.google.com/maps/dir/${origin}/${destination}`);
                },
                error => {
                    console.error('Error getting current location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    // const stories = [
    //     {
    //         title: "Приключение",
    //         content: "Однажды, отправившись в кругосветное путешествие, Александр случайно обнаружил древний храм в отдаленном уголке света. Внутри он нашел мистический артефакт, который, как говорили легенды, придавал своему обладателю способность видеть радугу даже в самый пасмурный день."
    //     },
    //     {
    //         title: "Влияние на окружающих",
    //         content: "Этот артефакт стал символом для Александра, и он решил поделиться своей радугой с миром. Он начал организовывать благотворительные акции, собирать средства для поддержки тех, кто нуждается, и проводить мастер-классы по фотографии для детей."
    //     },
    //     {
    //         title: "Наследие",
    //         content: "Хотя Александр больше нет с нами, его наследие продолжает жить в сердцах тех, кто его знал. Люди помнят его как сияющую личность, способную приносить свет и цвет в самые темные моменты жизни. Его друзья продолжают воплощать его идеалы, делая мир лучше вместе."
    //     }
    // ];

    // Проверяем количество слов в тексте

    const [modalIsOpenSelectedText, setModalIsOpenSelectedText] = useState(false);

    const [selectedText, setSelectedText] = useState("");

    const handleThreeDotsClick = (text) => {
        setSelectedText(text);
        setModalIsOpenSelectedText(true);
        // setModalIsOpen(true)
    };



    return (
        <>
            <div className='pesone-history-container' dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>

                <div className='pesone-history-details'>
                    {photoUrls?.length > 0 ? (
                        <div className="container">
                            {photoUrls?.map((url, index) => (
                                <div className='pesone-history-container-photo ' key={index}>
                                    <img
                                        className="photo"
                                        src={url}
                                        alt={`Img-${index}`}
                                        loading="eager"
                                        onClick={() => handlePhotoClick(index)}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="container">
                            <div className='pesone-history-container-photo no-photo-border'>
                                <img
                                    className="no-photo"
                                    src={noImage}
                                    alt="img"ג
                                    loading="eager" />
                                <button
                                    onClick={() => navigate(`/${storedUserId}/SettingsPersoneHistory`)}
                                    className='regular-button button-overlay' >
                                    {t('add_photo')}
                                </button>
                            </div>

                        </div>
                    )}

                    <div style={{ display: 'inline-block', }}>
                        <div className="about-persone" >

                            {`${lastNamePersone?.toUpperCase()} ${firstNamePersone?.toUpperCase()} `}
                            <br />
                            {fathersNamePersons !== "" && userLanguage?.includes("ru") ? fathersNamePersons?.toUpperCase() : null}
                        </div>
                        <div className='about-persone-text'>
                            <div className="about-persone-phone" >

                                {`${lastNamePersone?.toUpperCase()} ${firstNamePersone?.toUpperCase()}`}
                                <br />
                                {fathersNamePersons !== "" && userLanguage?.includes("ru") ? fathersNamePersons?.toUpperCase() : null}
                            </div>
                            <p>{reverseDateFormat(birthDatePersone)} - {reverseDateFormat(deathDatePersone)}
                                <br />
                                <br />
                                <br />
                                {storyPersone}</p>
                        </div>
                    </div>
                </div>

                {/* <div class="pesone-history-stories">
                    {stories?.map((story, index) => {
                        return (

                            <div key={index} className='pesone-history-story'>

                                <h2 style={{ display: 'flex', marginTop: story.title.length <= 11 ? 35 : '' }} >
                                    <div className="stick" style={{ marginTop: story.title.length > 11 ? 30 : '' }} />
                                    {story.title.toUpperCase()}
                                    <div className="stick" style={{ marginTop: story.title.length > 11 ? 30 : '' }} />
                                </h2>
                                <p>
                                    {story.content}
                                </p>
                            </div>
                        )
                    })}
                </div> */}

                
                    <div className="detail-item">
                        <div className='detail-item-size'>
                            {placeOfBirth === null ? (
                                <p>{createDetailItem(t('place_of_Birth'), "-")}</p>
                            ) : (
                                <p>{createDetailItem(t('place_of_Birth'), placeOfBirth)}</p>
                            )}
                            {memorialPlace === null ?
                                <p>{createDetailItem(t('memorial_site'), "-")}</p>
                                : (
                                    <p>{createDetailItem(t('memorial_site'), memorialPlace)}</p>
                                )}
                        </div>
                        <div className='detail-item-size'>
                            {education === null ?
                                <p>{createDetailItem(t('education'), "-")}</p>

                                : (
                                    <p>{createDetailItem(t('education'), education)}</p>
                                )}
                            {spouse === null ?
                                <p>{createDetailItem(t('spouse'), "-")}</p>
                                : (
                                    <p>{createDetailItem(t('spouse'), spouse)}</p>
                                )}
                        </div>
                        <div className='detail-item-size'>
                            {children === null ?
                                <p>{createDetailItem(t('children'), "-")}</p>
                                : (
                                    <p>{createDetailItem(t('children'), children)}</p>
                                )}
                            {hobbies === null ?
                                <p>{createDetailItem(t('hobby'), "-")}</p>
                                : (
                                    <p>{createDetailItem(t('hobby'), hobbies)}</p>
                                )}
                        </div>
                    </div>

                <div >
                    {freeTexts?.length > 0 ?
                        <>
                            <div >
                                {freeTexts?.length > 0 && (
                                    <div className='pesone-history-memories' >
                                        <h2>{t('memories')?.toUpperCase()}</h2>
                                        <div className='slide-content' >
                                            {freeTexts?.length === 1 ? (
                                                <div className='pesone-history-memories-carousel' >
                                                    <div className='pesone-history-memories-border'>
                                                        <img src={mingcute} alt="mingcute" loading="eager" />
                                                        <p style={{ textAlign: 'center' }}>
                                                            {freeTexts[0].freeTextData}
                                                        </p>
                                                        <h3 style={{ textAlign: 'center' }}>{freeTexts[0].authorOfText}</h3>
                                                    </div>
                                                </div>
                                            ) : (
                                                <Slider slidesToShow={slidesToShow} dots>
                                                    {freeTexts?.map((text, index) => (
                                                        <div key={index} className='pesone-history-memories-carousel'>
                                                            <div className='pesone-history-memories-border'>
                                                                <img src={mingcute} alt="mingcute" loading="eager" />
                                                                <p style={{ textAlign: 'center' }}>
                                                                    {/* Проверяем количество слов и отображаем три точки при необходимости */}
                                                                    {text.freeTextData.split(" ").length > 25 ? (
                                                                        <>
                                                                            {text.freeTextData.split(" ").slice(0, 25).join(" ")}
                                                                            <span style={{ textDecoration: "underline", cursor: "pointer" }}
                                                                                onClick={() => handleThreeDotsClick(text.freeTextData)}
                                                                            >...</span>
                                                                        </>
                                                                    ) : (
                                                                        text.freeTextData
                                                                    )}
                                                                </p>
                                                                <h3 style={{ textAlign: 'center' }}>{text.authorOfText}</h3>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Slider>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div style={{ marginTop: 60, textAlign: 'center' }}>
                                {window.location.pathname.includes('PersoneHistory') && (

                                    <div className='pesone-history-btns'>
                                        {!showFreeTextForm && (
                                            <RegularButton
                                                buttonText="add_your_memory"
                                                onClick={() => {
                                                    setShowFreeTextForm(true);
                                                    setModalIsOpen(true);
                                                }}
                                            />

                                        )}
                                    </div>
                                )}
                            </div>
                        </>
                        :
                        <>
                            {window.location.pathname.includes('PersoneHistory') && (
                                <div className={userLanguage?.includes("he") ? 'pesone-history-memories_hebrew' : 'pesone-history-memories'}>
                                    <h2>{t('memories')}</h2>
                                    <div className='pesone-history-btns'>
                                        {!showFreeTextForm && (
                                            <RegularButton
                                                buttonText="add_your_memory"
                                                style={{ width: 300 }}
                                                onClick={() => {
                                                    setShowFreeTextForm(true);
                                                    setModalIsOpen(true);
                                                }}
                                            />

                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    }
                </div>

                {userLocation ?
                    <div className='pesone-history-memories'>
                        <h2>{t('location')}</h2>
                        <p className='pesone-history-location-p'>{t('provide_the_exact_location_of_the_burial_site_so_that_loved_ones_can_easily_find_the_memorial_of_their_beloved')}</p>

                        <div className='pesone-history-location'  >
                            <img src={locationImage} alt="img" loading="eager" className='persone-history-img-map' />
                            <div className='pesone-history-location-btn'>

                                <button
                                    className='regular-button local-btn'
                                    onClick={openGoogleMaps}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                        <img src={locationIcon} alt="locationIcon"
                                            style={{ width: 25, height: 25, }}
                                        />
                                        {t('location')}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        {window.location.pathname.includes('PersoneHistory') && (

                            <div className='pesone-history-memories'>
                                <h2>{t('location')}</h2>
                                <p className='pesone-history-location-p'>{t('provide_the_exact_location_of_the_burial_site_so_that_loved_ones_can_easily_find_the_memorial_of_their_beloved')}</p>

                                <div className='pesone-history-location'  >
                                    <img src={locationImage} alt="img" loading="eager" className='persone-history-img-map' />
                                    <div className='pesone-history-location-btn'>

                                        <button
                                            className='regular-button local-btn'
                                            onClick={() => navigate(`/${storedUserId}/SettingsPersoneHistory`, { state: { numberTab: 4 } })}
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                <img src={locationIcon} alt="locationIcon"
                                                    style={{ width: 25, height: 25, }}
                                                />

                                                {t('location')}
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                }

                {/* <div>
                    {selectedPhotoIndex !== null && (
                        <div className="selected-photo-container">
                            <img
                                src={photoUrls[selectedPhotoIndex]}
                                alt={`Selected-Img-${selectedPhotoIndex}`}
                                className="pesone-history-photo"
                                style={{ borderRadius: 8 }}
                            />
                            <button className="close-button" onClick={() => setSelectedPhotoIndex(null)}>
                                <RiCloseLine />
                            </button>
                        </div>
                    )}
                </div> */}

                <div style={{ marginTop: 20, textAlign: 'center' }}>
                    <PhotoGallery setLoading={setLoading} storedUserId={storedUserId} />
                </div>

                <div style={{ marginTop: 20, marginBottom: 40 }}>
                    <VideoGallery setLoading={setLoading} storedUserId={storedUserId} />
                </div>

                {/* modal window for slider */}

                {modalIsOpenSelectedText && (
                    <Modal
                        isOpen={modalIsOpenSelectedText}
                        onRequestClose={() => setModalIsOpenSelectedText(false)}
                        className="custom-modal"
                    >
                        <div className="modal-slider">
                            <p>{selectedText}</p>
                            <button
                                className='regular-button'
                                style={{ fontSize: 15 }}
                                onClick={() => setModalIsOpenSelectedText(false)}>
                                {t('close')}
                            </button>

                        </div>
                    </Modal>
                )}

                {/* modal window for photos */}
                {storedUserId && (
                    <div>
                        {showFreeTextForm && (
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={() => {
                                    setShowFreeTextForm(false);
                                    setModalIsOpen(false);
                                    setSelectedPhotoIndex(null); // Сбрасываем индекс при закрытии модального окна
                                }}
                                contentLabel="Free Text Modal"
                                className="custom-modal"
                            >
                                <h2>{t('add_your_memory')}</h2>
                                <FreeTextSection
                                    storedUserId={storedUserId}
                                    setShowFreeTextForm={setShowFreeTextForm}
                                    userLanguage={userLanguage}
                                    moreBiography={false}
                                />
                            </Modal>
                        )}

                    </div>
                )}
            </div >

            <Footer userLanguage={userLanguage}/>

        </>
    );
};

export default PersonHistory;