import React, { useEffect, useState } from 'react';
import './RegularInput.css';
import { useTranslation } from 'react-i18next';
// import { countries } from 'countries-list'; // Получить полный список стран с готовой библиотеки
import Select from 'react-select';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import flags from 'country-flag-icons/react/3x2';
import { BsInfoCircle } from "react-icons/bs";

const RegularInput = ({ label, value, onChange, required, type, title, securePassword, style, styleTextArea, eyeStyle, placeholder, options, userLanguage, readOnly, matchMedia, info }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showPassword, setShowPassword] = useState(securePassword);
    const { t } = useTranslation();
    const [onChangeCheck, setOnChangeCheck] = useState(value);
    const [showInfo, setShowInfo] = useState(false); 

    useEffect(() => {
        const sanitizedValue = value?.replace(/<\/?[^>]+(>|$)/g, "");
        setOnChangeCheck(sanitizedValue);

        const handleResize = () => {
            setWindowWidth(window.innerWidth);

        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [onChange, onChangeCheck, value, windowWidth]);

    const renderInput = () => {
        if (type === 'textarea') {
            return (
                <textarea
                    value={onChangeCheck}
                    onChange={onChange}
                    required={required}
                    title={title}
                    className="regular-input-textarea"
                    style={styleTextArea}
                    placeholder={t(placeholder)}
                />
            );
        }

        //Styles for Select input (cities)
        const customStyles = {
            control: (provided) => ({
                ...provided,
                height: 60, // Задаем высоту
                width: matchMedia,
                textAlign: userLanguage?.includes("he") ? 'right' : 'left',
                paddingLeft: 15,
            }),
        };

        if (type === 'select') {
            return (
                <Select
                    value={options?.find(option => option.value === value)}
                    onChange={(selectedOption) => onChange(selectedOption ? selectedOption.value : '')}
                    options={options} // Передаем опции из свойства options
                    placeholder={t(placeholder)}
                    styles={customStyles}
                />
            );
        }

        //Исключаем все арабские страны 

        // Список кодов арабских стран
        // const arabicCountries = ["QA", "AE", "KW", "OM", "BH", "YE", "JO", "LB", "SY", "IQ", "EG", "LY", "SD", "TN", "DZ", "MR", "SO", "MA", "IR", "AF", "PK", "TM"];

        // Получаем все коды стран из flags и исключаем арабские страны
        // const excludedArabicCountries = Object.keys(flags).filter(countryCode => !arabicCountries.includes(countryCode));

        if (type === 'phone') {
            return (
                <div style={{ backgroundColor: 'white' }}>
                    <PhoneInput
                        placeholder="Enter phone number"
                        value={onChangeCheck}
                        defaultCountry="BY"
                        flags={flags}
                        onChange={onChange}
                        // countries={excludedArabicCountries}
                        // countryCallingCodeEditable
                        // autoFocus
                        maxLength={17}
                        style={{
                            height: "40px",
                            width: window.matchMedia('(max-width: 768px)').matches ? "250px" : "325px",
                            padding: 8,
                            borderRadius: 8,
                        }}

                    />
                </div>
            )
        }

        return (
            <input
                type={showPassword ? 'password' : type}
                value={onChangeCheck}
                onChange={onChange}
                required={required}
                title={title}
                className="regular-input"
                style={style}
                placeholder={t(placeholder)}
                readOnly={readOnly}
                
                onMouseLeave={() => setShowInfo(false)}
            />

        );
    };

    return (
        <div className='redular-input-container'>
            <label className="regular-label">
                {t(label)}
            </label>
            <div>
                {renderInput()}
                {info && (
                    <div className="info-icon" >
                        {showInfo && <div className="info-tooltip"> "example@qrmoment.by"</div>}
                        <BsInfoCircle onMouseEnter={() => setShowInfo(true)}  style={{ cursor: 'pointer' }} /> {/* Используем иконку информации */}
                    </div>
                )}
                {securePassword && (
                    <div
                        className={eyeStyle ? "toggle-password_hebrew" : "toggle-password"}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? '👁️' : '👁️‍🗨️'}
                    </div>
                )}
            </div>
        </div>

    );
};

export default RegularInput;
