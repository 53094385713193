import React, { useEffect, useRef, useState } from 'react';
import './Payment&Shiping.css';
import RegularInput from '../../Components/RegularInput/RegularInput';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import registration from '../../Images/registration.jpg';
import Footer from '../Footer/Footer';

import { useSelector } from 'react-redux';
import RegularButton from '../../Components/RegularButton/RegularButton';
import DataCities from '../../Components/DataCities/DataCities';
import BoneMacevot from '../../Components/DataBoneMacevot/DataBoneMacevot';
import Alert from '../../Components/Alert/Alert';

const PaymentShiping = ({ userLanguage, setLoading }) => {

  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const publicAgreementTrue = location.state?.publicAgreement; 

  // console.log(publicAgreementTrue,'publicAgreement');

  const userData = useSelector(state => state.saveDataNewUser.data || {});

  const [email, setEmail] = useState(userData?.email);
  const [fullName, setFullName] = useState(userData?.fullName);
  const [password, setPassword] = useState(userData?.password);
  const [userPrice, setUserPrice] = useState(userData?.userPrice);

  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [street, setStreet] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [flatNumber, setFlatNumber] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [discription, setDiscription] = useState('');
  const [phone, setPhone] = useState('+375');
  const [source, setSource] = useState('');
  const [isCheckedAgreement, setIsCheckedAgreement] = useState(publicAgreementTrue ? publicAgreementTrue :false);

  const heshbonNumber = Math.floor(Math.random() * 100000);

  const Merchant_ID = 478756;
  const orderNumber = heshbonNumber;
  const orderAmount = userPrice;
  const orderCurrency = "BYN";
  const delay = 0;
  const language = "RU";
  const emailQrmoment = "info@qrmoment.com";
  const orderComment = `Оплата заказа ${heshbonNumber}`;
  //prod
  const urlReturnOk = "https://qrmoment.by/PaymentShiping?status=ok"; // Добавляем параметр status=ok
  const urlReturnNo = "https://qrmoment.by/PaymentShiping?status=no"; // Добавляем параметр status=no

  //test
  //  const urlReturnOk = "http://localhost:3000/PaymentShiping?status=ok"; // Добавляем параметр status=ok
  // const urlReturnNo = "http://localhost:3000/PaymentShiping?status=no"; // Добавляем параметр status=no

  const [formError, setFormError] = useState('');
  const handleCheckboxChange = () => {
    setIsCheckedAgreement(!isCheckedAgreement);
  };

  useEffect(() => {
    setFullName(fullName);
    setCountry(userLanguage?.includes("en") ? 'Belarus' : "Беларусь");

    setTimeout(() => {
      const image = new Image();
      image.src = registration;
      image.onload = () => {
        // Обработка после успешной загрузки изображения
      }
    }, 2000);
  }, [fullName, setLoading, userLanguage]);

  useEffect(() => {
    const storedUserData = localStorage?.getItem('userData');

    if (storedUserData) {
      const parsedShippingData = JSON.parse(storedUserData);
      setFullName(parsedShippingData.fullname);
      setCity(parsedShippingData.city);
      setStreet(parsedShippingData.street);
      setHouseNumber(parsedShippingData.houseNumber);
      setFlatNumber(parsedShippingData.flatNumber);
      setZipCode(parsedShippingData.zipCode);
      setDiscription(parsedShippingData.discription);
      setPhone(parsedShippingData.phone);
      setSource(parsedShippingData.source);
      setEmail(parsedShippingData.email);
      setFullName(parsedShippingData.fullName);
      setPassword(parsedShippingData.password);
      setUserPrice(parsedShippingData.userPrice);
    }
  }, []);


  // добовляем места по дефолту
  const defaultOptionListSource = [

    {
      value: 'facebook',
      label: 'Facebook',
    },
    {
      value: 'instagram',
      label: 'Instagram',
    }
  ];

  const sourceOptions = BoneMacevot.filter(item => city === item.city)

  const allOptions = [...defaultOptionListSource, ...sourceOptions];

  const handleCityChange = (selectedCity) => {
    setCity(selectedCity);
    setSource("");
  };

  const changeLanguageCity = DataCities[0].map(city => ({
    value: city.value,
    label: userLanguage === 'ru' ? city.label : city.value
  }))

  const [paymentError, setPaymentError] = useState(false);

  useEffect(() => {
    const status = new URLSearchParams(location.search).get('status');

    if (status === 'ok') {
      navigate('/AssistPayment');
    }
    else if (status === 'no') {
      setPaymentError(true);
    }
  }, [location.search, navigate]);

  const shippingData = {
    fullName,
    city,
    country,
    street,
    houseNumber,
    flatNumber,
    zipCode,
    discription,
    phone,
    source,
    userPrice,
    email,
    password,
    orderNumber,
    formRef
  };

  const addShipingToLocalStorage = () => {
    localStorage.setItem('userData', JSON.stringify(shippingData));
  };

  const [isPaymentFormVisible, setIsPaymentFormVisible] = useState(false);

  const handleSaveDataAndShowPaymentForm = () => {

    if (!fullName) {
      setFormError(t('alert_please_enter_your_name'));
    } else if (!city) {
      setFormError(t('alert_please_enter_your_city'));
    } else if (!street) {
      setFormError(t('alert_please_enter_your_street'));
    } else if (!houseNumber) {
      setFormError(t('alert_please_enter_your_number_house'));
    } else if (!zipCode) {
      setFormError(t('alert_please_enter_your_zip_code'));
    } else if (!phone) {
      setFormError(t('alert_please_enter_your_phone'));
    } else if (!source) {
      setFormError(t('alert_please_enter_your_source'));
    } else if (!isCheckedAgreement) {
      setFormError(t('alert_please_enter_agree_to_terms'));
    }
    else {
      addShipingToLocalStorage();

      setTimeout(() => {
        setIsPaymentFormVisible(true);
        if (formRef.current) {
          formRef.current.submit();
        }
      }, 500);
    }
  };

  useEffect(() => {
    if (isPaymentFormVisible && formRef.current) {

      formRef.current.submit();
    }
  }, [isPaymentFormVisible]);


  return (
    <>
      <div className="payment-container" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>

        <img src={registration} alt="img signUp" loading="eager" />

        <div className="address-input-container">

          <h2> {t('enter_your_delivery_address')}</h2>
          <div >
            <RegularInput
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
              label="recipients_first_and_last_name"
              placeholder="recipients_first_and_last_name"
            />

            <RegularInput
              type="text"
              value={country}
              // onChange={(e) => setCountry(e.target.value)}
              label="country"
              placeholder="country"
              readOnly={true}
            />

            <RegularInput
              type="select"
              value={city}
              onChange={handleCityChange}
              required
              label="city"
              placeholder="city"
              matchMedia={window.matchMedia('(max-width: 768px)').matches ? "260px" : "345px"}
              // options={DataCities[0]}
              options={changeLanguageCity}
              userLanguage={userLanguage}
            />
          </div>

          <div >
            <RegularInput
              type="text"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              required
              label="street"
              placeholder="street"
            />

            <RegularInput
              type="text"
              value={houseNumber}
              onChange={(e) => setHouseNumber(e.target.value)}
              required
              label="house_number"
              placeholder="house_number"
            />

            <RegularInput
              type="number"
              value={flatNumber}
              onChange={(e) => setFlatNumber(e.target.value)}
              required
              label="apartment_number"
              placeholder="apartment_number"

            />

            <RegularInput
              type="number"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              label="index"
              placeholder="index"
            />

            <RegularInput
              type="text"
              value={discription}
              onChange={(e) => setDiscription(e.target.value)}
              label="comment_for_delivery"
              placeholder="comment_for_delivery"
            />
            <RegularInput
              type="phone"
              value={phone}
              onChange={(value) => setPhone(value)}
              label="phone"
              placeholder="+375"

            />

            <RegularInput
              type="select"
              matchMedia={window.matchMedia('(max-width: 768px)').matches ? "260px" : "345px"}
              value={source}
              onChange={(selectedSource) => setSource(selectedSource)}
              label={t('how_did_you_hear_about_us')}
              placeholder={t('how_did_you_hear_about_us')}
              options={allOptions}
              userLanguage={userLanguage}
            />

            <div>
              <h2>{t('price')}: {userPrice} BYN</h2>
            </div >

            <div className='payment-agreement' onClick={handleCheckboxChange}>
              <input
                type="checkbox"
                checked={isCheckedAgreement}
                onChange={handleCheckboxChange}
              />
              <Link to="PublicAgreement"
                onClick={() => addShipingToLocalStorage()}
              // style={{ color: 'white', fontSize: 22, marginLeft: 10 }}
              >
                {t('i_agree_with_the_public_offer')}
              </Link>
            </div>

            <div className='payment-formError'>
              <h4 >{formError}</h4>

            </div>

            {isPaymentFormVisible & isCheckedAgreement ? (
              <div style={{ display: 'none' }}>
                <form ref={formRef} action="https://test.paysec.by/pay/order.cfm" method="POST">
                  <input type="hidden" name="Merchant_ID" value={Merchant_ID} />
                  <input type="hidden" name="OrderNumber" value={orderNumber} />
                  <input type="hidden" name="OrderAmount" value={orderAmount} />
                  <input type="hidden" name="OrderCurrency" value={orderCurrency} />
                  <input type="hidden" name="Delay" value={delay} />
                  <input type="hidden" name="Language" value={language} />
                  <input type="hidden" name="Email" value={emailQrmoment} />
                  <input type="hidden" name="OrderComment" value={orderComment} />
                  <input type="hidden" name="URL_RETURN_OK" value={urlReturnOk} />
                  <input type="hidden" name="URL_RETURN_NO" value={urlReturnNo} />
                  <input type="hidden" name="CardPayment" value="1" />
                  <input className="regular-button" type="submit" name="Submit" value={t('pay')} />
                </form>
              </div>
            ) :
              (
                <RegularButton
                  buttonText="pay"
                  onClick={handleSaveDataAndShowPaymentForm}
                />
              )
            }
          </div>
        </div>

        {/* Показываем Alert при ошибке оплаты */}
        {paymentError && (
          <Alert
            title={null}
            text={t('payment_failed_message')}
            icon="error"
            confirmButtonText={t('Ok')}
            onConfirm={() => {
              setPaymentError(false);
            }
            }
          />
        )}
      </div >
      <Footer />
    </>
  );
};

export default PaymentShiping;
