import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import { getStorage, ref, uploadString } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import RegularButton from '../RegularButton/RegularButton';
import './GenerateQRCode.css';

const GenerateQRCode = (props) => {
    const navigate = useNavigate();
    const [filename, setFilename] = useState("userId");
    const color = "#000"
    const companyName = 'QR MOMENT';

    const { email, userId, setLoading, EnterWithQrCode } = props;

    const shortenedUserId = userId?.substring(0, 10);

    // const email = "testQrcode@code.net";
    // const userId = 'XA6PEXvB7CM2d8qfjDQaxMZBoZV2';
    // const firstNamePersone = 'לולו';
    // const lastNamePersone = 'דניאל';

    useEffect(() => {
        setFilename(`${email}.png`);
        setLoading(false);
    }, [email, setLoading, userId]);

    const generateQRCode = async () => {
        setLoading(true);
        if (filename) {
            try {

                //test
                // const updatedUrl = `http://localhost:3000/${shortenedUserId}/EnterWithQrCode`;

                //prod
                const updatedUrl = `http://qrmoment.by/${shortenedUserId}/EnterWithQrCode`;

                // Создаем QR-код
                const qrCanvas = document.createElement('canvas');
                const qrCtx = qrCanvas.getContext('2d');
                await QRCode.toCanvas(qrCanvas, updatedUrl, {
                    width: 2000,
                    margin: 1,
                    color: {
                        dark: color,
                        // light: "#0000"
                    },
                });

                // Создаем canvas для текста
                const textCanvas = document.createElement('canvas');
                const textCtx = textCanvas.getContext('2d');
                textCanvas.width = qrCanvas.width;
                textCanvas.height = qrCanvas.height;

                // Рисуем белый прямоугольник в центре
                const centerX = qrCanvas.width / 2;
                const centerY = qrCanvas.height / 2;
                const textWidth = textCtx.measureText(companyName).width;
                const textHeight = 230; // Высота текста

                const rectangleWidth = textWidth + 1200; // Увеличиваем ширину прямоугольника на 20 пикселей

                textCtx.fillStyle = 'white';
                textCtx.fillRect(centerX - rectangleWidth / 2, centerY - textHeight / 2, rectangleWidth, textHeight);

                // Задаем параметры для названия компании
                textCtx.fillStyle = "#000";
                textCtx.font = 'bold 180px Arial';
                textCtx.textAlign = 'center';
                textCtx.textBaseline = 'middle'; // Выравниваем текст по центру

                textCtx.fillText(companyName, centerX, centerY);

                // Объединяем QR-код и текст
                qrCtx.drawImage(textCanvas, 0, 0);

                // Загрузка изображения в Firebase Storage
                const storage = getStorage();
                const storageRef = ref(storage, `${userId}/QrCode/${userId}`);
                await uploadString(storageRef, qrCanvas.toDataURL('image/png'), 'data_url');

                if (EnterWithQrCode) {
                    alert("Пользователь добавлен успешно")
                    navigate(`/_:_CreateDefaultUser`);
                }
                else {
                    navigate(`/${shortenedUserId}/PersoneHistory`);

                }
                // console.log(`QR Code for ${updatedUrl} created and saved as ${userId}`);
            } catch (err) {
                console.error('Error creating QR Code:', err);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="generate-qr-container">
            <RegularButton
                onClick={generateQRCode}
                buttonText="next"
                style={{ width: 300 }}

            />
        </div>
    );
};

export default GenerateQRCode;
