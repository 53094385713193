const DataCities = [
  [
    
    {
      value: 'Minsk',
      label: 'Минск'
    },
    {
      value: 'Antopol',
      label: 'Антополь'
    },
    {
      value: 'Baranovichi',
      label: 'Барановичи'
    },
    {
      value: 'Begoml',
      label: 'Бегомль'
    },
    {
      value: 'Belozyorsk',
      label: 'Белоозёрск'
    },
    {
      value: 'Belynichi',
      label: 'Белыничи'
    },
    {
      value: 'Berezino',
      label: 'Березино'
    },
    {
      value: 'Beryoza',
      label: 'Берёза'
    },
    {
      value: 'Beshenkovichi',
      label: 'Бешенковичи'
    },
    {
      value: 'Bobr',
      label: 'Бобр'
    },
    {
      value: 'Bobruisk',
      label: 'Бобруйск'
    },
    {
      value: 'Bolshaya Berestovitsa',
      label: 'Большая Берестовица'
    },
    {
      value: 'Borisov',
      label: 'Борисов'
    },
    {
      value: 'Bragin',
      label: 'Брагин'
    },
    {
      value: 'Braslav',
      label: 'Браслав'
    },
    {
      value: 'Brest',
      label: 'Брест'
    },
    {
      value: 'Buda-Koshelevo',
      label: 'Буда-Кошелёво'
    },
    {
      value: 'Bykhov',
      label: 'Быхов'
    },
    {
      value: 'Vasilevichi',
      label: 'Василевичи'
    },
    {
      value: 'Verkhnedvinsk',
      label: 'Верхнедвинск'
    },
    {
      value: 'Vetka',
      label: 'Ветка'
    },
    {
      value: 'Vidzy',
      label: 'Видзы'
    },
    {
      value: 'Vileyka',
      label: 'Вилейка'
    },
    {
      value: 'Vitebsk',
      label: 'Витебск'
    },
    {
      value: 'Volkovysk',
      label: 'Волковыск'
    },
    {
      value: 'Vologin',
      label: 'Воложин'
    },
    {
      value: 'Voronovo',
      label: 'Вороново'
    },
    {
      value: 'Vysokoye',
      label: 'Высокое'
    },
    {
      value: 'Gantsevichi',
      label: 'Ганцевичи'
    },
    {
      value: 'Glubokoye',
      label: 'Глубокое'
    },
    {
      value: 'Glusk',
      label: 'Глуск'
    },
    {
      value: 'Gomel',
      label: 'Гомель'
    },
    {
      value: 'Goretsk',
      label: 'Горецк'
    },
    {
      value: 'Gorodeya',
      label: 'Городея'
    },
    {
      value: 'Gorodishche',
      label: 'Городище'
    },
    {
      value: 'Gorodok',
      label: 'Городок'
    },
    {
      value: 'Grodno',
      label: 'Гродно'
    },
    {
      value: 'David-Gorodok',
      label: 'Давид-Городок'
    },
    {
      value: 'Dzerzhinsk',
      label: 'Дзержинск'
    },
    {
      value: 'Disna',
      label: 'Дисна'
    },
    {
      value: 'Dobrush',
      label: 'Добруш'
    },
    {
      value: 'Dokshitsy',
      label: 'Докшицы'
    },
    {
      value: 'Dribin',
      label: 'Дрибин'
    },
    {
      value: 'Drogichin',
      label: 'Дрогичин'
    },
    {
      value: 'Druya',
      label: 'Друя'
    },
    {
      value: 'Dubrovno',
      label: 'Дубровно'
    },
    {
      value: 'Dyatlovo',
      label: 'Дятлово'
    },
    {
      value: 'Ezerische',
      label: 'Езерище'
    },
    {
      value: 'Yelsk',
      label: 'Ельск'
    },
    {
      value: 'Zhabinka',
      label: 'Жабинка'
    },
    {
      value: 'Zhitkovichi',
      label: 'Житковичи'
    },
    {
      value: 'Zhlobin',
      label: 'Жлобин'
    },
    {
      value: 'Zhodino',
      label: 'Жодино'
    },
    {
      value: 'Zaslavl',
      label: 'Заславль'
    },
    {
      value: 'Zelva',
      label: 'Зельва'
    },
    {
      value: 'Ivanovo',
      label: 'Иваново'
    },
    {
      value: 'Ivacevichi',
      label: 'Ивацевичи'
    },
    {
      value: 'Ivenets',
      label: 'Ивенец'
    },
    {
      value: 'Ivye',
      label: 'Ивье'
    },
    {
      value: 'Kalinkovichi',
      label: 'Калинковичи'
    },
    {
      value: 'Kamenets',
      label: 'Каменец'
    },
    {
      value: 'Kirovsk',
      label: 'Кировск'
    },
    {
      value: 'Kletsk',
      label: 'Клецк'
    },
    {
      value: 'Klimovichi',
      label: 'Климовичи'
    },
    {
      value: 'Klichev',
      label: 'Кличев'
    },
    {
      value: 'Kobrin',
      label: 'Кобрин'
    },
    {
      value: 'Kozlovshchina',
      label: 'Козловщина'
    },
    {
      value: 'Kopys',
      label: 'Копыль'
    },
    {
      value: 'Korelichi',
      label: 'Кореличи'
    },
    {
      value: 'Korma',
      label: 'Корма'
    },
    {
      value: 'Kossovo',
      label: 'Коссово'
    },
    {
      value: 'Kostyukovichi',
      label: 'Костюковичи'
    },
    {
      value: 'Krasnopolie',
      label: 'Краснополье'
    },
    {
      value: 'Krivichi',
      label: 'Кривичи'
    },
    {
      value: 'Krichev',
      label: 'Кричев'
    },
    {
      value: 'Krugloe',
      label: 'Круглое'
    },
    {
      value: 'Krupki',
      label: 'Крупки'
    },
    {
      value: 'Lelchitsy',
      label: 'Лельчицы'
    },
    {
      value: 'Lepel',
      label: 'Лепель'
    },
    {
      value: 'Lesnoy',
      label: 'Лесной'
    },
    {
      value: 'Lida',
      label: 'Лида'
    },
    {
      value: 'Liozno',
      label: 'Лиозно'
    },
    {
      value: 'Logishin',
      label: 'Логишин'
    },
    {
      value: 'Logoisk',
      label: 'Логойск'
    },
    {
      value: 'Loev',
      label: 'Лоев'
    },
    {
      value: 'Luninets',
      label: 'Лунинец'
    },
    {
      value: 'Lyuban',
      label: 'Любань'
    },
    {
      value: 'Lyubcha',
      label: 'Любча'
    },
    {
      value: 'Lyakhovichi',
      label: 'Ляховичи'
    },
    {
      value: 'Malorita',
      label: 'Малорита'
    },
    {
      value: 'Maryina Gorka',
      label: 'Марьина Горка'
    },
    {
      value: 'Miory',
      label: 'Миоры'
    },
    {
      value: 'Mir',
      label: 'Мир'
    },
    {
      value: 'Mogilev',
      label: 'Могилёв'
    },
    {
      value: 'Mozyr',
      label: 'Мозырь'
    },
    {
      value: 'Molodechno',
      label: 'Молодечно'
    },
    {
      value: 'Mosty',
      label: 'Мосты'
    },
    {
      value: 'Mstislavl',
      label: 'Мстиславль'
    },
    {
      value: 'Myadel',
      label: 'Мядель'
    },
    {
      value: 'Narovlya',
      label: 'Наровля'
    },
    {
      value: 'Negoreloye',
      label: 'Негорелое'
    },
    {
      value: 'Nesvizh',
      label: 'Несвиж'
    },
    {
      value: 'Novogrudok',
      label: 'Новогрудок'
    },
    {
      value: 'Novolukoml',
      label: 'Новолукомль'
    },
    {
      value: 'Novopolotsk',
      label: 'Новополоцк'
    },
    {
      value: 'Ozarichi',
      label: 'Озаричи'
    },
    {
      value: 'Oktyabrsky',
      label: 'Октябрьский'
    },
    {
      value: 'Orsha',
      label: 'Орша'
    },
    {
      value: 'Osipovichi',
      label: 'Осиповичи'
    },
    {
      value: 'Ostrovets',
      label: 'Островец'
    },
    {
      value: 'Oshmiany',
      label: 'Ошмяны'
    },
    {
      value: 'Petrikov',
      label: 'Петриков'
    },
    {
      value: 'Pinsk',
      label: 'Пинск'
    },
    {
      value: 'Polotsk',
      label: 'Полоцк'
    },
    {
      value: 'Postavy',
      label: 'Поставы'
    },
    {
      value: 'Pruzhany',
      label: 'Пружаны'
    },
    {
      value: 'Pleshenice',
      label: 'Плещеницы'
    },
   
    {
      value: 'Radoshkovichi',
      label: 'Радошковичи'
    },
    {
      value: 'Rechitsa',
      label: 'Речица'
    },
    {
      value: 'Rogachev',
      label: 'Рогачёв'
    },
    {
      value: 'Rossoshy',
      label: 'Россоны'
    },
    {
      value: 'Rudensk',
      label: 'Руденск'
    },
    {
      value: 'Ruzhany',
      label: 'Ружаны'
    },
    {
      value: 'Svetlogorsk',
      label: 'Светлогорск'
    },
    {
      value: 'Svir',
      label: 'Свирь'
    },
    {
      value: 'Svisloch',
      label: 'Свислочь'
    },
    {
      value: 'Senno',
      label: 'Сенно'
    },
    {
      value: 'Skidel',
      label: 'Скидель'
    },
    {
      value: 'Slavgorod',
      label: 'Славгород'
    },
    {
      value: 'Slonim',
      label: 'Слоним'
    },
    {
      value: 'Slutsk',
      label: 'Слуцк'
    },
    {
      value: 'Smolevichi',
      label: 'Смолевичи'
    },
    {
      value: 'Smorgon',
      label: 'Сморгонь'
    },
    {
      value: 'Soligorsk',
      label: 'Солигорск'
    },
    {
      value: 'Sopotskin',
      label: 'Сопоцкин'
    },
    {
      value: 'Starobin',
      label: 'Старобин'
    },
    {
      value: 'Starye Dorogi',
      label: 'Старые Дороги'
    },
    {
      value: 'Stolbtsy',
      label: 'Столбцы'
    },
    {
      value: 'Stolin',
      label: 'Столин'
    },
    {
      value: 'Streshin',
      label: 'Стрешин'
    },
    {
      value: 'Telekhany',
      label: 'Телеханы'
    },
    {
      value: 'Tolochin',
      label: 'Толочин'
    },
    {
      value: 'Turov',
      label: 'Туров'
    },
    {
      value: 'Uzda',
      label: 'Узда'
    },
    {
      value: 'Urechye',
      label: 'Уречье'
    },
    {
      value: 'Ushachi',
      label: 'Ушачи'
    },
    {
      value: 'Fanipol',
      label: 'Фаниполь'
    },
    {
      value: 'Khoyniki',
      label: 'Хойники'
    },
    {
      value: 'Kholopenichi',
      label: 'Холопеничи'
    },
    {
      value: 'Khotimsk',
      label: 'Хотимск'
    },
    {
      value: 'Chausy',
      label: 'Чаусы'
    },
    {
      value: 'Chashniki',
      label: 'Чашники'
    },
    {
      value: 'Cherven',
      label: 'Червень'
    },
    {
      value: 'Cherikov',
      label: 'Чериков'
    },
    {
      value: 'Chachersk',
      label: 'Чечерск'
    },
    {
      value: 'Sharkovshchina',
      label: 'Шарковщина'
    },
    {
      value: 'Sherechevo',
      label: 'Шерешёво'
    },
    {
      value: 'Shklov',
      label: 'Шклов'
    },
    {
      value: 'Shumilino',
      label: 'Шумилино'
    },
    {
      value: 'Shchuchin',
      label: 'Щучин'
    }
  ]

]

export default DataCities;