import React from 'react';
import './PrivacyPolicy.css';
import Footer from '../../Screens/Footer/Footer';

const PrivacyPolicy = () => {
    return (
        <div style={{ backgroundColor: '#003B46' }}>
            <div className="privacy-policy-container">
                <h1 className="policy-heading">Политика конфиденциальности</h1>
                <div className="policy-content">
                    <p>
                        <strong>1. Общие положения</strong>
                    </p>
                    <p>
                        <strong>1.1.</strong> Настоящая Политика обработки персональных данных (далее – Политика) от имени ИП Мележик А. (далее – Оператор) представляет собой официальный документ, в котором определены общие принципы, цели и порядок обработки персональных данных пользователей интернет-сайта QRmoment.by (далее – Сайт), а также предоставляются сведения о реализуемых мерах защиты персональных данных.
                    </p>
                    <p>
                        <strong>1.2.</strong> Политика разработана в соответствии с действующим законодательством Республики Беларусь, включая Указ Президента Республики Беларусь от 28.10.2021 г. № 422 «О мерах по совершенствованию защиты персональных данных», Закон Республики Беларусь от 07.05.2021 г. № 99-З «О защите персональных данных», Закон от 10.11.2008 г. № 455-З «Об информации, информатизации и защите информации», а также иными нормативными правовыми актами, регулирующими отношения, связанные с деятельностью Оператора.
                    </p>
                    <p>
                        <strong>1.3.</strong> Политика публикуется в свободном доступе в информационно-телекоммуникационной сети Интернет на сайте.
                    </p>
                    <p>
                        <strong>1.4.</strong> Действие Политики распространяется на все процессы по обработке персональных данных пользователей сайта QRmoment.by, осуществляемые с использованием средств автоматизации и (или) без использования таких средств.
                    </p>
                    <p>
                        <strong>1.5.</strong> Настоящая Политика вступает в силу с момента ее утверждения и действует до замены ее новой Политикой.
                    </p>
                </div>
                <div className="policy-content">
                    <p>
                        <strong>2. Основные термины и определения</strong>
                    </p>
                    <p>
                        <strong>2.1.</strong>
                        В настоящей Политике используются следующие термины:</p>
                    <p>
                        <strong>2.1.1.</strong>
                        Обработка персональных данных - любое действие или совокупность действий, совершаемые с персональными данными, включая сбор, систематизацию, хранение, изменение, использование, обезличивание, блокирование, распространение, предоставление, удаление персональных данных.
                    </p>
                    <p>
                        <strong>2.1.2.</strong> Оператор – ИП Мележик А. индивидуальный предприниматель Республики Беларусь,  самостоятельно или совместно с иными лицами организующее и (или) осуществляющее обработку персональных данных Пользователей.
                    </p>
                    <p>
                        <strong>2.1.3.</strong>
                        Персональные данные – любая информация, относящаяся к идентифицированному физическому лицу или физическому лицу, которое может быть идентифицировано (прямо или косвенно, в частности через фамилию, собственное имя, отчество, дату рождения, идентификационный номер либо через один или несколько признаков, характерных для его физической, психологической, умственной, экономической, культурной или социальной идентичности).
                    </p>
                    <p>
                        <strong>2.1.4.</strong> Пользователь сайта https://qrmoment.by (Пользователь) – субъект персональных данных, пришедший на сайт https://qrmoment.by.
                    </p>
                    <p>
                        <strong>2.1.5.</strong>  Субъект персональных данных – физическое лицо, в отношении которого осуществляется обработка персональных данных
                    </p>
                    <p>
                        <strong>2.1.6.</strong> Сайт – совокупность связанных между собой веб-страниц, размещённых в сети Интернет по уникальному адресу (URL), а также его субдоменах. .
                    </p>
                    <p>
                        <strong>2.1.7</strong> Уполномоченное лицо – государственный орган, юридическое лицо Республики Беларусь, иная организация, физическое лицо, которые в соответствии с актом законодательства, решением государственного органа, являющегося оператором, либо на основании договора с оператором осуществляют обработку персональных данных Пользователей от имени оператора или в его интересах.
                    </p>
                    <p>
                        <strong>2.1.8.</strong> Cookies – это небольшой текстовый файл, хранящийся на устройстве Пользователя, в котором сохраняются данные о посещенном Пользователем ресурсе. Cookie помогают Оператору запоминать информацию о совершенных Пользователем на ресурсе действиях.
                    </p>
                    <p>
                        <strong>2.1.9.</strong> IP-адрес – уникальный сетевой адрес узла в компьютерной сети, через который Пользователь получает доступ на сайт.
                    </p>
                </div>
                <div className="policy-content">
                    <p>
                        <strong>3. Объем и цели обработки персональных данных</strong>
                    </p>
                    <p>
                        <strong>3.1.</strong>
                        Основанием обработки персональных данных пользователей Сайта является согласие на обработку персональных данных. Давая согласие на обработку персональных данных Оператору, пользователь Сайта соглашается на совершение Оператором следующих действий с данными: сбор, систематизацию, хранение, изменение, использование, обезличивание, блокирование, предоставление, удаление, как с использованием средств автоматизации, так и без них. Согласие дается на срок до момента его отзыва.</p>
                    <p>
                        <strong>3.2.</strong> Оператор может обрабатывать следующие персональные данные для установленных целей:
                    </p>
                    <p>
                        <strong>3.2.1.</strong> Заключение, исполнение, изменение и расторжение договора с Пользователями сайта.
                        <br />
                        Обрабатываемые персональные данные:
                        <br />
                        — фамилия, имя, отчество;
                        <br />
                        — номер телефона;
                        <br />
                        — адрес электронной почты;
                        <br />
                        — адрес доставки;
                        <br />
                        Основание: договор (аб. 15 ст. 6 Закона Республики Беларусь от 07.05.2021 N 99-З «О защите персональных данных»), п. 66 Постановления Министерства юстиции Республики Беларусь от 24.05.2012 N 140 (ред. от 30.09.2021) «О перечне типовых документов Национального архивного фонда Республики Беларусь»
                        <br />
                        Срок хранения персональных данных: 3 года после окончания срока действия договора, при проведении налоговыми органами проверки соблюдения налогового законодательства. Если налоговыми органами проверка соблюдения налогового законодательства не проводилась — 10 лет после окончания срока действия договора
                    </p>

                    <p>
                        <strong>3.2.2.</strong> Для создания и использования личного кабинета на Сайте.
                        <br />
                        Обрабатываемые персональные данные:
                        <br />
                        — адрес электронной почты.
                        <br />
                        Основание: согласие
                    </p>
                    <p>
                        <strong>3.2.3.</strong> Для получения рекламной, новостной, технической рассылки, анализа данных.
                        <br />
                        Обрабатываемые персональные данные:
                        <br />
                        — номер телефона;
                        <br />
                        — адрес электронной почты.
                        <br />
                        Основание: согласие
                    </p>
                    <p>
                        <strong>3.2.4.</strong>
                        Для установления с пользователем Сайта, обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг.
                        <br />
                        Обрабатываемые персональные данные:
                        <br />
                        — номер телефона;
                        <br />
                        — адрес электронной почты.
                        <br />
                        Основание: согласие
                    </p>
                    <p>
                        <strong>3.2.5</strong>
                        Для рассмотрения, опубликования и анализа отзывов, вопросов на Cайте, анализа данных.
                        <br />
                        Обрабатываемые персональные данные:
                        <br />
                        — имя;
                        <br />
                        — адрес электронной почты .
                        <br />
                        Основание: согласие
                    </p>

                    <p>
                        <strong>3.2.6. .</strong>
                        Для ведения статистики и анализа работы Сайта Оператор обрабатывает с использованием метрических сервисов Google Analytics и Яндекс.Метрика такие данные, как:
                        <br />
                        — IP-адрес;
                        <br />
                        — информация о браузере;
                        <br />
                        — данные из файлов cookie;
                        <br />
                        — время доступа;
                        <br />
                        — реферер (адрес предыдущей страницы).
                        <br />
                        — логин и пароль Субъекта.
                    </p>
                    <p>
                        <strong>3.3.</strong>
                        Google Analytics, который является инструментом веб-анализа Google Inc., зарегистрирован по адресу: Амфитеатр Паркуэй, Маунтин-Вью, Калифорния 94043, США (далее – Google) для постоянной оптимизации Сайта. Google Analytics работает с файлами cookie и создает профили использования с псевдонимом, которые позволяют анализировать использование Пользователями Сайта. Информация, хранящаяся в таких файлах cookie (например, тип / версия браузера, используемая операционная система, URL-адрес реферера, имя хоста компьютера, получающего доступ, время запроса к серверу), обычно передается и хранится на серверах Google. Для блокировки Google Analytics можно скачать и установить надстройку по ссылке http://tools.google.com/dlpage/gaoptout?hl=ru. Дополнительную информацию можно получить в политике конфиденциальности Google: https://policies.google.com/privacy?hl=ru
                    </p>
                    <p>
                        <strong>3.4.</strong>
                        Сервис Яндекс.Метрики, доступный по адресу http://api.yandex.com/metrika, который позволяет различным сервисам и приложениям Пользователя взаимодействовать с сервисом Яндекс.Метрики ООО «Яндекс», зарегистрирован по адресу 119021, Москва, ул. Льва Толстого, д. 16 (далее – Яндекс). Яндекс. Метрика работает с файлами cookie и создает псевдонимные профили использования, которые позволяют анализировать использование Пользователями Сайта. Информация, хранящаяся в таких файлах cookie (например, тип / версия браузера, используемая операционная система, URL-адрес реферера, имя хоста компьютера, получающего доступ, время запроса к серверу), обычно передается и сохраняется на серверах Яндекс. Для блокировки Яндекс. Метрики можно скачать и установить надстройку по ссылке https://yandex.com/support/metrica/general/user-opt-out.html Дополнительную информацию можно получить в политике конфиденциальности Яндекс: https://yandex.by/legal/confidential/
                        <br />
                        При блокировке Google Analytics и Яндекс.Метрики некоторые функции Сайта могут стать недоступны.
                    </p>
                    <p>
                        <strong>3.5.</strong> Оператор также использует на своих ресурсах Cookie (куки) и другие инструменты для удобства пользования ресурсами, анализа использования товаров и услуг Компании и повышения качества рекомендаций.
                        <br />
                        Cookie позволяют запоминать, в частности:
                        <br />
                        -IP-адрес и местоположение;
                        <br />
                        -дату и время посещения ресурса;
                        <br />
                        -версию операционной системы и браузера, при помощи которых был выполнен вход на ресурс;
                        <br />
                        -клики и переходы по ресурсу.
                        <br />
                        Оператор использует Cookie:
                        <br />
                        - необходимые для работы основных функций ресурса;
                        <br />
                        - для сбора статистики, которая необходима для анализа поведения Пользователя на ресурсе для улучшения сервиса (Google Analytics, Webtrekk, Yandex Metrika и др.);
                        <br />
                        - для показа рекламы, которая направляется в зависимости от интересов Пользователя.
                        <br />
                        Пользователь вправе отключить Cookie в настройках браузера, однако в этом случае Оператор не гарантирует Пользователю надлежащую работу ресурсов.
                    </p>
                    <p>
                        <strong>3.6.</strong>
                        Обработка биометрических персональных данных и категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни, на Сайте не осуществляется.
                    </p>
                    <p>
                        <strong>3.7.</strong>
                        Оператор не проверяет достоверность информации, предоставляемой Пользователем, и исходит из того, что Пользователь предоставляет достоверную и достаточную информацию, контролирует ее актуальность.
                    </p>
                    <p>
                        <strong>3.8.</strong>
                        Обработка персональных данных субъекта прекращается с момента:
                        <br />
                        — достижения целей обработки данных;
                        <br />
                        — истечения срока обработки данных;
                        <br />
                        — отзыва субъектом данных согласия на обработку его (ее) данных;
                        <br />
                        — выявления неправомерной обработки данных.
                    </p>
                </div>
                <div className="policy-content">
                    <p>
                        <strong>4. Порядок и условия обработки персональных данных</strong>
                    </p>
                    <p>
                        <strong>4.1.</strong>
                        Оператор осуществляет следующие действия с персональными данными: сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), блокирование, удаление, уничтожение.
                    </p>
                    <p>
                        <strong>4.2.</strong>
                        Оператор принимает необходимые организационные и технические меры для защиты персональной информации Пользователей Сайта от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
                    </p>
                    <p>
                        <strong>4.3</strong>
                        Оператор предоставляет доступ к персональным данным Пользователей только тем категориям работников, в должностные или функциональные обязанности которых входит обработка персональных данных Пользователей и это обусловлено достижением целей, определенных настоящей Политикой.
                    </p>
                    <p>
                        <strong>4.4.</strong>
                        Каждый работник, которому для выполнения порученной ему работы в соответствии с его должностными обязанностями или обязанностями, предусмотренными гражданско-правовым договором, предоставлен доступ к персональным данным Пользователей, обязан:
                        <br />
                        - ознакомиться с положениями настоящей Политики и соблюдать их;
                        <br />
                        - осуществлять обработку персональных данных Пользователей исключительно в соответствии с теми целями, для достижения которых такие персональные данные были получены;
                        <br />
                        - не передавать (в любом виде и любым способом) и не разглашать третьим лицам и работникам, не имеющим соответствующего права на доступ к такой информации, информацию, содержащую персональные данные Пользователей (соблюдать конфиденциальность персональных данных Пользователей);
                        <br />
                        - в случае попытки незаконного вмешательства в процессы по обработке персональных данных Пользователей немедленно информировать об этом лицо, ответственное за осуществление внутреннего контроля за обработкой персональных данных;
                        <br />
                        - при получении официального запроса со стороны государственных органов, организаций или иных лиц, уполномоченных в предусмотренных законодательством случаях запрашивать информацию, содержащую в себе персональные данные Пользователей, ограничиваться предоставлением только того объема персональных данных, которые необходимы для выполнения возложенных на указанных лиц функций.
                    </p>
                </div>
                <div className="policy-content">
                    <p>
                        <strong>5. Основные права субъекта персональных данных</strong>
                    </p>
                    <p>
                        <strong>5.1.</strong>
                        Пользователь сайта имеет право:
                    </p>
                    <p>
                        <strong>5.1.1.</strong>
                        На отзыв своего согласия. В случае отзыва Пользователем сайта своего согласия на обработку персональных данных Оператор прекращает обработку указанных персональных данных и при необходимости уничтожает (обезличивает) их.
                    </p>
                    <p>
                        <strong>5.1.2.</strong>
                        Потребовать от Оператора внести изменения, если персональные данные устарели, неполные или неточные;
                    </p>
                    <p>
                        <strong>5.1.3.</strong>
                        На получение информации, касающейся обработки персональных данных.
                    </p>
                    <p>
                        <strong>5.1.4</strong>
                        Потребовать прекратить обработку своих данных, их удаление;
                    </p>
                    <p>
                        <strong>5.1.5.</strong>
                        Обжаловать действия/бездействий и решения Оператора, относящегося к обработке своих персональных данных, в уполномоченный орган по защите прав субъектов персональных данных в порядке, установленном законодательством.
                    </p>
                    <p>
                        <strong>5.2.</strong>
                        Заявление должно содержать:
                        <br />
                        — фамилию, имя, отчество (если такое имеется) субъекта персональных данных;
                        <br />
                        — номер мобильного телефона и/или электронную почту, указанную при регистрации на Сайте;
                        <br />
                        — суть требований;
                        <br />
                        — идентификационный номер или номер документа, удостоверяющего личность (при отсутствии идентификационного номера) субъекта персональных данных, если такая информация указывалась субъектом персональных данных при даче согласия или обработка персональных данных осуществляется без согласия субъекта персональных данных;
                        <br />
                        —  электронную цифровую подпись субъекта персональных данных.
                    </p>
                    <p>
                        <strong>5.3.</strong>
                        Право на обжалование действий (бездействия) и решений Оператора, связанных с обработкой персональных данных, осуществляется порядке, установленном законодательством Республики Беларусь об обращениях граждан и юридических лиц.
                    </p>
                    <p>
                        <strong>5.4.</strong>
                        За содействием в реализации прав субъект персональных данных может также обратиться к лицу, ответственному за осуществление внутреннего контроля за обработкой персональных данных у оператора, направив сообщение на электронный адрес:         info@qrmoment.com
                    </p>
                    <p>
                        <strong>5.5.</strong>
                        В случае отказа субъекта персональных данных от дачи согласия на обработку персональных данных, отзыва субъекта персональных данных предоставленного им согласия на обработку персональных данных и (или) заявления требования о прекращении их обработки, Оператор не сможет обрабатывать персональные данные, если иное не предусмотрено законодательством. Прекращение обработки Оператором персональных данных субъекта персональных данных может сделать невозможным дальнейшее предоставление такому субъекту персональных данных товаров и услуг.
                    </p>
                </div>
                <div className="policy-content">
                    <p>
                        <strong>6. Обязанности оператора</strong>
                    </p>

                    <p>
                        <strong>6.1.</strong>
                        В соответствии с требованиями Закона «О защите персональных данных» Оператор обязан:
                        <br />
                        - разъяснять Пользователю его права, связанные с обработкой персональных данных;
                        <br />
                        - получать согласие Пользователя, за исключением случаев, предусмотренных законодательными актами Республики Беларусь;
                        <br />
                        - обеспечивать защиту персональных данных в процессе их обработки;
                        <br />
                        - предоставлять Пользователю информацию о его персональных данных, а также о предоставлении его персональных данных третьим лицам, за исключением случаев, предусмотренных законодательством;
                        <br />
                        - вносить изменения в персональные данные, которые являются неполными, устаревшими или неточными, за исключением случаев, когда иной порядок внесения изменений в персональные данные установлен законодательными актами либо если цели обработки персональных данных не предполагают последующих изменений таких данных;
                        <br />
                        - прекращать обработку персональных данных, а также осуществлять их удаление или блокирование (обеспечивать прекращение обработки персональных данных, а также их удаление или блокирование уполномоченным лицом) при отсутствии оснований для обработки персональных данных, предусмотренных законодательством;
                        <br />
                        - уведомлять уполномоченный орган по защите прав субъектов персональных данных о нарушениях систем защиты персональных данных незамедлительно, но не позднее трех рабочих дней после того, как оператору стало известно о таких нарушениях, за исключением случаев, предусмотренных уполномоченным органом по защите прав субъектов персональных данных;
                        <br />
                        - осуществлять изменение, блокирование или удаление недостоверных или полученных незаконным путем персональных данных Пользователей по требованию уполномоченного органа по защите прав субъектов персональных данных, если иной порядок внесения изменений в персональные данные, их блокирования или удаления не установлен законодательными актами;
                        <br />
                        - исполнять иные требования уполномоченного органа по защите прав субъектов персональных данных об устранении нарушений законодательства о персональных данных.
                    </p>

                </div>
                <div className="policy-content">
                    <p>
                        <strong>7. Заключительные положения</strong>
                    </p>
                    <p>
                        <strong>7.1.</strong>
                        Вопросы, касающиеся обработки персональных данных, не закрепленные в настоящей Политике, регулируются законодательством Республики Беларусь.
                    </p>
                    <p>
                        <strong>7.2.</strong>
                        В случае, если какое-либо положение Политики признается противоречащим законодательству, остальные положения, соответствующие законодательству, остаются в силе и являются действительными, а любое недействительное положение будет считаться удаленным/измененным в той мере, в какой это необходимо для обеспечения его соответствия законодательству.
                    </p>
                    <p>
                        <strong>7.3. </strong>
                        Оператор имеет право по своему усмотрению в одностороннем порядке изменить и (или) дополнить условия настоящей Политики без предварительного уведомления субъектов персональных данных посредством размещения на Сайте новой редакции Политики.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
