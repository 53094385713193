import React from 'react';
import './InstructionSteps.css';
import RegularButton from '../../Components/RegularButton/RegularButton';
import { useTranslation } from 'react-i18next';
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const InstructionSteps = ({ userLanguage }) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 150,
            behavior: 'smooth',
        });
    };

    const { t } = useTranslation();

    return (
        <div className="instruction-content" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
            <h2>{t('how_it_works')}</h2>
            <div className="instruction-container">
                <div className="instruction-step">
                    <h4>{t('step1')}</h4>
                    <p>{t('aboutTheFirstStep')}</p>
                </div>
                {userLanguage?.includes("he") ?
                    <FaArrowLeftLong color='white' size="20" className="instruction-step-arrow" />
                    :
                    <FaArrowRightLong color='white' size="20" className="instruction-step-arrow" />
                }
                <div className="instruction-step" >
                    <h4>{t('step2')}</h4>
                    <p>{t('aboutTheSecondStep')}</p>
                </div>
                {userLanguage?.includes("he") ?
                    <FaArrowLeftLong color='white' size="20" className="instruction-step-arrow" />
                    :
                    <FaArrowRightLong color='white' size="20" className="instruction-step-arrow" />
                }
                <div className="instruction-step">
                    <h4>{t('step3')}</h4>
                    <p>{t('aboutStepTree')}</p>
                </div>
            </div>

            <div className="instruction-button-container">
                <RegularButton
                    buttonText="write_to_us"
                    to="/ContactForm"
                    styleText={{ color: "#003B46", fontWeight: 550, fontFamily: 'Lora' }}
                    style={{ backgroundColor: "#dcdce2", height: 60, padding: 0, marginTop: 20 }}
                    onClick={scrollToTop}
                />
            </div>
        </div>
    );
};

export default InstructionSteps;