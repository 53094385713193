import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import './CookieConsent.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CookieConsent = ({ handleCookieConsent }) => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const cookieConsentFromLocalStorage = localStorage.getItem("cookieConsent");
    if (cookieConsentFromLocalStorage === "true") {
      setShowCookieConsent(false);
    }
  }, []);

  const giveCookieConsent = () => {
    setCookie("cookieConsent", cookies, { path: "/" });
    localStorage.setItem("cookieConsent", "true");
    handleCookieConsent();
    setShowCookieConsent(false);
  };

  return (
    <>
      {showCookieConsent && (
        <div className="cookie-consent-container">
          <div className="cookie-consent">
            <p>
              {t('we_use_cookies')}{" "}
              <br />
              <Link to={"/PrivatePolicy"}>{t('learn_more')}</Link>
            </p>
            <button onClick={giveCookieConsent}>
              {t('accept')}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
