import React from 'react';
import './ErrorPage.css'
import Footer from '../Footer/Footer';
import RegularButton from '../../Components/RegularButton/RegularButton';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className='error-page-container'>
        <div className='error-page-details'>
          <h2>{t('something_went_wrong')}</h2>
          <p>{t('we_apologize_for_the_inconvenience_Please_try_again_later')}</p>
          <RegularButton
            to="/"
            buttonText="home_page"
            style={{ width: 250 }}
            styleText={{ fontSize: 20, color: "white", }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ErrorPage;
