import React from 'react';
import './PrivacyPolicy.css';
import Footer from '../../Screens/Footer/Footer';
import { useNavigate } from 'react-router-dom';

const PublicAgreement = () => {
    const navigate = useNavigate(); // Инициализация функции navigate для управления навигацией

    const handleGoBack = () => {
        navigate("/PaymentShiping", { state: { publicAgreement: true } }); // Передача пропсов при переходе на предыдущую страницу
        
    };

    return (
        <div style={{ backgroundColor: '#003B46' }}>

            <div className="privacy-policy-container">
                <h1 className="policy-heading">ПУБЛИЧНЫЙ ДОГОВОР</h1>
                <h2 className="policy-heading">на оказание услуг</h2>
                <div className="policy-content">
                    <p>
                        <strong>Индивидуальный предприниматель Мележик Андрей, </strong>
                        именуемый в дальнейшем «Исполнитель», действующий на основании свидетельства о государственной регистрации 08.02.2024 № 691648286, публикует настоящий договор (далее – Договор), являющийся публичным договором-офертой (предложением) в адрес физических лиц, принимающих предложение, и выражающий намерение Исполнителя, сделавшего предложение, считать себя заключившим Договор с лицом (далее – «Заказчик»), которое принимает условия настоящего Договора с момента совершения Заказчиком конклюдентных и (или) иных действий, необходимых для пользования услугой Исполнителя (акцепт оферты).
                    </p>

                    <p>
                        К отношениям между Исполнителем и Заказчиком применяются требования Гражданского кодекса Республики Беларусь, Закона Республики Беларусь от 09.01.2002 №90-З «О защите прав потребителей» и иных нормативных правовых актов Республики Беларусь, регулирующих вопросы оказания услуг Исполнителем.
                        Размещение текста настоящего Договора на официальном сайте Исполнителя по адресу qrmoment.by является публичной офертой Исполнителя в соответствии с п. 2 ст. 407 Гражданского кодекса Республики Беларусь, на основании которой Исполнитель заключит Договор с любым из Заказчиков, который согласится на его заключение.
                    </p>

                    <p>
                        Договор считается заключенным между Исполнителем и Заказчиком в момент акцепта Заказчиком публичной оферты Исполнителя. Оплата услуг любым физическим лицом в соответствии с условиями договора является акцептом оферты, что считается равносильным заключению договора на условиях, изложенных в оферте (п. 3 ст. 408 Гражданского кодекса Республики Беларусь).
                        Публичный договор на оказание услуг должен быть принят Заказчиком путем присоединения к нему в целом и безоговорочно без каких-либо исключений.
                        Настоящий Договор при условии соблюдения порядка его акцепта считается заключенным в простой письменной форме.

                    </p>

                    <h3>1. ПРЕДМЕТ ДОГОВОРА</h3>
                    <p>
                        1.1 Предметом настоящей оферты является оказание Заказчику услуги по созданию персонального сайта (страницы) в сети Интернет в отношении умершего близкого человека Заказчика в порядке, объеме и на условиях, предусмотренных настоящей публичной офертой, а также присвоением электронному адресу страницы индивидуального QR-кода с размещением его на металлической табличке для обеспечения свободного доступа неограниченного круга лиц.
                    </p>
                    <p>1.2 Термины и определения к настоящему Договору:</p>
                    <p>1. Заказчик – любое физическое лицо, которое осуществляет акцепт оферты и является наследником, близким родственником (родители и дети, полнородные или неполнородные братья и сестры, дед, бабка и внуки, правнуки), усыновителем (удочерителем), усыновленным (удочеренным), опекуном (попечителем) либо супругом (супругой) умершего.</p>
                    <p>2. Под умершим близким человеком Заказчика по тексту настоящей публичной оферты стороны понимают физическое лицо, по отношению к которому Заказчик является: наследником, близким родственником (родители и дети, полнородные или неполнородные братья и сестры, дед, бабка и внуки, правнуки), усыновителем (удочерителем), усыновленным (удочеренным), опекуном (попечителем) либо супругом (супругой). </p>
                    <p>3. Сайт Исполнителя – информационный ресурс по адресу: qrmoment.by, принадлежащий Исполнителю в сети Интернет (далее по тексту Договора – Сайт)</p>
                    <p>4. Акцепт оферты – полное и безоговорочное принятие оферты Исполнителя путем оплаты Заказчиком стоимости выбранного пакета услуг на Сайте Исполнителя на условиях настоящего Договора.</p>
                    <p>5. QR-код – это индивидуальный штрих-код, состоящий из набора квадратов (пикселей), при считывании которого камерой смартфона Заказчик или иные заинтересованные лица получают доступ к персональному сайту (странице) умершего близкого человека Заказчика в сети Интернет.</p>
                    <p>2. Публичная оферта и приложения к публичной оферте являются официальными документами и публикуются на официальном Сайте Исполнителя.</p>
                    <p>3. Услуги по настоящему Договору оказываются по месту нахождения Исполнителя</p>
                    <p>4. Заказчик самостоятельно определяет объем предоставляемых Исполнителем услуг путем акцепта на Сайте одного из предоставленных пакетов услуг: «Стандарт» или «Премиум».</p>
                    <p>5. Заказчик подтверждает, что до момента заключения настоящего Договора ознакомлен и согласен со стоимостью и объемом услуг, предоставляемых Исполнителем в рамках выбранного пакета услуг на Сайте, а также ознакомлен с условиями и порядком оказания услуг, оказываемых Исполнителем по Договору.</p>
                    <p>6. Фактом принятия (акцепта) Заказчиком условий Договора является оплата Заказчиком заказанных им услуг в порядке и на условиях, определенных Договором.</p>

                    <h1>2. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h1>
                    <h3>1. Исполнитель обязан:</h3>
                    <p>1.1 оказать услуги в полном объеме в соответствии с выбранным Заказчиком пакетом услуг и обеспечить надлежащее их качество в соответствии с требованиями, установленными законодательством Республики Беларусь, и обычно предъявляемыми требованиями для такого рода услуг;</p>
                    <p>1.2 предоставить Заказчику личный доступ к персональному сайту (странице) с момента регистрации Заказчика на Сайте Исполнителя (логин и пароль) и оплаты выбранного пакета услуг в установленный настоящим Договором срок с правом ее редактирования на правах администратора;</p>
                    <p>1.3 присвоить QR-код для персонального сайта (страницы) Заказчика и изготовить табличку из закаленного металла с размещением на ней изображения присвоенного QR-кода;</p>
                    <p>1.4 консультировать Заказчика по техническим вопросам ведения персонального сайта (страницы) и его (ее) наполнению в рабочее время Исполнителя с ____ часов по _____ часов. Консультационные услуги оказываются Исполнителем онлайн посредством обращения Заказчика через доступные мессенджеры, номера телефонов, а также через форму обращения на Сайте;</p>
                    <p>1.5 удовлетворить требования Заказчика по устранению недостатков в переданной табличке с QR-кодом в случае их предъявления в порядке и на условиях, предусмотренных настоящим Договором; </p>
                    <p>1.6 осуществлять иные обязанности, вытекающие из настоящего Договора и приложений к нему.</p>

                    <h3>2. Исполнитель имеет право:</h3>
                    <p>2.1 получать от Заказчика любую информацию, необходимую для выполнения своих обязательств по Договору. В случае непредставления либо неполного или неверного представления Заказчиком информации в процессе оказания услуг Исполнитель вправе приостановить исполнение своих обязательств по настоящему Договору до получения необходимой информации;</p>
                    <p>2.2 изменить в одностороннем порядке срок оказания услуг, предварительно уведомив Заказчика, по причинам, не зависящим от Исполнителя;</p>
                    <p>2.3 изменять стоимость оказываемых услуг, объем и порядок оказания услуг в рамках предоставленных пакетов, добавлять или отменять услуги, входящие в комплекс услуг в одностороннем порядке, предварительно разместив данную информацию на Сайте;</p>
                    <p>2.4 в случае нарушения Заказчиком условий настоящего Договора либо в случае нарушения Заказчиком условий оказания услуг, указанных в настоящем Договоре и иных локальных актах, Исполнитель вправе приостановить, прекратить выполнение своих обязательств по настоящему Договору либо расторгнуть Договор в одностороннем порядке. Внесенная Заказчиком стоимость услуг при этом не возвращается; </p>
                    <p>2.5 сделать фото готовой таблички перед отправкой и предоставить его Заказчику в случае необходимости или по запросу Заказчика;</p>
                    <p>2.6 самостоятельно в одностороннем порядке определять формы, методы и способы осуществления услуг;</p>
                    <p>2.7 привлекать третьих лиц для оказания услуг по настоящему Договору без согласования и уведомления Заказчика. При этот ответственность за действия третьих лиц перед Заказчиком несет Исполнитель.</p>
                    <h3>3. Заказчик обязан:</h3>

                    <p>3.1 предоставить материалы, всю необходимую информацию и сведения об умершем близком человеке в объеме, необходимом для исполнения Исполнителем своих обязательств по формированию персонального сайта (страницы).
                        Исполнитель не несет ответственности за качество оказанных услуг в связи с указанием неполных, недостоверных, неточных или неактуальных данных Заказчиком, в том числе на стадии получения ссылки на персональный сайт (страницу) Заказчика;
                    </p>
                    <p>3.2 принять услуги в порядке и сроки, установленные настоящим Договором;</p>
                    <p>3.3 оплатить оказанные Исполнителем услуги в соответствии с условиями публичной оферты;</p>
                    <p>3.4 осуществлять иные обязанности, вытекающие из настоящего Договора и приложений к нему.</p>
                    <h3>4. Заказчик имеет право:</h3>
                    <p>4.1 на получение достоверной, доступной и полной информации о перечне оказываемых услуг, их стоимости и условиях оплаты, режиме работы Исполнителя;</p>

                    <h1>2. ПОРЯДОК ОКАЗАНИЯ УСЛУГ</h1>
                    <p>2.1 Для получения услуги Заказчик регистрируется на Сайте Исполнителя путем введения данных по запросу (создание личного кабинета Заказчика), производит оплату согласно выбранного пакета услуг, вводит информацию об умершем близком человеке и нажимая на кнопку «_________» получает доступ к персональному сайту (странице). С момента получения персонального доступа к сайту (странице) услуга по его (ее) созданию считается оказанной Исполнителем надлежащим образом и в согласованный сторонами срок. </p>
                    <p>2.2 В течение 10 рабочих дней с момента получения доступа к персональному сайту (странице) Исполнитель обязуется изготовить и направить по указанному адресу Заказчика табличку с персональным QR-кодом созданного сайта (страницы) посредством операторов почтовой связи или курьерской доставки. В срок оказания услуги не входит время доставки таблички по адресу Заказчика.</p>
                    <p>2.3 Присоединяясь к условиям настоящей публичной оферты Заказчик подтверждает, что он является близким человеком умершего по условиям настоящего Договора, обладает полным объемом прав в отношении персональных данных и материалов об умершем человеке и его близких, предоставляемые им сведения не являются предметом спора, не нарушают права и законные интересы третьих лиц, не порочат их честь и достоинство, а равно настоящим Заказчик подтверждает, что им получено надлежащим образом согласие третьих лиц на предоставление сведений или материалов о них в рамках настоящего Договора или в процессе использования результата оказанной услуги для неограниченного круга лиц в соответствии с требованиями законодательства Республики Беларусь в области персональных данных.
                        Исполнитель не несет ответственности за вред, причиненный третьим лицам незаконным распространением Заказчиком сведений о них посредством размещения на персональном сайте (странице) умершего без согласия последних.
                    </p>
                    <p>2.4 Срок оказания услуги исчисляется с момента поступления 100% предоплаты стоимости выбранного пакета услуг Исполнителю и предоставления полной и необходимой информации и материалов для ее оказания на Сайте Исполнителя. При непредоставлении информации и материалов к моменту начала оказания услуг, срок оказания услуг отодвигается на время, в течение которого Заказчик препятствует оказанию услуг в рамках настоящего Договора. В данном случае Исполнитель не несет ответственности за несвоевременное оказание услуг по настоящему Договору.</p>
                    <p>2.5 Днем исполнения обязательств Исполнителем по настоящему Договору считается день получения Заказчиком доступа к персональному сайту (странице), отправления таблички с QR-кодом посредством операторов почтовой связи или курьерской доставки.</p>
                    <p>2.6 Исполнитель не несет ответственность за время доставки почтового отправления операторами почтовой связи или курьерской доставки, а также за порчу, уничтожение, повреждение или утерю отправления по их вине. </p>
                    <p>2.7 Заказчик самостоятельно осуществляет наполнение созданного персонального сайта (страницы) материалами и информацией об умершем близком человеке. По-своему усмотрению Заказчик вправе передать логин и пароль для доступа к персональной странице иным лицам с правами администратора и редактирования. За информацию и размещаемые материалы на персональном сайте (странице) Заказчика Исполнитель ответственности не несет.</p>
                    <p>2.8 Заказчик вправе обращаться за консультацией и технической поддержкой Исполнителя при заполнении и использовании персонального сайта (страницы) в рабочее время Исполнителя, указанное в настоящем Договоре или на Сайте Исполнителя.</p>

                    <h1>3.СРОКИ И ПОРЯДОК РАСЧЕТОВ</h1>
                    <p>3.1 Заказчиком производится оплата в размере 100% от стоимости выбранного пакета оказываемых услуг исходя из цен, указанных на Сайте Исполнителя на момент их оплаты. Оплата считается произведенной при поступлении денежных средств Исполнителю.</p>
                    <p>3.2 Заказчик оплачивает услуги онлайн посредством платежных систем, предложенных Исполнителем на Сайте в качестве способов оплаты.</p>
                    <p>3.3 Наличие у Заказчика претензий по качеству и полноте оказания услуг (далее - претензии) не может быть основанием для отказа Заказчика от оказанной услуги с последующим требованием возврата денежных средств. </p>
                    <p>3.4 В соответствии с действующим законодательством о единоличном составлении учетных первичных документов, Заказчик подтверждает и соглашается с тем, что акт оказанных услуг в соответствии с договором составляется Исполнителем единолично. После составления Исполнителем акта единолично, копия акта по желанию Заказчика направляется на электронную почту, указанную Заказчиком.</p>
                    <p>3.5 Заказчик соглашается с тем, что информация об объеме оказанных услуг составляется Исполнителем в одностороннем порядке в соответствии с действующим законодательством Республики Беларусь.</p>
                    <p>3.6 В случаях, когда невозможность оказания услуг возникла по обстоятельствам, за которые ни одна из сторон не отвечает, Заказчик возмещает Исполнителю фактически понесенные им расходы.</p>
                    <p>3.7 Все расходы, связанные с перечислением денежных средств за оказание услуг, несет Заказчик.</p>
                    <p>3.8 При произведении оплаты услуг Заказчик подтверждает, что ознакомлен и согласен с условиями оплаты услуг, действующими у Исполнителя и указанными в настоящем Договоре.</p>

                    <h1>4. ГАРАНТИЙНЫЕ ОБЯЗАТЕЛЬСТВА И УРЕГУЛИРОВАНИЕ ПРЕТЕНЗИЙ ЗАКАЗЧИКА</h1>
                    <p>4.1 Гарантийный срок на табличку с QR-кодом составляет 10 лет с момента ее получения Заказчиком. Гарантийные обязательства не распространяются на клейкую ленту, а также в случае установки или крепления таблички Заказчиком иным способом по своему усмотрению, повреждения целостности таблички или изображения QR-кода действиями Заказчика, в том числе приведшее к появлению следов коррозии, окислению металла и иным негативным последствиям, по вине Заказчика.</p>
                    <p>4.2 Исполнитель не обеспечивает установку таблички, не несет ответственность за порядок и способы ее крепления Заказчиком по своему усмотрению. </p>
                    <p>4.3 Претензии Заказчика по качеству и полноте оказанных услуг принимаются Исполнителем на электронную почту, указанную на Сайте и реквизитах Исполнителя в настоящем Договоре, не позднее 1 (одного) календарного дня с момента получения Заказчиком таблички с QR-кодом. Днем получения Заказчиком таблички считается дата ее вручения Заказчику согласно данным оператора почтовой связи или курьерской службы.</p>
                    <p>4.4 Претензия в обязательном порядке должна содержать:</p>
                    <p>4.4.1 Фамилию, имя, отчество Заказчика;</p>
                    <p>4.4.2 Адрес для корреспонденции, контактный телефон;</p>
                    <p>4.4.3 Фото конверта с номером заказного отправления в адрес Заказчика, дату получения;</p>
                    <p>4.4.4 Фото выявленных дефектов;</p>
                    <p>4.4.5 Суть претензии и предложения по ее урегулированию.</p>
                    <p>4.5 Претензия не подлежит удовлетворению и не рассматривается Исполнителем при наличии следов механического воздействия на табличку, в том числе, следов эксплуатации, крепления или иных действий и повреждений, а равно в случае несоблюдения срока или порядка ее предъявления.</p>
                    <p>4.6 Исполнитель обязуется рассмотреть претензию в течение 5 (пяти) календарных дней и письменно уведомить Заказчика о результатах ее рассмотрения.</p>
                    <p>4.7 В случае признания претензии обоснованной Исполнитель принимает все возможные меры по урегулированию спора в порядке и сроки, установленные законодательством Республики Беларусь.</p>
                    <p>4.8 Споры, не урегулированные путем переговоров, передаются на рассмотрение суда в порядке, предусмотренном законодательством Республики Беларусь.</p>

                    <h1>5. ОТВЕТСТВЕННОСТЬ СТОРОН</h1>
                    <p>5.1 За неисполнение или ненадлежащее исполнение своих обязательств по настоящему Договору стороны несут ответственность в соответствии с действующим законодательством Республики Беларусь.</p>
                    <p>5.2 Любая из Сторон освобождается от ответственности за ненадлежащее исполнение условий договора, если оно оказалось невозможным вследствие воздействия непреодолимой силы, т.е. чрезвычайных и непредотвратимых при данных условиях обстоятельств.</p>
                    <p>5.3 Исполнитель не несет ответственности в случае:</p>
                    <p>5.3.1 предоставления Заказчиком недостоверной информации;</p>
                    <p>5.3.2 если оказание услуг прекращено и (или) незакончено не по инициативе Исполнителя;</p>
                    <p>5.3.3 недостижение желаемого результата по причинам, не зависящим от Исполнителя.</p>

                    <h1>6. ПЕРСОНАЛЬНЫЕ ДАННЫЕ И КОНФИДЕНЦИАЛЬНОСТЬ</h1>
                    <p>6.1 Для целей настоящего Договора под конфиденциальной информацией понимается информация о ходе исполнения настоящего Договора и полученных результатов, предоставляемая Сторонами друг другу или получаемая друг от друга, в том числе персональные данные (далее - Конфиденциальная информация).</p>
                    <p>6.2 Каждая из Сторон обязана обеспечить защиту Конфиденциальной информации, ставшей доступной ей в рамках исполнения настоящего Договора, от несанкционированного использования, распространения или публикации.</p>
                    <p>6.3 Конфиденциальная информация может быть использована получающей Стороной только в связи с исполнением настоящего Договора.</p>
                    <p>6.4 Конфиденциальная информация не должна раскрываться третьим лицам без письменного согласия другой Стороны, за исключением случаев, предусмотренных законодательством Республики Беларусь и соглашениями Сторон, при этом Сторона, раскрывающая Конфиденциальную информацию, обязуется обеспечить подписание соглашения о конфиденциальности с такими лицами до раскрытия им такой информации.</p>
                    <p>6.5 Сторона, получившая информацию обязана по письменному запросу второй стороны в течении 10 (десяти) календарных дней вернуть все носители Конфиденциальной информации либо представить убедительные свидетельства безвозвратного уничтожения полученной конфиденциальной информации</p>
                    <p>6.6 При предоставлении одной из Сторон Конфиденциальной информации в отношении третьих лиц, в том числе персональных данных, передающая Сторона гарантирует наличие оформленного надлежащим образом согласия на передачу такой информации и ее обработку, а принимающая Сторона гарантирует обеспечение конфиденциальности такой информации. При сборе, обработке и передаче сведений, содержащих охраняемую законом тайну, в том числе персональные данные, Стороны обязуются соблюдать законодательство Республики Беларусь.</p>
                    <p>6.7 Для целей оказания услуг Исполнитель осуществляет обработку следующих персональных данных Заказчика:</p>

                    <p>7.1.1 фамилия, имя, отчество;</p>
                    <p>7.1.2 контактный телефон;</p>
                    <p>7.1.3 адрес электронной почты;</p>
                    <p>7.1.4 почтовый адрес;</p>
                    <p>7.1.5 место проживания (улица, дом, квартира, город, почтовый индекс).</p>
                    <p>6.8 Обработка персональных данных Заказчика производится Исполнителем без согласия Заказчика на основании абзаца 15 статьи 6 Закона Республики Беларусь от 07.05.2021 № 99-3 «О защите персональных данных».</p>
                    <p>6.9 При обработке охраняемой законом тайны, в том числе, персональных данных, Стороны обязуются принимать необходимые правовые, организационные и технические меры или обеспечивать их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения, а также от иных неправомерных действий.</p>
                    <p>6.10 Сторона, допустившая утрату или разглашение Конфиденциальной информации, незамедлительно информирует об этом другую Сторону, при этом обе Стороны принимают все необходимые и доступные меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением Конфиденциальной информации. Сторона, допустившая утрату или разглашение Конфиденциальной информации, несёт ответственность за убытки, понесённые другой Стороной в связи с утратой или разглашением Конфиденциальной информации, и обязана возместить в полном объёме документально подтверждённый ущерб в соответствии с законодательством Республики Беларусь.</p>

                    <h1> 7. ДЕЙСТВИЯ ДОГОВОРА. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h1>
                    <p>7.1 Договор вступает в силу с момента акцепта Заказчиком настоящей оферты и действует в течение срока исполнения обязательств сторон по Договору</p>

                    <p>7.2 Настоящий договор может быть досрочно прекращен в одностороннем порядке, в случае невыполнения одной из Сторон обязательств по настоящему договору. </p>
                    <p>7.3 Исполнитель оставляет за собой право вносить изменения и дополнения в Договор в любой момент по своему усмотрению. Изменения и дополнения в Договор вступают в силу с момента их размещения на Сайте Исполнителя.</p>
                    <p>7.4 Отсутствие подписанного между Сторонами экземпляра договора на бумажном носителе с проставлением подписей Сторон в случае акцепта оферты Заказчиком не является основанием считать договор незаключенным.</p>
                    <p>7.5 Во всем остальном, что не предусмотрено настоящим договором, Стороны руководствуются действующим законодательством Республики Беларусь.</p>
                    <h1>8. Юридические адреса и реквизиты</h1>

                    <p>Исполнитель:</p>
                    <p>Индивидуальный предприниматель Мележик Андрей</p>
                    <p>Минская обл., Дзержинский район, г. Фаниполь, ул. Зеленая, д. 17, кв. 97</p>
                    <p>УНП 691648286</p>
                    <p>р/с BY93POIS30130160487901933001 в ОАО «Паритетбанк»</p>
                    <p>код банка POISBY2X</p>
                    <p>info@qrmoment.com</p>
                    <p>_____________ИП Мележик А.</p>
                </div>
                <div className="back-button-container">
                <button className="back-button" onClick={handleGoBack}>Согласен</button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PublicAgreement;