import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app, db, updateEmail, updatePassword } from "../../../Components/Firebase/Firebase";

import './UpdateEmail.css';
import RegularButton from "../../../Components/RegularButton/RegularButton";
import RegularInput from "../../../Components/RegularInput/RegularInput";
import { useTranslation } from "react-i18next";
import login from '../../../Images/login.jpg';
import Footer from "../../Footer/Footer";
import { updateUserData } from "../../../Redux/actions/actions";
import { doc, setDoc } from "firebase/firestore";

const UpdateEmail = ({ userLanguage, setLoading }) => {

    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('+375');
    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [registrationError, setRegistrationError] = useState('');

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const alertPleaseEnterYourName = t('alert_please_enter_your_name');
    const alert_please_enter_a_valid_email_address = t('alert_please_enter_a_valid_email_address');
    const alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number = t('alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number');

    const handleUpdateEmailAndPassword = async () => {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        const passwordRegex = /^(?=.*\d)[\s\S]{6,}$/;
        setLoading(true);
        if (!fullName) {
            setRegistrationError(alertPleaseEnterYourName);
            setLoading(false);
            return;
        }

        else if (!emailRegex.test(email || newEmail)) {
            setRegistrationError(alert_please_enter_a_valid_email_address);
            setLoading(false);
            return;
        }
        else if (!passwordRegex.test(password || newPassword || confirmNewPassword)) {
            setRegistrationError(alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number);
            setLoading(false);
            return;
        }
        else if (!phone) {
            setRegistrationError(t('alert_please_enter_your_phone'));
            setLoading(false);
            return;
        }

        else {
            const auth = getAuth(app);
            try {
                // Авторизуем пользователя с текущими учетными данными
                await signInWithEmailAndPassword(auth, email, password);

                // Если авторизация прошла успешно, обновляем электронный адрес и пароль
                const user = auth.currentUser;

                // Добавляем данные в коллекцию new-users для отправки email покупателю
                const otherCollectionDocRef = doc(db, "new-users", user.uid);
                await setDoc(otherCollectionDocRef, {
                    uid: user.uid,
                    price: "500",
                    country: "Israel",
                    email: newEmail,
                    fullName,
                    phone,
                    dateOfCreation: new Date().toISOString(),
                });
                await updateEmail(user, newEmail);
                await updatePassword(user, newPassword);
                
                //обновляем данные в коллекции
                const updatedData = {
                    phone: phone,
                    fullName: fullName,
                    email: newEmail,
                    isUserRegistered: true
                };

                // Обновление данных в Firebase и Redux
                await dispatch(updateUserData(user.uid, updatedData));
                const userid = user.uid.substring(0, 10);
                localStorage.setItem('userId', userid);

                navigate(`/${userid}/PersoneHistory`);

                // alert('Email and password updated successfully');
            } catch (error) {
                alert('Error updating email and password:', error.message);
            }
        }


        setLoading(false);
    };

    return (
        <>
            <div className="update-email-form" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
                <div className='update-email-titel' >
                    <img src={login} alt="img signUp" loading="eager" />
                    <div className="update-email-input-container">

                        <h4>{t('please_enter_a_temporary_email_and_password')}</h4>

                        <RegularInput
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            placeholder="temporary_email"
                        />

                        <label style={{ position: 'relative' }}>
                            <RegularInput
                                securePassword={true}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="temporary_password"
                                eyeStyle={userLanguage?.includes("he") ? true : false}

                            />
                        </label>

                        <h4>{t('please_fill_in_the_information')}</h4>

                        <RegularInput
                            type="text"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                            label="recipients_first_and_last_name"
                            placeholder="recipients_first_and_last_name"
                        />

                        <RegularInput
                            type="email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            required
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            placeholder="new_email"
                        />
                        <label style={{ position: 'relative' }}>
                            <RegularInput
                                securePassword={true}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="new_password"
                                eyeStyle={userLanguage?.includes("he") ? true : false}

                            />
                        </label>
                        <label style={{ position: 'relative' }}>
                            <RegularInput
                                securePassword={true}
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                placeholder="confirm_your_new_password"
                                eyeStyle={userLanguage?.includes("he") ? true : false}

                            />
                        </label>

                        <RegularInput
                            type="phone"
                            value={phone}
                            onChange={(value) => setPhone(value)}
                            label="phone"
                            placeholder="+375"
                        />

                        {registrationError && (
                            <p style={{ color: 'red', textAlign: 'center' }}>{registrationError}</p>
                        )}

                        <div className='btns-update-email'>
                            <RegularButton
                                onClick={handleUpdateEmailAndPassword}
                                buttonText="next"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default UpdateEmail;
